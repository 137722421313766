import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { apiGroupDetailCondition, apiGroupMachinesDetail } from "@app/api";
import {
  IconCheck,
  IconWarning,
  IconError,
  IconInvalidUrl,
} from "@app/components/icons";
import {
  ToggleButtonGroup,
  ToggleButton,
  Avatar,
  Button,
  Box,
} from "@mui/material";
import { DataTable, DataPagination, DataDialog } from "@app/components/atoms";
import { GroupMachinesRequestDef } from "@app/types";
import {
  CURRENT_PAGE,
  LIMIT_PAGE,
  StorageKey,
  TOTAL_PAGE,
} from "@app/constants";
import {
  formatNumberWithCommas,
  isMobile,
  resetIsContinuousLoading,
  scrollToTop,
  setItem,
} from "@app/helpers";
import { useSelector } from "react-redux";
import { RootState } from "@app/store";

enum MACHINE_CONDITION {
  NORMAL = "NORMAL",
  WARNING = "WARNING",
  ERROR = "ERROR",
}

type MachineType = {
  machineCount: number;
  normalStatusCount: number;
  warningStatusCount: number;
  errorStatusCount: number;
};

type MachineFilterType = {
  label: React.ReactNode;
  key: keyof MachineType;
  machineCondition: MACHINE_CONDITION | "";
  count: number;
};

type machineType = {
  id: number;
  pictureUrl?: string;
  machineName?: string;
  machineCondition?: string;
  machineManufacturerName?: string;
  machineType?: string;
  modelAndType?: string;
  serialNumber?: string;
  serviceMeter?: number;
  reportCount?: number;
  serialNumberPlatePictureUrl?: string;
};

const dataMachineFilter: MachineFilterType[] = [
  {
    label: "all",
    key: "machineCount",
    machineCondition: "",
    count: 0,
  },
  {
    label: <IconCheck />,
    key: "normalStatusCount",
    machineCondition: MACHINE_CONDITION.NORMAL,
    count: 0,
  },
  {
    label: <IconWarning />,
    machineCondition: MACHINE_CONDITION.WARNING,
    key: "warningStatusCount",
    count: 0,
  },
  {
    label: <IconError />,
    machineCondition: MACHINE_CONDITION.ERROR,
    key: "errorStatusCount",
    count: 0,
  },
];

export function MachineTab() {
  const [dataMachineTabList, setDataMachineTabList] =
    useState(dataMachineFilter);
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [machineCondition, setMachineCondition] = useState<
    MACHINE_CONDITION | ""
  >("");
  const [dataCell, setDataCell] = useState<machineType>();
  const [rows, setRow] = useState<machineType[]>();
  const [field, setField] = useState<string>("machineName");
  const [order, setOrder] = useState<string | undefined>();
  const [pageInfo, setPageInfo] = useState({
    total: TOTAL_PAGE,
    page: CURRENT_PAGE,
    limit: LIMIT_PAGE,
  });
  const [open, setOpen] = useState(false);
  const [isInit, setIsInit] = useState(true);
  const columns = [
    {
      key: "id",
      title: "",
      dataIndex: "id",
      render: (_: any, record: any) => {
        return (
          <>
            {record.pictureUrl ? (
              <Avatar
                variant="square"
                src={record.pictureUrl}
                imgProps={{
                  onError: () => {
                    const newRows = rows?.map((item) => {
                      if (item.id === record.id) {
                        item.pictureUrl = undefined;
                      }
                      return item;
                    });
                    setRow(newRows);
                  },
                  sx: {
                    objectFit: "contain",
                  },
                }}
              />
            ) : (
              <Box
                sx={{
                  width: 40,
                  height: 40,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconInvalidUrl />
              </Box>
            )}
          </>
        );
      },
      className: "pr-8",
      tableHeadingClassName: "d-none-imp",
      width: 56,
    },
    {
      key: "machineName",
      title: "machineTable.machineName",
      dataIndex: "machineName",
      sorted: true,
      tableHeadingColspan: 2,
      className: "pl-0 txt-pre-wrap",
      whiteSpace: "nowrap",
      render: (value: any, record: any) => {
        return (
          <Link
            style={{ paddingLeft: 0 }}
            className="link-style"
            to={`/groups/${id}/machines/${record.machineId}/inspection-history`}
          >
            {value}
          </Link>
        );
      },
      style: {
        minWidth: isMobile() ? 232 : 203,
      },
    },
    {
      key: "machineCondition",
      title: "machineTable.machineCondition",
      dataIndex: "machineCondition",
      align: "center",
      render: (value: MACHINE_CONDITION) => {
        switch (value) {
          case MACHINE_CONDITION.NORMAL:
            return <IconCheck />;
          case MACHINE_CONDITION.WARNING:
            return <IconWarning />;
          default:
            return <IconError />;
        }
      },
      whiteSpace: "nowrap",
      style: {
        minWidth: 105,
      },
    },
    {
      key: "machineManufacturerName",
      title: "machineTable.machineManufacturerName",
      dataIndex: "machineManufacturerName",
      sorted: true,
      whiteSpace: "nowrap",
      style: {
        minWidth: 154,
      },
      className: "txt-pre-wrap",
    },
    {
      key: "machineType",
      title: "machineTable.machineType",
      dataIndex: "machineType",
      sorted: true,
      style: {
        minWidth: isMobile() ? 217 : 205,
      },
      className: "txt-pre-wrap",
    },
    {
      key: "modelAndType",
      title: "machineTable.modelAndType",
      dataIndex: "modelAndType",
      sorted: true,
      style: {
        minWidth: 196,
      },
      className: "txt-pre-wrap",
    },
    {
      key: "serialNumber",
      title: "machineTable.serialNumber",
      dataIndex: "serialNumber",
      render: (value: string, record: any) => (
        <span
          onClick={() => handleCellClick(record)}
          className={
            record.serialNumberPlatePictureUrl && "has-text-link pointer"
          }
        >
          {value}
        </span>
      ),
      className: "serial-number txt-pre-wrap",
      style: {
        minWidth: isMobile() ? 193 : 200,
      },
    },
    {
      key: "serviceMeter",
      title: "machineTable.serviceMeter",
      dataIndex: "serviceMeter",
      align: "right",
      whiteSpace: "nowrap",
      style: {
        minWidth: 200,
      },
      forceRender: true,
      render: (value: number) =>
        value !== null && formatNumberWithCommas(value),
    },
    {
      key: "reportCount",
      title: "machineTable.countReport",
      dataIndex: "reportCount",
      render: (value: number, record: any) => {
        return (
          <div className={`flex align-items-center txt-nowrap`}>
            <Link
              className="link-style"
              to={`/groups/${id}/machines/${record.machineId}/report`}
            >
              {value}
            </Link>
            {!!record.reportOpenCount && (
              <div>
                <span className="report-open">{record.reportOpenCount}</span>
              </div>
            )}
          </div>
        );
      },
      style: {
        minWidth: 85,
      },
    },
  ];
  const { isRequestedGroupDetail } = useSelector(
    (state: RootState) => state.groupDetail
  );
  const [isRequestedGroupDetailCondition, setIsRequestedGroupDetailCondition] =
    useState(false);

  const handleClickMachineCondition = (
    _: React.MouseEvent<HTMLElement>,
    machineCondition: MACHINE_CONDITION | ""
  ) => {
    setPageInfo({
      ...pageInfo,
      page: CURRENT_PAGE,
    });
    setMachineCondition(machineCondition);
  };

  const handleChangePage = (page: number) => {
    setPageInfo({
      ...pageInfo,
      page,
    });
  };

  const handleCellClick = (column: any) => {
    if (!column.serialNumberPlatePictureUrl) {
      return;
    }
    setDataCell(column);
    setOpen(true);
  };

  const handleSort = (order: string | undefined, property: string) => {
    setOrder(order);
    setField(property);
  };

  const initScrollToTop = () => {
    if (isInit) {
      scrollToTop();
      setIsInit(false);
    }
  };

  const convertDataRow = (data: machineType[]) => {
    return data.map((row: any) => ({
      ...row,
      id: row?.machineId,
    }));
  };

  const getGroupDetailCondition = (id: string) => {
    return apiGroupDetailCondition(id)
      .then((res) => {
        const data = res.data.data as MachineType;
        const newDataMachineTabs = dataMachineTabList.map((tab) => {
          tab.count = data[tab.key as keyof MachineType];
          return tab;
        });
        setDataMachineTabList(newDataMachineTabs);
      })
      .finally(() => {
        setIsRequestedGroupDetailCondition(true);
      });
  };

  const groupMachinesDetail = (id: string, param: GroupMachinesRequestDef) => {
    return apiGroupMachinesDetail(id, param).then((e) => {
      const { data, meta } = e.data;
      const formatData = convertDataRow(data);
      setRow(formatData);
      setPageInfo(meta.pageInfo);
      if (!data?.length) {
        initScrollToTop();
        resetIsContinuousLoading();
      }
    });
  };

  useEffect(() => {
    if (isRequestedGroupDetail) {
      getGroupDetailCondition(`${id}`);
    }
  }, [isRequestedGroupDetail]);

  useEffect(() => {
    if (isRequestedGroupDetailCondition) {
      setItem(StorageKey.IS_CONTINUOUS_LOADING, "true");
      groupMachinesDetail(`${id}`, {
        limit: LIMIT_PAGE,
        page: pageInfo.page,
        ...(machineCondition ? { machineCondition } : {}),
        ...(field && order ? { orderBys: [{ field, order }] } : {}),
      });
    }
  }, [
    pageInfo.page,
    field,
    order,
    machineCondition,
    isRequestedGroupDetailCondition,
  ]);

  useEffect(() => {
    return () => {
      resetIsContinuousLoading();
    };
  }, []);

  return (
    <div className="machine-tab">
      <div className="toggle-btn-group">
        {!!rows?.length && (
          <ToggleButtonGroup
            style={{ marginTop: "24px", overflow: "auto" }}
            value={machineCondition}
            exclusive
          >
            {dataMachineTabList.map((tab, index) => (
              <ToggleButton
                disabled={!tab.count}
                key={index}
                value={tab.machineCondition}
                className="flex-centered filter-tab"
                onClick={handleClickMachineCondition}
              >
                {typeof tab.label === "string" ? t(tab.label) : tab.label}
                <span style={{ marginLeft: "8px" }}>({tab.count})</span>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        )}
      </div>
      <div className="machine-table">
        <DataTable
          columns={columns}
          dataSource={rows}
          textEmptyData="noGroupDetailMachines"
          onRequestSort={handleSort}
          onCellClick={handleCellClick}
          didScrollTop={() => {
            initScrollToTop();
            resetIsContinuousLoading();
          }}
        />
        <DataPagination
          isHidden={!rows?.length}
          className="mt-25 sp-p24"
          currentPage={pageInfo.page}
          limitPage={pageInfo.limit}
          totalPage={pageInfo.total}
          handleChangePage={handleChangePage}
        />
      </div>
      {dataCell && (
        <DataDialog
          title={t("serialNumberPlate")}
          open={open}
          dialogActions={
            <Button onClick={() => setOpen(false)} className="btn btn-white">
              {t("close")}
            </Button>
          }
        >
          {dataCell.serialNumberPlatePictureUrl ? (
            <img
              className="img-dialog"
              src={`${dataCell.serialNumberPlatePictureUrl}`}
              alt={dataCell.machineManufacturerName}
              loading="lazy"
              onError={() => {
                setDataCell({
                  ...dataCell,
                  serialNumberPlatePictureUrl: undefined,
                });
              }}
            />
          ) : (
            <Box
              sx={{
                width: 512,
                height: 512,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconInvalidUrl />
            </Box>
          )}
        </DataDialog>
      )}
    </div>
  );
}
