import { StorageKey } from "@app/constants";
import { differenceInMinutes, format } from "date-fns";

const NO_ACTION_TIMEOUT_MINUTES = 43200;

export const useForceLogout = () => {
  const setTimer = () => {
    localStorage.setItem(
      StorageKey.LAST_UPDATED_AT,
      format(new Date(), "yyyy-MM-dd'T'HH:mm:ssxxx")
    );
  };

  // true: expired, false: not expired
  const isExpired = (): boolean => {
    const lastUpdatedAt = localStorage.getItem(StorageKey.LAST_UPDATED_AT);
    if (!lastUpdatedAt) {
      return false;
    }

    const diff = differenceInMinutes(
      format(new Date(), "yyyy-MM-dd'T'HH:mm:ssxxx"),
      lastUpdatedAt
    );
    return diff >= NO_ACTION_TIMEOUT_MINUTES;
  };

  const clearTimer = () => {
    localStorage.removeItem(StorageKey.LAST_UPDATED_AT);
  };

  const getTimer = () => {
    return localStorage.getItem(StorageKey.LAST_UPDATED_AT);
  };

  return { setTimer, isExpired, clearTimer, getTimer };
};
