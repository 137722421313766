import React from "react";
import { Link } from "react-router-dom";
import { Button as MuiButton } from "@mui/material";

interface ButtonProps {
  to?: string;
  children: (React.ReactNode | string)[] | React.ReactNode | string;
  className?: string;
  onClick?: () => void;
  type?: string | any;
  disabled?: boolean;
}

export function Button({
  children,
  className,
  to,
  onClick,
  type,
  disabled,
}: ButtonProps) {
  if (to) {
    return <Link to={to}>{children}</Link>;
  }
  return (
    <MuiButton
      onClick={onClick}
      className={`btn ${className}`}
      type={type}
      disabled={disabled}
    >
      {children}
    </MuiButton>
  );
}
