import { AxiosResponse } from "axios";
import { api, ENDPOINTS } from "@app/api";
import { LoginRequestDef } from "@app/types";

export const apiAuthLogin = (data: LoginRequestDef): Promise<AxiosResponse> => {
  return api.post(ENDPOINTS.login, data);
};

export const apiAuthGetMe = (): Promise<AxiosResponse> => {
  return api.get(ENDPOINTS.user);
};
export const apiAuthPutMe = (params: {
  isoLocaleCode: string
}): Promise<AxiosResponse> => {
  return api.put(ENDPOINTS.user, params);
};
