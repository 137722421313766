export const ENDPOINTS = {
  login: "login",
  groups: "latest/groups",
  user: "latest/users/me",
  userSettings: "latest/users/settings",
  groupDetail: (id: string) => `latest/groups/${id}`,
  groupDetailCondition: (id: string) => `latest/groups/${id}/conditions`,
  groupDetailInspectionForms: (id: string) =>
    `latest/groups/${id}/inspection-forms`,
  groupDetailInspectionDeleteOrUpdate: (
    groupId: string,
    machineId: string,
    customInspectionFormId: string
  ) =>
    `latest/groups/${groupId}/machines/${machineId}/inspection-forms/${customInspectionFormId}`,
  groupDetailInspectionFormStatus: (id: string) =>
    `latest/groups/${id}/inspection-form-status`,
  groupMachinesDetail: (id: string) => `latest/groups/${id}/machines`,
  machineInfo: (groupId: string, machineId: string) =>
    `latest/groups/${groupId}/machines/${machineId}`,
  machineReport: (groupId: string, machineId: string) =>
    `latest/groups/${groupId}/machines/${machineId}/machine-reports`,
  inspectionFormTemplates: (groupId: string, machineId: string) =>
    `latest/groups/${groupId}/machines/${machineId}/inspection-form-templates`,
  inspectionForms: (groupId: string, machineId: string) =>
    `latest/groups/${groupId}/machines/${machineId}/inspection-forms`,
  inspectionFormDetail: (
    groupId: string,
    machineId: string,
    inspectionFormId: string
  ) =>
    `latest/groups/${groupId}/machines/${machineId}/inspection-form-templates/${inspectionFormId}`,
  customInspectionFormDetail: (
    groupId: string,
    machineId: string,
    customInspectionFormId: string
  ) =>
    `latest/groups/${groupId}/machines/${machineId}/inspection-forms/${customInspectionFormId}`,
  inspectionForm: (groupId: string, machineId: string) =>
    `latest/groups/${groupId}/machines/${machineId}/inspection-forms`,
  uploadUrls: "/latest/blob-storage/generate-video-upload-urls",
  uploadStore: "",
  machineReportResponses: (
    groupId: string,
    machineId: string,
    machineReportId: string
  ) =>
    `latest/groups/${groupId}/machines/${machineId}/machine-reports/${machineReportId}/machine-report-responses`,
  machineReportStatus: (
    groupId: string,
    machineId: string,
    machineReportId: string
  ) =>
    `latest/groups/${groupId}/machines/${machineId}/machine-reports/${machineReportId}/read-status`,
  inspections: (groupId: string, machineId: string) =>
    `latest/groups/${groupId}/machines/${machineId}/inspections`,
  machineReportActionChoices: () => `latest/report-action-choices`,
  machineReportDetail: (
    groupId: string,
    machineId: string,
    machineReportId: string
  ) =>
    `/latest/groups/${groupId}/machines/${machineId}/machine-reports/${machineReportId}`,
  inspectionPdf: (groupId: string, machineId: string) =>
    `/latest/groups/${groupId}/machines/${machineId}/inspection-pdf`,
  machineOperationReport: (
    groupId: string,
    machineId: string,
    machineReportId: string
  ) =>
    `latest/groups/${groupId}/machines/${machineId}/machine-operation-reports/${machineReportId}`,
  machineReportInfo: (groupId: string, machineId: string) =>
    `/latest/groups/${groupId}/machines/${machineId}/machine-report-info`,
  machineSummary: (groupId: string, machineId: string) =>
    `/latest/groups/${groupId}/machines/${machineId}/summary`,
  maintenanceReport: (
    groupId: string,
    machineId: string,
    machineReportId: string
  ) =>
    `/latest/groups/${groupId}/machines/${machineId}/maintenance-reports/${machineReportId}`,
};
