import { InspectionsType } from "@app/types";
import { createSlice } from "@reduxjs/toolkit";

interface InspectionFormDialogProps {
  inspectionsTemplate: InspectionsType;
  machineId: string;
  currentInspectionInfo: {
    id: string;
    machineId: string;
    inspectionFormId: string;
  };
  isEditDraft: boolean;
  isRequestedMachineInfo: string;
  isUpdatedMachineReportResponses: string;
  isMachinePage: boolean;
}

const initialState = {
  inspectionsTemplate: {},
  machineId: "",
  currentInspectionInfo: {},
  isEditDraft: false,
  isRequestedMachineInfo: "",
  isUpdatedMachineReportResponses: `${new Date()}`,
} as InspectionFormDialogProps;

export const inspectionsSlice = createSlice({
  name: "inspection",
  initialState,
  reducers: {
    setInspectionsTemplate: (state, action) => {
      state.inspectionsTemplate = action.payload || "";
    },
    setMachineId: (state, action) => {
      state.machineId = action.payload || "";
    },
    setCurrentInspectionInfo: (state, action) => {
      state.currentInspectionInfo = action.payload || "";
    },
    setIsEditDraft: (state, action) => {
      state.isEditDraft = action.payload;
    },
    setIsRequestedMachineInfo: (state, action) => {
      state.isRequestedMachineInfo = action.payload;
    },
    setIsUpdatedMachineReportResponses: (state, action) => {
      state.isUpdatedMachineReportResponses = action.payload;
    },
    setIsMachinePage: (state, action) => {
      state.isMachinePage = action.payload;
    }
  },
});

export const {
  setInspectionsTemplate,
  setMachineId,
  setCurrentInspectionInfo,
  setIsEditDraft,
  setIsRequestedMachineInfo,
  setIsUpdatedMachineReportResponses,
  setIsMachinePage
} = inspectionsSlice.actions;

export default inspectionsSlice.reducer;
