import {
  apiGetMachineReportResponses,
  apiGroupDetail,
  apiMachineReportActionChoices,
  apiPostMachineReportResponses,
  getMachineInfo,
  getMachineReports,
  updateMachineReportStatus,
  apiGetMachineOperationReport,
  apiGetMaintenanceReport,
} from "@app/api";
import {
  DATE_TIME_FORMAT,
  DevicePlatform,
  DialogTypes,
  INVALID_URL,
  ReportActions,
  ResponseStatus,
  StorageKey,
  UploadFileType,
  MACHINE_OPERATION_REPORTS,
  FUEL_MAINTENANCE_REPORTS,
  INCIDENT_REPORTS,
  INSPECTION,
  MAINTENANCE_REPORTS,
  STATUS_UPDATES,
} from "@app/constants";
import {
  ConfirmDialog,
  ConfirmDialogProps,
  MachineInfo,
  MachineOpen,
  MediaUpload,
} from "@app/pages/Features";
import {
  Machine,
  MachineInfoType,
  machineReportActionChoice,
  screenPermission,
} from "@app/types";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate, Outlet } from "react-router-dom";
import {
  Tabs,
  Tab,
  Box,
  Avatar,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { DataDialog, Helmet, Video } from "@app/components/atoms";
import { useDispatch, useSelector } from "react-redux";
import {
  RootState,
  setIdReport,
  setIsMachinePage,
  setIsRequestedMachineInfo,
  setShowAlert,
  setReportType,
} from "@app/store";
import {
  IconUpdate,
  IconInvalidUrl,
  IconUser,
  IconInputError,
} from "@app/components/icons";
import {
  REGEX,
  formatNumberWithCommas,
  handleUploadStore,
  isMobile,
  resetIsContinuousLoading,
  scrollTo,
  setItem,
} from "@app/helpers";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Navigation, Pagination, Keyboard } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { format } from "date-fns";
import { useForm } from "react-hook-form";
import { setIsUpdatedMachineReportResponses } from "@app/store";
import { Footer } from "@app/components/organisms";

interface TabPanelProps {
  label: string;
  route: string;
  disabled?: boolean;
  className?: string;
}

//TODO: clear before get api
const dummyData = {
  reportTitle: "給油/整備",
  reportedAt: "2024/06/12 13:46",
  reporter: {
    userPictureUrl:
      "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c?w=164&h=164&fit=crop&auto=format&dpr=2",
    surname: "山田",
    givenName: "太郎",
    time: "1時間前",
    workTime: "2024/06/12 13:46",
    serviceMeter: 20,
  },
  fuelRefill: {
    oilAmount: 10,
    action: "車体下にオイル",
    adBlueRefill: "あり",
    comment: "コメントがここにくる",
  },
  coolantRefill: {
    type: "種類",
    waterAmount: 100,
    comment: "クローラーの部品を交換しました。",
  },
  replacement: {
    type: "フィルター(エンジン関連)",
    action: "対応",
    media: [
      {
        mediaUrl:
          "https://images.unsplash.com/photo-1522770179533-24471fcdba45?w=164&h=164&fit=crop&auto=format&dpr=2",
        mimeType: "image/jpeg",
      },
      {
        mediaUrl:
          "https://images.unsplash.com/photo-1522770179533-24471fcdba45?w=164&h=164&fit=crop&auto=format&dpr=2",
        mimeType: "image/jpeg",
      },
    ],
    comment: "クローラーの部品を交換しました。",
  },
};

export function MachineDetail() {
  const { t } = useTranslation();
  const { groupId, machineId } = useParams();
  const [machineInfo, setMachineInfo] = useState<MachineInfoType | undefined>();
  const [groupName, setGroupName] = useState<string>("");
  const [machineReportsOpen, setMachineReportsOpen] = useState<Machine[]>();
  const navigate = useNavigate();
  const [metaTitle, setMetaTitle] = useState<string>("");
  const [isRequestedReports, setIsRequestedReports] = useState(false);
  const [isRequestedGroupDetails, setIsRequestedGroupDetails] = useState(false);
  const [machineReportId, setMachineReportId] = useState<string>();
  const [openFormReport, setOpenFormReport] = useState<string>(INSPECTION);
  const [targetRow, setTargetRow] = useState<any>(null);
  const [openUpdateStatus, setOpenUpdateStatus] = useState(false);
  const [screenPermission, setScreenPermission] = useState<screenPermission>();
  const [mediaUploadErrorMsg, setMediaUploadErrorMsg] = useState("");
  const [currentMachineReportResponseIds, setCurrentMachineReportResponseIds] =
    useState<string[]>([]);
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  const [machineReportActionChoices, setMachineReportActionChoices] =
    useState<machineReportActionChoice[]>();
  const [isResolved, setIsResolved] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState<ConfirmDialogProps>({});
  const idReport = useSelector((state: RootState) => state?.report?.idReport);
  const reportType = useSelector(
    (state: RootState) => state?.reportType?.reportType
  );
  const { isUpdatedMachineReportResponses } = useSelector(
    (state: RootState) => state.inspection
  );
  const [isRead, setIsRead] = useState(false);
  const dispatch = useDispatch();
  const [bodyHeight, setBodyHeight] = useState(0);
  const [currentBodyHeight, setCurrentBodyHeight] = useState(0);
  const [reportTypeDialog, setReportTypeDialog] = useState(INSPECTION);
  const tabPanels: TabPanelProps[] = [
    {
      label: t("inspection"),
      route: `/groups/${groupId}/machines/${machineId}/inspection-history`,
      className: `tab-item `,
    },
    {
      label: t("labelReport"),
      route: `/groups/${groupId}/machines/${machineId}/report`,
      className: "tab-item",
    },
  ];

  const route = () => {
    let route = window.location.pathname;
    if (!route.includes("report")) {
      route = tabPanels[0].route;
    }
    return route;
  };
  const [valueRoute, setValueRoute] = useState(route);
  const [contentRef, setContentRef] = useState<any>(null);
  const {
    register,
    setValue,
    reset,
    getValues,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      reportComment: "",
      lat: null,
      lng: null,
      locationAccuracy: "string",
      devicePlatform: DevicePlatform.WEB,
      machineReportMedias: [] as File[],
      reportActionChoiceIds: [] as string[],
      status: "",
    },
  });
  const [isReportCommentInputFocus, setIsReportCommentInputFocus] =
    useState(false);
  const [prevReportComment, setPrevReportComment] = useState("");
  const uploadDescription = [
    `${t("dialogReport.pleaseUploadFile")}`,
    `<span class="fs-12">${t("dialogReport.thatCanBeUploadedAre")}</span>`,
    `<span class="fs-12">${t("dialogReport.fileFormats")}</span>`,
    `<span class="fs-12">${t("dialogReport.videosCanBeUploaded")}</span>`,
  ];

  const handleChange = (_: React.SyntheticEvent, newValueRoute: string) => {
    setValueRoute(newValueRoute);
  };

  const updateBtnDisableStatus = () => {
    const {
      reportComment,
      machineReportMedias,
      reportActionChoiceIds,
      status,
    } = watch();
    return (
      (!!reportComment ||
        !!reportActionChoiceIds?.length ||
        !!machineReportMedias?.length ||
        (!!status && status !== targetRow?.reportResponseStatus)) &&
      !errors?.reportComment?.type
    );
  };

  const resetForm = (isCancelUpdate = false) => {
    if (!isCancelUpdate) {
      setOpenFormReport(INSPECTION);
      setTargetRow(null);
      setMachineReportId("");
      dispatch(setIdReport(""));
    }
    setOpenUpdateStatus(false);
    setIsResolved(false);
    reset({
      reportComment: "",
    });
    setIsRead(false);
  };

  const handleClickConfirm = () => {
    if (openUpdateStatus) {
      setOpenDialogConfirm(true);
      setConfirmDialog({
        classBtnSubmit: "btn-primary",
        typeDialog: DialogTypes.CONFIRM,
        title: "titleConfirm",
        content: "",
        btnSubmitText: "ok",
        onCancel() {
          setOpenDialogConfirm(false);
        },
        onSubmit() {
          setOpenDialogConfirm(false);
          resetForm(true);
        },
      });
    } else {
      resetForm();
    }
  };

  const handleCloseDialog = () => {
    setMachineReportId("");
    setOpenFormReport(INSPECTION);
    setTargetRow(null);
    dispatch(setIdReport(""));
    dispatch(setReportType(INSPECTION));
  };

  const onBack = () => {
    navigate(`/groups/${groupId}`);
  };

  const subTitleDialog = (targetRow: any) => {
    return (
      !!targetRow && (
        <div
          className={`sub-title-dialog ${
            openFormReport === MAINTENANCE_REPORTS ? "fw-400" : ""
          }`}
        >
          {format(new Date(targetRow.reportedAt), DATE_TIME_FORMAT)}
          {openFormReport === INCIDENT_REPORTS && (
            <div
              className={`status-resolved ml-10 ${
                targetRow.reportResponseStatus === ResponseStatus.RESOLVED
                  ? "active"
                  : ""
              }`}
            >
              <span>
                {targetRow?.reportResponseStatus === ResponseStatus.RESOLVED
                  ? t("resolved")
                  : t("open")}
              </span>
            </div>
          )}
        </div>
      )
    );
  };

  const handleOnChangeReportActionChoice = (e: any) => {
    const reportActionChoiceIds = getValues("reportActionChoiceIds") || [];
    const { checked, value: choiceId } = e.target;
    if (checked) {
      reportActionChoiceIds.push(choiceId);
    } else {
      const targetIndex = reportActionChoiceIds.findIndex(
        (id) => id === choiceId
      );
      if (targetIndex > -1) {
        reportActionChoiceIds.splice(targetIndex, 1);
      }
    }
    setValue("reportActionChoiceIds", reportActionChoiceIds);
  };

  const getMachineReportId = (id: string) => {
    setMachineReportId(id);
    setReportTypeDialog(INCIDENT_REPORTS);
  };

  const getGroupDetail = (id: string) => {
    return apiGroupDetail(id).then((db) => {
      const { data } = db.data;
      setGroupName(data?.groupName);
    });
  };

  const convertDataRow = (data: Machine[]) => {
    return data.map((row: any) => ({
      ...row,
      id: row?.machineReportId,
      reporterName: `${row?.surname} ${row?.givenName}`,
    }));
  };

  const handleMediaOnError = (index: number, mediaIndex: number) => {
    const newTargetRow = {
      ...targetRow,
    };
    newTargetRow.machineReportResponses[index].machineReportMedias[
      mediaIndex
    ].mediaUrl = INVALID_URL;
    setTargetRow(newTargetRow);
  };

  const handleMediaOnMaintenanceError = (
    mediaIndex: number,
    machineReportResponseIndex?: number
  ) => {
    const newTargetRow = {
      ...targetRow,
    };
    if (machineReportResponseIndex !== undefined) {
      newTargetRow.machineReportResponses[
        machineReportResponseIndex
      ].machineReportMedias[mediaIndex].mediaUrl = INVALID_URL;
    } else {
      newTargetRow.machineReportMedias[mediaIndex].mediaUrl = INVALID_URL;
    }
    setTargetRow(newTargetRow);
  };

  const handleUserPictureOnError = (index?: number) => {
    if (index !== undefined) {
      const newMachineReportResponses = [...targetRow.machineReportResponses];
      newMachineReportResponses[index].userPictureUrl = INVALID_URL;
      const newTargetRow = {
        ...targetRow,
        machineReportResponses: newMachineReportResponses,
      };
      setTargetRow(newTargetRow);
    } else {
      const newTargetRow = { ...targetRow };
      newTargetRow.userPictureUrl = INVALID_URL;
      setTargetRow(newTargetRow);
    }
  };

  const getUserPicture = (
    item: any,
    machineReportResponseIndex?: number
  ): JSX.Element => {
    const hasUserPictureUrl = item?.userPictureUrl;
    const isUserPictureUrlInvalid = item.userPictureUrl === INVALID_URL;
    let userPicture = (
      // Default user icon
      <Box
        sx={{
          width: 40,
          height: 40,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <IconUser size={40} />
      </Box>
    );
    if (hasUserPictureUrl) {
      // Render invalid URL icon when user url fails to load
      if (isUserPictureUrlInvalid) {
        userPicture = (
          <Box
            sx={{
              minWidth: 40,
              height: 40,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconInvalidUrl />
          </Box>
        );
      } else {
        // Render avatar of user with error handling
        userPicture = (
          <Avatar
            sx={{
              width: 40,
              height: 40,
            }}
            alt="avatar-reporter"
            src={item.userPictureUrl}
            imgProps={{
              onError: () =>
                handleUserPictureOnError(machineReportResponseIndex),
            }}
          />
        );
      }
    }
    return userPicture;
  };

  const handleShowReportAction = (actions: string[]) => {
    return actions?.map((action: any) => {
      const actionCode =
        action?.reportActionChoiceCode || action?.reportActionCode;
      switch (actionCode) {
        case ReportActions.SELF_REPAIR:
          return (
            <span
              key={"selfRepair"}
              className={`label-action label-purple fs-12 ${
                action?.reportActionChoiceCode ? "ml-5" : ""
              } `}
            >
              {t("selfRepair")}
            </span>
          );
        case ReportActions.REPAIR_REQUEST:
          return (
            <span
              key={"repairRequest"}
              className={`label-action label-pink fs-12 ${
                action?.reportActionChoiceCode ? "ml-5" : ""
              } `}
            >
              {t("repairRequest")}
            </span>
          );
        default:
          return null;
      }
    });
  };

  const handleConvertMedia = async (files: File[]) => {
    const array = [];
    for await (const item of files) {
      const newItem = (await handleUploadStore(
        UploadFileType.MACHINE_REPORT,
        item
      )) as any;
      array.push(
        newItem
          ? {
              fileName: newItem?.name || newItem?.fileName,
              filePath: newItem?.filePath,
              mediaUrl: newItem?.filePath,
              mimeType: newItem?.type || newItem?.mimeType,
            }
          : null
      );
    }
    return array;
  };

  const getMachineReportResponses = (
    groupId: string,
    machineId: string,
    machineReportId: string
  ) => {
    return apiGetMachineReportResponses(groupId, machineId, machineReportId)
      .then((res) => {
        const { data, meta } = res.data;
        const { machineReportResponses, reportResponseStatus } = data;
        const machineReportResponseIds = machineReportResponses.map(
          (item: any) => item.machineReportResponseId
        );
        setValue("status", "");
        setIsResolved(reportResponseStatus === ResponseStatus.RESOLVED);
        setOpenFormReport(INCIDENT_REPORTS);
        setIsRead(!data.isRead && data.isRead !== null);
        setTargetRow(data);
        setScreenPermission(meta.screenPermission);
        setCurrentMachineReportResponseIds(machineReportResponseIds);
      })
      .catch(() => {
        setMachineReportId("");
      });
  };

  const getMachineOperationReport = (
    groupId: string,
    machineId: string,
    machineReportId: string
  ) => {
    return apiGetMachineOperationReport(groupId, machineId, machineReportId)
      .then((res) => {
        const { data } = res.data;
        setOpenFormReport(MACHINE_OPERATION_REPORTS);
        setTargetRow(data);
      })
      .catch(() => {
        setMachineReportId("");
      });
  };

  const getMaintenanceReport = (
    groupId: string,
    machineId: string,
    machineReportId: string
  ) => {
    return apiGetMaintenanceReport(groupId, machineId, machineReportId)
      .then((res) => {
        const { data } = res.data;
        setOpenFormReport(MAINTENANCE_REPORTS);
        setTargetRow(data);
      })
      .catch(() => {
        setMachineReportId("");
      });
  };

  const onSubmit = async (data: any) => {
    if (groupId && machineId && machineReportId) {
      setMediaUploadErrorMsg("");
      const newMachineReportMedias = await handleConvertMedia(
        data.machineReportMedias || []
      );
      if (newMachineReportMedias?.some((item) => item === null)) {
        setMediaUploadErrorMsg(t("messages.errConmpressImg"));
        return;
      }
      const payload = {
        ...data,
        machineReportMedias: newMachineReportMedias,
      };
      if (!data.status) {
        delete payload.status;
      }
      return apiPostMachineReportResponses(
        groupId,
        machineId,
        machineReportId,
        payload
      ).then((res) => {
        dispatch(setShowAlert(res?.data?.meta?.successMessage));
        reset({ reportActionChoiceIds: [], reportComment: "" });
        setOpenUpdateStatus(false);
        getMachineReportResponses(groupId, machineId, machineReportId).then(
          () => {
            dispatch(setIsUpdatedMachineReportResponses(`${new Date()}`));
          }
        );
      });
    }
  };

  const getMachineReportActionChoices = () => {
    return apiMachineReportActionChoices()
      .then((res) => {
        setMachineReportActionChoices(res.data.data);
      })
      .finally(() => {
        scrollToDialogContentBottom();
      });
  };

  const scrollToDialogContentBottom = () => {
    const contentEl = document.querySelector(
      ".dialog-report .MuiDialogContent-root .content"
    );
    contentEl &&
      scrollTo(contentEl, () => {
        scrollToDialogBottom();
      });
  };

  const renderMedia = (
    media: any,
    mediaIndex: number,
    machineReportResponseIndex?: number
  ) => {
    const isInvalidMediaUrl = media?.mediaUrl === INVALID_URL;
    const isMediaMimeTypeVideo = media?.mimeType.includes("video");
    if (isInvalidMediaUrl) {
      return (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconInvalidUrl />
        </Box>
      );
    }
    if (!isMediaMimeTypeVideo && !isInvalidMediaUrl) {
      return (
        <Box
          className="swiper-image img-report"
          component="img"
          alt="swiper-image"
          src={media.mediaUrl}
          onError={() =>
            machineReportResponseIndex
              ? handleMediaOnError(machineReportResponseIndex, mediaIndex)
              : handleMediaOnMaintenanceError(
                  mediaIndex,
                  machineReportResponseIndex
                )
          }
        />
      );
    }
    return (
      <Video
        media={media}
        onError={() =>
          machineReportResponseIndex
            ? handleMediaOnError(machineReportResponseIndex, mediaIndex)
            : handleMediaOnMaintenanceError(
                mediaIndex,
                machineReportResponseIndex
              )
        }
      />
    );
  };

  const scrollToDialogBottom = () => {
    const dialogContentEl = document.querySelector(
      ".dialog-report .MuiDialogContent-root"
    );
    dialogContentEl && scrollTo(dialogContentEl);
  };

  const getMachineInfoPage = (groupId: string, machineId: string) => {
    getMachineReports(groupId, machineId, {
      responseStatus: ResponseStatus.UNADDRESSED,
    })
      .then((db: { data: Machine[]; meta: any }) => {
        setMachineReportsOpen(convertDataRow(db?.data));
      })
      .finally(() => {
        setIsRequestedReports(true);
      });
  };

  useEffect(() => {
    if (!!groupId && !!machineId) {
      setItem(StorageKey.IS_CONTINUOUS_LOADING, "true");
      getGroupDetail(groupId).finally(() => {
        setIsRequestedGroupDetails(true);
      });
    }
  }, [groupId, machineId]);

  useEffect(() => {
    !!groupId &&
      !!machineId &&
      !!isRequestedGroupDetails &&
      !!isUpdatedMachineReportResponses &&
      getMachineInfoPage(groupId, machineId);
  }, [
    groupId,
    machineId,
    isRequestedGroupDetails,
    isUpdatedMachineReportResponses,
  ]);

  useEffect(() => {
    !!isRequestedReports &&
      groupId &&
      machineId &&
      getMachineInfo(groupId, machineId)
        .then((db) => {
          setMachineInfo({
            ...db,
            machineManufacturerName: db.isOtherMachineManufacturer
              ? db.customMachineManufacturerName || db.machineManufacturerName
              : db.machineManufacturerName,
            groupName,
          } as MachineInfoType);
        })
        .finally(() => {
          dispatch(setIsRequestedMachineInfo(`${new Date()}`));
        });
  }, [isRequestedReports]);

  useEffect(() => {
    if (!!machineInfo) {
      setMetaTitle(
        t(
          `metaInfo.${
            valueRoute.includes("inspection-history")
              ? "machineDetailInspection"
              : "machineDetailReport"
          }`,
          {
            customerMachineNo: machineInfo?.machineName,
          }
        )
      );
    }
    // When init route successfully
    if (!!bodyHeight) {
      setCurrentBodyHeight(bodyHeight);
    }
  }, [valueRoute, machineInfo]);

  useEffect(() => {
    setMachineReportId(idReport);
  }, [idReport]);

  useEffect(() => {
    setReportTypeDialog(reportType);
  }, [reportType]);

  useEffect(() => {
    if (groupId && machineId && machineReportId && reportTypeDialog) {
      switch (reportTypeDialog) {
        case INCIDENT_REPORTS:
          getMachineReportResponses(groupId, machineId, machineReportId);
          break;
        case MACHINE_OPERATION_REPORTS:
          getMachineOperationReport(groupId, machineId, machineReportId);
          break;
        case MAINTENANCE_REPORTS:
          getMaintenanceReport(groupId, machineId, machineReportId);
          break;
        default:
          setOpenFormReport(INSPECTION);
      }
    }
  }, [machineReportId, reportTypeDialog]);

  useEffect(() => {
    if (contentRef && targetRow) {
      scrollToDialogBottom();
    }
  }, [contentRef, targetRow]);

  useEffect(() => {
    if (groupId && machineId && machineReportId && isRead) {
      updateMachineReportStatus(
        groupId,
        machineId,
        machineReportId,
        isRead
      ).then(() => {
        if (!!machineReportsOpen) {
          const newMachineReportsOpen = [...machineReportsOpen];
          const index = machineReportsOpen?.findIndex(
            (item) => item.machineReportId === machineReportId
          );
          if (index > -1) {
            newMachineReportsOpen[index].isRead = true;
            setMachineReportsOpen(newMachineReportsOpen);
          }
        }
      });
    }
  }, [isRead]);

  useEffect(() => {
    if (openUpdateStatus) {
      setIsResolved(targetRow.reportResponseStatus === ResponseStatus.RESOLVED);
      getMachineReportActionChoices();
    } else {
      if (openFormReport) {
        scrollToDialogContentBottom();
      }
    }
  }, [openUpdateStatus]);

  useEffect(() => {
    dispatch(setIsMachinePage(true));
    return () => {
      dispatch(setIsRequestedMachineInfo(""));
      dispatch(setIsMachinePage(false));
      dispatch(setIdReport(""));
      dispatch(setReportType(""));
      resetIsContinuousLoading();
    };
  }, []);

  useEffect(() => {
    setValueRoute(route());
  }, [route()]);

  useEffect(() => {
    setBodyHeight(currentBodyHeight || document.body.offsetHeight);
  }, [document.body.offsetHeight]);

  return (
    <>
      <div className="machine-detail-page">
        <Helmet title={metaTitle} />
        <div className="container">
          <div className="row flex wrapper-machine-detail">
            <MachineInfo machineInfo={machineInfo} onBack={onBack} />
            <div className="machine-detail-right">
              <div
                style={{
                  minHeight: !isMobile()
                    ? `calc(${bodyHeight}px - 130px)`
                    : "auto",
                }}
                className="flex wrapper-machines-and-table"
              >
                {!!machineReportsOpen?.length && (
                  <MachineOpen
                    dbMachineOpen={machineReportsOpen}
                    machineReportIdCard={(id) => getMachineReportId(id)}
                  />
                )}
                <div className="machine-tabs">
                  <Box
                    className="data-tabs"
                    sx={{ width: "100%", marginTop: "24px" }}
                  >
                    <Box sx={{ borderBottom: 1, borderColor: "#d0d7de" }}>
                      <Tabs
                        value={valueRoute}
                        variant="scrollable"
                        onChange={handleChange}
                        TabIndicatorProps={{ style: { background: "#140a9a" } }}
                        className="tab-list"
                      >
                        {tabPanels.map((tab, index) => (
                          <Tab
                            key={index}
                            value={tab.route}
                            label={tab.label}
                            className={tab.className}
                            onClick={() => {
                              navigate(tab.route);
                            }}
                            disabled={tab.disabled}
                          />
                        ))}
                      </Tabs>
                    </Box>
                    <div role="tabpanel">
                      <Outlet />
                    </div>
                  </Box>
                </div>
              </div>
              <Footer className="d-none-sp" />
            </div>
            <DataDialog
              open={openFormReport === INCIDENT_REPORTS}
              title={targetRow?.reportTitle}
              subTitle={subTitleDialog(targetRow)}
              className={`dialog-report ${
                openUpdateStatus ? "full-height" : ""
              }  ${openFormReport === INSPECTION ? "d-none-imp" : "d-block"}`}
              dialogActions={
                <div className="flex justify-content-end group-btn-footer">
                  <Button
                    className="btn btn-no-border"
                    onClick={handleClickConfirm}
                  >
                    {t("dialogReport.cancel")}
                  </Button>
                  <Button
                    className={`btn btn-primary ml-8 ${
                      openUpdateStatus &&
                      screenPermission?.allowCreateInspectionAndReport
                        ? "d-block"
                        : "d-none-imp"
                    }`}
                    onClick={handleSubmit(onSubmit)}
                    disabled={!updateBtnDisableStatus()}
                  >
                    {t("dialogReport.update")}
                  </Button>
                  <Button
                    className={`ml-8 btn btn-primary ${
                      openUpdateStatus ||
                      !screenPermission?.allowCreateInspectionAndReport
                        ? "d-none-imp"
                        : "flex"
                    }`}
                    type="button"
                    onClick={() => setOpenUpdateStatus(true)}
                  >
                    <IconUpdate />
                    <span className="ml-8">
                      {t("dialogReport.updateStatus")}
                    </span>
                  </Button>
                </div>
              }
            >
              <Box component="form" autoComplete="off">
                <div
                  ref={(ref) => setContentRef(ref)}
                  className={`content ${
                    openUpdateStatus ? "show-update" : "un-show-update"
                  }`}
                >
                  {openFormReport === INCIDENT_REPORTS &&
                    targetRow?.machineReportResponses.map(
                      (item: any, index: number) => {
                        const userPicture = getUserPicture(item, index);
                        const {
                          serviceMeterInHour,
                          isChangeStatus,
                          reportActionChoices,
                          reportResponseStatus: itemReportResponseStatus,
                          machineReportMedias: itemMachineReportMedias,
                          reportComment: itemReportComment,
                          subtype,
                        } = item;
                        const hasReportActionChoices =
                          !!reportActionChoices.length;
                        const isReportResponseStatusResolved =
                          itemReportResponseStatus === ResponseStatus.RESOLVED;
                        const itemMachineReportMediasLength =
                          itemMachineReportMedias.length;
                        return (
                          <div className="report-card" key={item}>
                            <div className="content-report">
                              <div className="header flex align-items-center">
                                {userPicture}
                                <div className="report-detail">
                                  <h3 className="report-name txt-black-1 word-break txt-pre-wrap">{`${item.surname} ${item.givenName}`}</h3>
                                  <p className="report-time txt-black-1">
                                    {item.timeSinceCommentCreation}
                                  </p>
                                </div>
                              </div>
                              {!!item.machineReportMedias?.length && (
                                <Swiper
                                  cssMode={true}
                                  navigation={true}
                                  pagination={true}
                                  mousewheel={true}
                                  keyboard={true}
                                  modules={[
                                    Navigation,
                                    Pagination,
                                    Mousewheel,
                                    Keyboard,
                                  ]}
                                  className="swiper mt-20"
                                >
                                  {itemMachineReportMedias.map(
                                    (media: any, mediaIndex: number) => {
                                      return (
                                        <SwiperSlide
                                          key={`${media}_${mediaIndex}`}
                                        >
                                          <div className="wrapper-file">
                                            {renderMedia(
                                              media,
                                              mediaIndex,
                                              index
                                            )}
                                          </div>
                                        </SwiperSlide>
                                      );
                                    }
                                  )}
                                </Swiper>
                              )}
                              {(itemMachineReportMediasLength < 2 ||
                                itemReportComment) && (
                                <p
                                  className={`text-content txt-black-1 txt-pre-wrap word-break ${
                                    itemMachineReportMediasLength > 1 &&
                                    itemReportComment &&
                                    "pt-0"
                                  }`}
                                >
                                  {itemReportComment}
                                </p>
                              )}
                              {subtype !== STATUS_UPDATES && (
                                <div className="text-content">
                                  <p className="txt-gray-1 fs-12 mt-0 mb-0 txt-pre-wrap">
                                    {t("serviceMeterOrSMR")}
                                  </p>
                                  <p className="txt-black-1 fs-16 mt-0 mb-0 txt-pre-wrap">
                                    {serviceMeterInHour !== null
                                      ? formatNumberWithCommas(
                                          serviceMeterInHour,
                                          "h"
                                        )
                                      : "-"}
                                  </p>
                                </div>
                              )}
                            </div>
                            {(isChangeStatus || hasReportActionChoices) && (
                              <div className="status">
                                <div className="content-status">
                                  {hasReportActionChoices && (
                                    <div className="txt-gray-1">
                                      {t("tableMachineDetail.repairMethod")}:
                                      {handleShowReportAction(
                                        reportActionChoices
                                      )}
                                    </div>
                                  )}
                                  {isChangeStatus && (
                                    <div
                                      className={`${
                                        hasReportActionChoices ? "pt-44" : ""
                                      } txt-gray-1`}
                                    >
                                      {t("dialogReport.status")}:
                                      {isReportResponseStatusResolved ? (
                                        <span
                                          className={`status-resolved ml-5 active`}
                                        >
                                          {t("resolved")}
                                        </span>
                                      ) : (
                                        <span className="status-resolved ml-5">
                                          {t("open")}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      }
                    )}
                </div>
                {openUpdateStatus && (
                  <div className="update-status">
                    <FormGroup>
                      <div>
                        <span className="fs-14 txt-gray-3 mr-15">
                          {t("resolved")}
                        </span>
                        <Switch
                          className={`btn-switch ${isResolved && "checked"} `}
                          inputProps={{ "aria-label": "Switch A" }}
                          checked={isResolved}
                          onChange={() => {
                            setIsResolved(!isResolved);
                            setValue(
                              "status",
                              !isResolved
                                ? ResponseStatus.RESOLVED
                                : ResponseStatus.UNADDRESSED
                            );
                          }}
                        />
                      </div>
                      <div className="flex align-items-start repair">
                        <p className="label">
                          {t("dialogReport.repairAction")}
                        </p>
                        <div>
                          {machineReportActionChoices?.map((choice, index) => (
                            <FormControlLabel
                              className="checkbox"
                              key={index}
                              control={
                                <Checkbox
                                  defaultChecked={currentMachineReportResponseIds.includes(
                                    choice.reportActionChoiceId
                                  )}
                                  value={choice.reportActionChoiceId}
                                  onChange={handleOnChangeReportActionChoice}
                                />
                              }
                              label={choice.reportActionChoiceName}
                              style={{
                                display: "block",
                                marginBottom: index ? 0 : 16,
                              }}
                            />
                          ))}
                        </div>
                      </div>
                      <div className="wrapper-text-field mt-8">
                        <TextField
                          multiline
                          rows={3}
                          className="input-field"
                          label={t("dialogReport.comment")}
                          id={
                            errors?.reportComment?.type
                              ? "outlined-error"
                              : "outlined-controlled"
                          }
                          error={
                            !!errors?.reportComment?.type &&
                            errors?.reportComment?.type !== "maxLength"
                          }
                          {...register("reportComment", {
                            validate: {
                              hasEmoji: (value) => REGEX.emojiRegex.test(value),
                              hasInvalidChar: (value) =>
                                REGEX.commonNameFieldCharacterMuiltiLineRegex.test(
                                  value
                                ),
                            },
                            maxLength: 1000,
                            onBlur: (e) => {
                              if (errors?.reportComment?.type === "maxLength") {
                                setValue("reportComment", prevReportComment);
                              } else {
                                setValue(
                                  "reportComment",
                                  e.target.value?.trim()
                                );
                              }
                              setIsReportCommentInputFocus(false);
                            },
                            onChange: (e) => {
                              if (
                                !(errors?.reportComment?.type === "maxLength")
                              ) {
                                setPrevReportComment(
                                  e.target.value.slice(0, -1)
                                );
                              }
                            },
                          })}
                          onFocus={() => {
                            setIsReportCommentInputFocus(true);
                          }}
                          onClick={() => {
                            if (
                              isReportCommentInputFocus &&
                              errors?.reportComment?.type === "maxLength"
                            ) {
                              setValue("reportComment", prevReportComment);
                            }
                          }}
                          inputProps={{ maxLength: 1000 }}
                        />
                        {errors?.reportComment?.type &&
                          errors?.reportComment?.type !== "maxLength" && (
                            <IconInputError />
                          )}
                      </div>
                      {errors?.reportComment?.type && (
                        <p className="txt-red fs-12 mt-0 mb-0 ml-15">
                          {errors?.reportComment?.type === "hasInvalidChar" &&
                            t("messages.validCharacters")}
                          {errors?.reportComment?.type === "hasEmoji" &&
                            t("messages.validCharacters")}
                        </p>
                      )}
                      <div className="update-media-upload-file mt-24 mb-24 w-100">
                        <MediaUpload
                          setMedias={(files: File[]) => {
                            setValue("machineReportMedias", files);
                          }}
                          uploadDescription={uploadDescription}
                          errMessage={mediaUploadErrorMsg}
                        />
                      </div>
                    </FormGroup>
                  </div>
                )}
              </Box>
            </DataDialog>
            <DataDialog
              open={openFormReport === FUEL_MAINTENANCE_REPORTS}
              title={t("dialogRefuel.refuelOrMaintenance")}
              subTitle={subTitleDialog(dummyData)}
              className="dialog-report"
              dialogActions={
                <Button
                  onClick={() => setOpenFormReport(INSPECTION)}
                  className="btn btn-white"
                >
                  {t("close")}
                </Button>
              }
            >
              {dummyData && (
                <div className="content">
                  <div className="report-card">
                    <div className="content-report">
                      <div className="header flex align-items-center">
                        <Avatar
                          sx={{
                            width: 40,
                            height: 40,
                          }}
                          alt="avatar-reporter"
                          src={dummyData.reporter.userPictureUrl}
                        />
                        <div className="report-detail">
                          <h3 className="report-name txt-black-1 word-break txt-pre-wrap">{`${dummyData.reporter.surname} ${dummyData.reporter.givenName}`}</h3>
                          <p className="report-time txt-black-1">
                            {dummyData.reporter.time}
                          </p>
                        </div>
                      </div>
                      <div className="text-content">
                        <p className="txt-gray-1 fs-12 mt-0 mb-0 txt-pre-wrap">
                          {t("dialogRefuel.workTime")}
                        </p>
                        <p className="txt-black-1 fs-16 mt-0 mb-0 txt-pre-wrap">
                          {dummyData.reporter.workTime} h
                        </p>
                      </div>
                      <div className="text-content">
                        <p className="txt-gray-1 fs-12 mt-0 mb-0 txt-pre-wrap">
                          {t("serviceMeterOrSMR")}
                        </p>
                        <p className="txt-black-1 fs-16 mt-0 mb-0 txt-pre-wrap">
                          {dummyData.reporter.serviceMeter} h
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="report-card">
                    <div className="content-report">
                      <div className="text-content">
                        <p className="txt-gray-1 fs-12 mt-0 mb-0 txt-pre-wrap">
                          {t("dialogRefuel.items")}
                        </p>
                        <p className="txt-black-1 fs-16 mt-0 mb-0 txt-pre-wrap">
                          {t("dialogRefuel.fuelRefill")}
                        </p>
                      </div>
                      <div className="text-content">
                        <p className="txt-gray-1 fs-12 mt-0 mb-0 txt-pre-wrap">
                          {t("dialogRefuel.AdBlue")}
                        </p>
                        <p className="txt-black-1 fs-16 mt-0 mb-0 txt-pre-wrap">
                          {dummyData.fuelRefill.adBlueRefill}
                        </p>
                      </div>
                      <div className="text-content">
                        <p className="txt-gray-1 fs-12 mt-0 mb-0 txt-pre-wrap">
                          {t("dialogRefuel.oilWaterAmount")}
                        </p>
                        <p className="txt-black-1 fs-16 mt-0 mb-0 txt-pre-wrap">
                          {dummyData.fuelRefill.oilAmount} ℓ
                        </p>
                      </div>
                      <p className="text-content txt-black-1 txt-pre-wrap word-break ">
                        {dummyData.fuelRefill.comment}
                      </p>
                    </div>
                  </div>
                  <div className="report-card">
                    <div className="content-report">
                      <div className="text-content">
                        <p className="txt-gray-1 fs-12 mt-0 mb-0 txt-pre-wrap">
                          {t("dialogRefuel.items")}
                        </p>
                        <p className="txt-black-1 fs-16 mt-0 mb-0 txt-pre-wrap">
                          {t("dialogRefuel.oilCoolantRefillOrReplace")}
                        </p>
                      </div>
                      <div className="text-content">
                        <p className="txt-gray-1 fs-12 mt-0 mb-0 txt-pre-wrap">
                          {t("dialogRefuel.type")}
                        </p>
                        <p className="txt-black-1 fs-16 mt-0 mb-0 txt-pre-wrap">
                          {dummyData.coolantRefill.type}
                        </p>
                      </div>
                      <div className="text-content">
                        <p className="txt-gray-1 fs-12 mt-0 mb-0 txt-pre-wrap">
                          {t("dialogRefuel.fuelAmount")}
                        </p>
                        <p className="txt-black-1 fs-16 mt-0 mb-0 txt-pre-wrap">
                          {dummyData.coolantRefill.waterAmount} ℓ
                        </p>
                      </div>
                      <p className="text-content txt-black-1 txt-pre-wrap word-break ">
                        {dummyData.coolantRefill.comment}
                      </p>
                    </div>
                  </div>
                  <div className="report-card">
                    <div className="content-report">
                      <div className="text-content">
                        <p className="txt-gray-1 fs-12 mt-0 mb-0 txt-pre-wrap">
                          {t("dialogRefuel.items")}
                        </p>
                        <p className="txt-black-1 fs-16 mt-0 mb-0 txt-pre-wrap">
                          {t("dialogRefuel.partsReplace")}
                        </p>
                      </div>
                      <div className="text-content">
                        <p className="txt-gray-1 fs-12 mt-0 mb-0 txt-pre-wrap">
                          {t("dialogRefuel.type")}
                        </p>
                        <p className="txt-black-1 fs-16 mt-0 mb-0 txt-pre-wrap">
                          {dummyData.replacement.type}
                        </p>
                      </div>
                      <Swiper
                        cssMode={true}
                        navigation={true}
                        pagination={true}
                        mousewheel={true}
                        keyboard={true}
                        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                        className="swiper mt-20"
                      >
                        {dummyData.replacement.media.map((media: any) => {
                          return (
                            <SwiperSlide key={media}>
                              <div className="wrapper-file">
                                <Box
                                  sx={{
                                    height: "inherit",
                                    position: "relative",
                                  }}
                                >
                                  <Box
                                    className="swiper-image img-report"
                                    component={`${
                                      media.mimeType.includes("video")
                                        ? "video"
                                        : "img"
                                    }`}
                                    controls
                                    alt="swiper-image"
                                    src={media.mediaUrl}
                                    poster={media?.thumbnailUrl || ""}
                                  />
                                </Box>
                              </div>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                      <p className="text-content txt-black-1 txt-pre-wrap word-break ">
                        {dummyData.replacement.comment}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </DataDialog>
            <DataDialog
              open={openFormReport === MACHINE_OPERATION_REPORTS}
              title={t("dialogRefuel.refuelOrMaintenance")}
              subTitle={subTitleDialog(targetRow)}
              className="dialog-report"
              dialogActions={
                <Button onClick={handleCloseDialog} className="btn btn-white">
                  {t("close")}
                </Button>
              }
            >
              {openFormReport === MACHINE_OPERATION_REPORTS && targetRow && (
                <div className="content">
                  <div className="report-card">
                    <div className="content-report">
                      <div className="header flex align-items-center">
                        <Avatar
                          sx={{
                            width: 40,
                            height: 40,
                          }}
                          alt="avatar-reporter"
                          src={targetRow.userPictureUrl}
                        />
                        <div className="report-detail">
                          <h3 className="report-name txt-black-1 word-break txt-pre-wrap">{`${targetRow.surname} ${targetRow.givenName}`}</h3>
                          <p className="report-time txt-black-1">
                            {targetRow.reportedAt}
                          </p>
                        </div>
                      </div>
                      <div className="text-content">
                        <p className="txt-gray-1 fs-12 mt-0 mb-0 txt-pre-wrap">
                          {t("operation.startTime")}
                        </p>
                        <p className="txt-black-1 fs-16 mt-0 mb-0 txt-pre-wrap">
                          {targetRow.startAt}
                        </p>
                      </div>
                      <div className="text-content">
                        <p className="txt-gray-1 fs-12 mt-0 mb-0 txt-pre-wrap">
                          {t("operation.endTime")}
                        </p>
                        <p className="txt-black-1 fs-16 mt-0 mb-0 txt-pre-wrap">
                          {targetRow.endAt}
                        </p>
                      </div>
                      <p className="text-content txt-black-1 txt-pre-wrap word-break ">
                        {targetRow.comment}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </DataDialog>
            <DataDialog
              open={openFormReport === MAINTENANCE_REPORTS}
              title={
                openFormReport === MAINTENANCE_REPORTS
                  ? targetRow?.regularMaintenanceItemChoice
                      .regularMaintenanceItemChoiceName
                  : ""
              }
              subTitle={subTitleDialog(targetRow)}
              className={`dialog-report ${
                openFormReport === INSPECTION ? "d-none-imp" : "d-block"
              }`}
              dialogActions={
                <Button onClick={handleCloseDialog} className="btn btn-white">
                  {t("close")}
                </Button>
              }
            >
              {openFormReport === MAINTENANCE_REPORTS && targetRow && (
                <div className="content">
                  <div className="report-card">
                    <div className="content-report">
                      <div className="header flex align-items-center">
                        {getUserPicture(targetRow)}
                        <div className="report-detail">
                          <h3 className="report-name txt-black-1 word-break txt-pre-wrap">{`${targetRow.surname} ${targetRow.givenName}`}</h3>
                          <p className="report-time txt-black-1">
                            {targetRow.timeSinceCommentCreation}
                          </p>
                        </div>
                      </div>
                      <div className="text-content">
                        <p className="txt-gray-1 fs-12 mt-0 mb-0 txt-pre-wrap fw-500">
                          {t("dialogMaintenanceReports.workTime")}
                        </p>
                        <p className="txt-black-1 fs-16 mt-0 mb-0 txt-pre-wrap">
                          {format(new Date(targetRow.workAt), DATE_TIME_FORMAT)}
                        </p>
                      </div>
                      {targetRow.serviceMeterInHour !== null && (
                        <div className="text-content pt-0">
                          <p className="txt-gray-1 fs-12 mt-0 mb-0 txt-pre-wrap fw-500">
                            {t("serviceMeterOrSMR")}
                          </p>
                          <p className="txt-black-1 fs-16 mt-0 mb-0 txt-pre-wrap">
                            {formatNumberWithCommas(
                              targetRow.serviceMeterInHour,
                              "h"
                            )}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="report-card">
                    <div className="content-report">
                      <div className="text-content pb-0">
                        <p className="txt-gray-1 fs-12 mt-0 mb-0 txt-pre-wrap fw-500">
                          {t(
                            "dialogMaintenanceReports.irregularMaintenanceItem"
                          )}
                        </p>
                        {!!targetRow.irregularMaintenanceItemChoices.length
                          ? targetRow.irregularMaintenanceItemChoices.map(
                              (item: any) => (
                                <p className="txt-black-1 fs-16 mt-0 mb-0 txt-pre-wrap">
                                  {item.irregularMaintenanceItemChoiceName}
                                </p>
                              )
                            )
                          : "-"}
                      </div>

                      <div className="text-content">
                        <p className="txt-gray-1 fs-12 mt-0 mb-0 txt-pre-wrap fw-500">
                          {t("dialogMaintenanceReports.maintenanceReason")}
                        </p>
                        <p className="txt-black-1 fs-16 mt-0 mb-0 txt-pre-wrap">
                          {`${
                            targetRow.maintenanceReasonChoice
                              .maintenanceReasonChoiceName
                          }　${
                            targetRow.maintenanceReasonPeriodChoice
                              ?.maintenanceReasonPeriodChoiceName || ""
                          }`}
                        </p>
                      </div>
                      <Swiper
                        cssMode={true}
                        navigation={true}
                        pagination={true}
                        mousewheel={true}
                        keyboard={true}
                        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                        className="swiper"
                      >
                        {targetRow.machineReportMedias.map(
                          (media: any, mediaIndex: number) => {
                            return (
                              <SwiperSlide key={`${media}_${mediaIndex}`}>
                                <div className="wrapper-file">
                                  {renderMedia(media, mediaIndex)}
                                </div>
                              </SwiperSlide>
                            );
                          }
                        )}
                      </Swiper>
                      {(targetRow.machineReportMedias.length < 2 ||
                        targetRow.comment) && (
                        <p
                          className={`text-content txt-black-1 txt-pre-wrap word-break ${
                            targetRow.machineReportMedias.length > 1 &&
                            targetRow.comment &&
                            "pt-0"
                          } `}
                        >
                          {targetRow.comment}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </DataDialog>
            <ConfirmDialog
              open={openDialogConfirm}
              setOpen={setOpenDialogConfirm}
              {...confirmDialog}
            />
          </div>
        </div>
      </div>
      <Footer className="d-none d-block-sp" />
    </>
  );
}
