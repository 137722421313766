export function IconPlay() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="57"
      viewBox="0 0 56 57"
      fill="none"
    >
      <rect
        x="5.66406"
        y="5.91504"
        width="44.67"
        height="44.67"
        rx="23.335"
        fill="white"
      ></rect>
      <path
        d="M27.9993 4.91699C15.1193 4.91699 4.66602 15.3703 4.66602 28.2503C4.66602 41.1303 15.1193 51.5837 27.9993 51.5837C40.8793 51.5837 51.3327 41.1303 51.3327 28.2503C51.3327 15.3703 40.8793 4.91699 27.9993 4.91699ZM23.3327 38.7503V17.7503L37.3327 28.2503L23.3327 38.7503Z"
        fill="#140A9A"
      />
    </svg>
  );
}
