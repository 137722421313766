export function IconEdit({ className = "", fillColor = "black" }) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.06 4.33988L20.41 5.68988C21.2 6.46988 21.2 7.73988 20.41 8.51988L7.18 21.7499H3V17.5699L13.4 7.15988L16.23 4.33988C17.01 3.55988 18.28 3.55988 19.06 4.33988ZM5 19.7499L6.41 19.8099L16.23 9.97988L14.82 8.56988L5 18.3899V19.7499Z"
        fill={fillColor}
      />
    </svg>
  );
}
