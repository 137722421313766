import { Helmet } from "@app/components/atoms";
import { IconAppleQR, IconGoogleQR, IconVector } from "@app/components/icons";
import { Footer } from "@app/components/organisms";
import { useTranslation } from "react-i18next";

export function ErrorAccount() {
  const { t } = useTranslation();

  return (
    <div className="page-not-found bg-gray">
      <Helmet title={t("metaInfo.errorAccount")} />
      <div className="container">
        <div className="row">
          <div className="min-height-no-footer wrapper-content sp-p24">
            <div className="text-center bg-white content">
              <IconVector className="mt-5" />
              <h3 className="m-0 txt-black-1 fw-700 fs-18 mt-16 lh-28">
                {t("errorAccount.title")}
              </h3>
              <p className="m-0 txt-black-1 mt-8 fs-14 txt-pre-wrap">
                {t("errorAccount.description")}
              </p>
              <div className="mt-40 flex-centered">
                <div>
                  <IconAppleQR />
                  <p className="fs-12 txt-gray-1">iOS</p>
                </div>
                <div className="ml-40">
                  <IconGoogleQR />
                  <p className="fs-12 txt-gray-1">Android</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
