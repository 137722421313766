import { getUser } from "@app/api";
import { DefaultLayout } from "@app/components/layouts";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { store, setUser, setSuppressDataUsagePopup } from "@app/store";
import { ApiErrorType, ApiStatusCodes, SelectLanguage } from "@app/constants";

export function Features() {
  const { i18n } = useTranslation();
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    getUser()
      .then((res) => {
        if (res?.data?.data) {
          const {
            isoLocaleCode,
            surname,
            givenName,
            pictureUrl,
            suppressDataUsagePopup,
          } = res.data.data;
          const currenLanguage =
            isoLocaleCode !== SelectLanguage.EN &&
            isoLocaleCode !== SelectLanguage.JA
              ? SelectLanguage.EN
              : isoLocaleCode;

          i18n.changeLanguage(currenLanguage);
          store.dispatch(
            setUser({
              fullName: `${surname} ${givenName}`,
              pictureUrl,
            })
          );
          store.dispatch(setSuppressDataUsagePopup(suppressDataUsagePopup));
        }
        setHasLoaded(true);
      })
      .catch((error) => {
        const errorStatus =
          error?.response?.status || error?.response?.statusCode || error.code;
        const errorType = error?.response?.data?.error?.errorType;
        if (
          errorStatus === ApiStatusCodes.NOT_FOUND &&
          errorType === ApiErrorType.USER_NOT_FOUND
        ) {
          setHasLoaded(false);
        } else {
          setHasLoaded(true);
        }
      });
  }, []);

  return <DefaultLayout>{hasLoaded && <Outlet />}</DefaultLayout>;
}
