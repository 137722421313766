import { AxiosResponse } from "axios";

import { api, ENDPOINTS } from "@app/api";

export const getUser = (): Promise<AxiosResponse> => {
  return api.get(ENDPOINTS.user);
};

export const deleteUser = (): Promise<AxiosResponse> => {
  return api.delete(ENDPOINTS.user);
};

export const putUserSettings = (params: {
  suppressDataUsagePopup: boolean;
}): Promise<AxiosResponse> => {
  return api.put(ENDPOINTS.userSettings, params);
};
