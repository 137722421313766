import { Helmet } from "@app/components/atoms";
import { useTranslation } from "react-i18next";

export function NotFound() {
  const { t } = useTranslation();

  return (
    <div className="page-not-found">
      <Helmet title={t("metaInfo.pageNotFound")} />
      <div className="container">
        <div className="row">
          <div className="wrapper-content sp-p24">
            <div className="text-center bg-white content">
              <h3 className="m-0 txt-black-1 fs-18 fw-700">
                {t("titleNotFound")}
              </h3>
              <p className="m-0 txt-black-1 mt-8 fs-14">{t("desNotFound")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
