export function IconInputError() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      className="icon-input-err"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM9 15.5V13.5H11V15.5H9ZM9 5.5V11.5H11V5.5H9Z"
        fill="#DA093B"
      />
    </svg>
  );
}
