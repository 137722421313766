import React from "react";
import ReactDOM from "react-dom/client";
import {
  QueryClient,
  QueryFunctionContext,
  QueryClientProvider,
} from "react-query";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { api } from "@app/api";
import reportWebVitals from "@app/reportWebVitals";
import router from "@app/routes/routes";
import { store } from "@app/store";
import "@app/styles/scss/styles.scss";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "@app/types";
import i18n from "i18next";
import { I18nextProvider, initReactI18next } from "react-i18next";
import intervalPlural from "i18next-intervalplural-postprocessor";
import translationEn from "@app/locale/en.json";
import translationJa from "@app/locale/ja.json";
import { SelectLanguage } from "@app/constants";

// Define a default query function that will receive the query key
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const defaultQueryFn = async ({ queryKey }: QueryFunctionContext<any>) => {
  const { data } = await api.get(queryKey[0]);
  return data;
};

// Provide the default query function to your app via the query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
    },
  },
});
const i18nInstance = i18n.createInstance();
const resources = {
  en: {
    translation: translationEn,
  },
  ja: {
    translation: translationJa,
  },
};
const userLang = navigator.language;
const currentLanguage =
  userLang !== SelectLanguage.EN && userLang !== SelectLanguage.JA
    ? SelectLanguage.EN
    : userLang;
i18nInstance
  .use(initReactI18next)
  .use(intervalPlural)
  .init({
    resources,
    fallbackLng: currentLanguage,
    interpolation: {
      escapeValue: false,
    },
  });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <I18nextProvider i18n={i18nInstance} defaultNS={"translation"}>
            <RouterProvider router={router} />
          </I18nextProvider>
        </QueryClientProvider>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
