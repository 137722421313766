import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ArrowBackIos, KeyboardArrowDown } from "@mui/icons-material";
import {
  IconCheckDone,
  IconError,
  IconInvalidUrl,
  IconWarning,
} from "@app/components/icons";
import { Box, Button } from "@mui/material";
import { isMobile } from "@app/helpers";
import { MachineInfoType } from "@app/types";

import { DataDialog } from "@app/components/atoms";
import { INVALID_URL } from "@app/constants";

type MachineInfoProps = {
  onBack?: () => void;
  machineInfo?: MachineInfoType;
};

export function MachineInfo({
  onBack = () => {},
  machineInfo = {} as MachineInfoType,
}: MachineInfoProps) {
  const { t } = useTranslation();
  const [showContentDrop, setShowContentDrop] = useState(() => !isMobile());
  const [machineInfoPictureUrl, setMachineInfoPictureUrl] = useState<
    string | undefined
  >();
  const [serialNumberPlatePictureUrl, setSerialNumberPlatePictureUrl] =
    useState<string | undefined>();
  const [open, setOpen] = useState(false);

  const handleOnClick = (machineInfo: MachineInfoType) => {
    if (!machineInfo?.serialNumberPlatePictureUrl) {
      return;
    }
    setOpen(true);
  };

  useEffect(() => {
    if (machineInfo?.pictureUrl) {
      setMachineInfoPictureUrl(machineInfo.pictureUrl);
    }
    if (machineInfo?.serialNumberPlatePictureUrl) {
      setSerialNumberPlatePictureUrl(machineInfo.serialNumberPlatePictureUrl);
    }
  }, [machineInfo]);

  return (
    <div className="machine-info sp-p24">
      <div
        className="machine-title flex align-items-center pointer"
        onClick={() => onBack()}
      >
        <ArrowBackIos className="icon-back txt-blue" />
        <h3 className="title txt-blue m-0 word-break txt-pre-wrap">
          {machineInfo?.groupName}
        </h3>
      </div>
      <div
        className="wrapper-dropdown-info"
        onClick={() => setShowContentDrop(!showContentDrop)}
      >
        {machineInfo?.pictureUrl && (
          <div className="wrapper-image">
            {machineInfoPictureUrl ? (
              <Box
                className="info-image"
                component="img"
                alt="info-image"
                src={machineInfoPictureUrl}
                onError={() => setMachineInfoPictureUrl(undefined)}
              />
            ) : (
              <Box className="info-image">
                <IconInvalidUrl />
              </Box>
            )}
            <div className="info-status flex align-items-center">
              <div className="text mr-10 word-break txt-pre-wrap">
                {machineInfo?.machineName}
              </div>
              {machineInfo?.machineCondition === "NORMAL" && <IconCheckDone />}
              {machineInfo?.machineCondition === "WARNING" && <IconWarning />}
              {machineInfo?.machineCondition === "ERROR" && <IconError />}
            </div>
          </div>
        )}
        <KeyboardArrowDown
          className={`icon-arrow-down ${showContentDrop ? "rotate-180" : ""}`}
        />
      </div>
      {showContentDrop && (
        <div className="info-list mt-20">
          <div className="info-item">
            <span className="info-item-label">{t("machineInfo.maker")}</span>
            <div className="info-item-content word-break txt-pre-wrap">
              {machineInfo?.machineManufacturerName}
            </div>
          </div>
          <div className="info-item">
            <span className="info-item-label">
              {t("machineInfo.machineType")}
            </span>
            <div className="info-item-content word-break txt-pre-wrap">
              {machineInfo?.isOtherMachineType && machineInfo?.customTypeName
                ? machineInfo?.customTypeName
                : machineInfo?.machineTypeName}
            </div>
          </div>
          <div className="info-item">
            <span className="info-item-label">
              {t("machineInfo.modelType")}
            </span>
            <div className="info-item-content word-break txt-pre-wrap">
              {machineInfo?.modelAndType}
            </div>
          </div>
          <div className="info-item">
            <span className="info-item-label">{t("machineInfo.serialNo")}</span>
            <div className="info-item-content word-break txt-pre-wrap">
              {machineInfo?.serialNumberPlatePictureUrl ? (
                <span
                  className={
                    machineInfo?.serialNumberPlatePictureUrl &&
                    "has-text-link pointer"
                  }
                  onClick={() => handleOnClick(machineInfo)}
                >
                  {machineInfo?.serialNumber}
                </span>
              ) : (
                machineInfo?.serialNumber
              )}
            </div>
          </div>
        </div>
      )}
      {serialNumberPlatePictureUrl && (
        <DataDialog
          title={t("serialNumberPlate")}
          open={open}
          dialogActions={
            <Button onClick={() => setOpen(false)} className="btn btn-white">
              {t("close")}
            </Button>
          }
        >
          {serialNumberPlatePictureUrl === INVALID_URL ? (
            <Box
              sx={{
                width: 512,
                height: 512,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconInvalidUrl />
            </Box>
          ) : (
            <img
              className="img-dialog"
              src={`${machineInfo.serialNumberPlatePictureUrl}`}
              alt={machineInfo?.machineManufacturerName}
              loading="lazy"
              onError={() => {
                setSerialNumberPlatePictureUrl(INVALID_URL);
              }}
            />
          )}
        </DataDialog>
      )}
    </div>
  );
}
