import { StorageKey } from "@app/constants";
import { ApiResponseDef, TokenDef } from "@app/types";
import { useCookies } from "react-cookie";

export const useToken = () => {
  const [cookies, setCookie, removeCookie] = useCookies([
    StorageKey.AUTH_ACCESS_TOKEN,
    StorageKey.LAST_UPDATED_AT,
  ]);

  /**
   * Save token and refresh token to session cookie,
   * Default value used for demo API
   */
  const saveTokens = ({
    token,
    accountId = cookies.tokens?.accountId,
  }: ApiResponseDef) => {
    const cookieToken: TokenDef = {
      accessToken: token,
      accountId
    };

    setCookie(StorageKey.AUTH_ACCESS_TOKEN, cookieToken, {
      path: "/",
    });
  };

  /**
   * Loads token from session cookie
   */
  const getTokens = () => {
    const cookieToken: TokenDef | undefined =
      cookies[StorageKey.AUTH_ACCESS_TOKEN];

    return {
      accessToken: cookieToken?.accessToken,
      refreshToken: cookieToken?.refreshToken,
      expiresAt: cookieToken?.expiresAt,
    } as TokenDef;
  };

  /**
   * Clear token from session cookie
   */
  const clearTokens = () => {
    return removeCookie(StorageKey.AUTH_ACCESS_TOKEN, { path: "/" });
  };

  return {
    cookies,
    getTokens,
    saveTokens,
    clearTokens,
  };
};
