/**
 * API response status codes enum
 */
export enum ApiStatusCodes {
  SUCCESS = 200,
  CREATED = 201,
  FORBIDDEN = 403,
  NO_CONTENT = 204,
  UNAUTHORIZED = 401,
  NOT_FOUND = 404,
  SERVER_ERROR = 500,
  ECONNABORTED = "ECONNABORTED",
  ERR_NETWORK = "ERR_NETWORK",
}

export enum ApiErrorType {
  MACHINE_NOT_FOUND = "MachineNotFound",
  INSPECTION_FORM_NOT_FOUND = "InspectionFormNotFound",
  USER_NOT_FOUND = "UserNotFound",
  GROUP_NOT_FOUND = "GroupNotFound",
  MACHINE_REPORT_NOT_FOUND = "MachineReportNotFound",
  INSPECTION_FORM_Template_NOT_FOUND = "InspectionFormTemplateNotFound",
}
