import React from "react";
import { Button as MuiButton } from "@mui/material";
import { UploadOutlined } from "@mui/icons-material";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  htmlFor: string;
  children: (React.ReactNode | string)[] | React.ReactNode | string;
  className?: string;
  onClick?: () => void;
}

// this component is used for file upload button
// the purpose of this component is control input file upload
// therefore set component="label" and startIcon={<UploadOutlined className="mr-5" />}
export function FileUploadButton({
  children,
  className,
  htmlFor,
}: ButtonProps) {
  return (
    <MuiButton
      htmlFor={htmlFor}
      component="label"
      startIcon={<UploadOutlined className="mr-5" />}
      className={`btn ${className}`}
    >
      {children}
    </MuiButton>
  );
}
