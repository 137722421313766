export function Footer({ className = "" }) {
  return (
    <footer className={`page-footer ${className}`}>
      <div className="container">
        <div className="row flex-centered fs-14 txt-black-1">
          © Komatsu Ltd.
        </div>
      </div>
    </footer>
  );
}
