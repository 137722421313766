import { Box } from "@mui/material";

interface VideoProps {
  media: any;
  onError: () => void;
}

export function Video({ media, onError }: VideoProps) {
  return (
    <Box sx={{ height: "inherit", position: "relative" }}>
      <Box
        className="swiper-image img-report"
        component={`${media.mimeType.includes("video") ? "video" : "img"}`}
        controls
        alt="swiper-image"
        src={media.mediaUrl}
        onError={onError}
        poster={media?.thumbnailUrl || ""}
      />
    </Box>
  );
}
