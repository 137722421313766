import { configureStore } from "@reduxjs/toolkit";
import {
  msgReducer,
  groupsReducer,
  cardsReducer,
  userReducer,
  inspectionsReducer,
  errorReducer,
  loadingReducer,
  screenPermissionSliceReducer,
  reportReducer,
  reportTypeReducer,
  dataUsageReducer,
  groupDetailReducer,
} from "@app/store";
export const store = configureStore({
  reducer: {
    msg: msgReducer,
    groups: groupsReducer,
    cards: cardsReducer,
    user: userReducer,
    inspection: inspectionsReducer,
    error: errorReducer,
    loading: loadingReducer,
    screenPermission: screenPermissionSliceReducer,
    report: reportReducer,
    reportType: reportTypeReducer,
    dataUsage: dataUsageReducer,
    groupDetail: groupDetailReducer
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
