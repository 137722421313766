import { getItem, setItem } from "@app/helpers/storage.helper";
import { useEffect } from "react";
import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { useTranslation } from "react-i18next";
import { store, setError, setIsLoading } from "@app/store";
import { StorageKey, ApiStatusCodes, ApiErrorType } from "@app/constants";
import { useAuth } from "@app/hooks";
import { useCustomMsal } from "@app/hooks/useMsal";
import jsCookie from "js-cookie";
import { useForceLogout } from "@app/hooks/useForceLogout";
import { resetIsContinuousLoading } from "@app/helpers";

export type CustomAxiosRequestConfig = AxiosRequestConfig & {};
const TIMEOUT_SECONDS = 60000;

/** Setup an API instance */
export const api = axios.create({
  timeout: TIMEOUT_SECONDS,
  baseURL: process.env.REACT_APP_API_HOST,
  headers: {
    "Content-Type": "application/json",
    platform: "WEBAPP",
  },
});

/**
 * Adds authorization headers to API calls
 * @param {InternalAxiosRequestConfig} request
 */
export const AuthInterceptor = () => {
  const { t, i18n } = useTranslation();
  const { logout } = useAuth();
  const { setTimer, isExpired } = useForceLogout();
  const { refreshToken } = useCustomMsal();

  useEffect(() => {
    const reqInterceptor = async (config: InternalAxiosRequestConfig) => {
      const { headers } = config;
      if (headers) {
        const uploadInfo = getItem(StorageKey.MEDIA);
        if (uploadInfo) {
          const { baseUrl, contentType } = uploadInfo;
          config.baseURL = baseUrl;
          config.timeout = undefined;
          headers
            .set("x-ms-blob-type", "BlockBlob")
            .set("Content-Type", contentType);
        } else {
          // check user's action in 60mins
          // get lastUpdatedAt from cookie
          const expireResult = isExpired();
          if (expireResult) {
            await logout();
          }
          // otherwise, update lastUpdatedAt in cookie
          setTimer();

          // TODO: temporary solution to get latest cookie by `jsCookie.get`
          // the issue is `cookies` from react-cookie hook isn't update when while execute reqInterceptor even after update cookie by `refreshToken`.
          // Because `cookies` is hook and only can execute when AuthInterceptor execute, therefore the state of cookie isn't the latest while execute reqInterceptor even after refresh.
          // Tried to use `updateTokens` from react-cookie hook, but still not working.
          // So, use `jsCookie.get` to manually get token from cookie and use it for new request
          const tokenString = jsCookie.get(StorageKey.AUTH_ACCESS_TOKEN);
          if (tokenString) {
            const tokens = JSON.parse(tokenString);
            headers
              .set("X-Access-Token", `Bearer ${tokens.accessToken}`)
              .set("x-lang", i18n.language);
          }
        }
      }
      store.dispatch(setIsLoading(true));
      return config;
    };

    const reqErrInterceptor = (error: AxiosError) => {
      return Promise.reject(error);
    };

    const resInterceptor = (response: AxiosResponse) => {
      if (!getItem(StorageKey.IS_CONTINUOUS_LOADING)) {
        store.dispatch(setIsLoading(false));
      }
      return response;
    };

    const resErrInterceptor = async (error: any) => {
      const groupId = error?.response?.data?.error?.path?.split("/")[3];
      const errorStatus =
        error?.response?.status || error?.response?.statusCode || error.code;
      const errorType = error?.response?.data?.error?.errorType;
      const isDeleted = error?.response?.data?.error?.isDeleted;
      let content =
        error?.response?.data?.error?.localizedMessage || t("unexpectedError");
      let title = "error";
      let btn = {
        title: t("ok"),
        style: "",
      };
      let onClick = null;
      const config = error.config;
      const expireResult = isExpired();
      store.dispatch(setIsLoading(false));
      if (!!getItem(StorageKey.IS_CONTINUOUS_LOADING)) {
        setItem(StorageKey.IS_CONTINUOUS_LOADING, "");
      }
      switch (errorStatus) {
        case ApiStatusCodes.NOT_FOUND:
          if (!!isDeleted) {
            if (errorType === ApiErrorType?.GROUP_NOT_FOUND) {
              onClick = () => {
                window.location.href = "/";
              };
            }
            if (
              errorType === ApiErrorType?.INSPECTION_FORM_NOT_FOUND &&
              !!groupId
            ) {
              onClick = () => {
                window.location.href = `/groups/${groupId}/inspection-form`;
              };
            }
            if (errorType === ApiErrorType?.MACHINE_NOT_FOUND && !!groupId) {
              onClick = () => {
                window.location.href = `/groups/${groupId}/machines`;
              };
            }
          } else {
            if (errorType === ApiErrorType.USER_NOT_FOUND) {
              window.location.href = "/error-account";
              return Promise.reject(error)
            } else {
              if (window.location.pathname !== "/") {
                onClick = () => {
                  window.location.href = "/";
                };
              }
            }
          }
          break;
        case ApiStatusCodes.FORBIDDEN:
          if (window.location.pathname !== "/") {
            onClick = () => {
              window.location.href = "/";
            };
          }
          break;
        case ApiStatusCodes.UNAUTHORIZED:
          btn = {
            title: t("signOut"),
            style: "bg-red",
          };
          onClick = () => {
            logout();
          };
          if (error?.response.data.error.message === "jwt expired") {
            // logout when token expire AND no action in 60mins
            if (expireResult) {
              logout();
              return;
            }
            const newToken = await refreshToken();
            if (newToken) {
              // updated lastUpdatedAt in localstorage
              setTimer();
              config.headers["X-Access-Token"] = `Bearer ${newToken}`;
              resetIsContinuousLoading();
              return api(config);
            }
            store.dispatch(setIsLoading(false));
            break;
          }
          break;
        case ApiStatusCodes.ERR_NETWORK:
          title = t("errNetwork.title") 
          content = t("errNetwork.content");
          break;
        default:
          break;
      }
      const notShownPopup = !!getItem(StorageKey.NOT_SHOWN_POPUP);
      if (
        notShownPopup &&
        ![ApiStatusCodes.UNAUTHORIZED, ApiStatusCodes.ERR_NETWORK].includes(
          errorStatus
        )
      ) {
        title = "";
      }
      switch (errorType) {
        case ApiErrorType.USER_NOT_FOUND:
          if (errorStatus === ApiStatusCodes.UNAUTHORIZED) {
            logout();
            return;
          } else {
            store.dispatch(
              setError({
                title,
                content,
                btn,
                onClick,
              })
            );
          }
          break;
        case ApiErrorType.GROUP_NOT_FOUND:
          if (!isDeleted) {
            window.location.href = "/404";
          } else {
            store.dispatch(
              setError({
                title,
                content,
                btn,
                onClick,
              })
            );
          }
          break;
        case ApiErrorType.MACHINE_NOT_FOUND:
          if (!isDeleted) {
            window.location.href = "/404";
          } else {
            store.dispatch(
              setError({
                title,
                content,
                btn,
                onClick,
              })
            );
          }
          break;
        case ApiErrorType.INSPECTION_FORM_NOT_FOUND:
          if (!isDeleted) {
            window.location.href = "/404";
          } else {
            store.dispatch(
              setError({
                title,
                content,
                btn,
                onClick,
              })
            );
          }
          break;
        case ApiErrorType.MACHINE_REPORT_NOT_FOUND:
          if (!isDeleted) {
            window.location.href = "/404";
          } else {
            store.dispatch(
              setError({
                title,
                content,
                btn,
                onClick,
              })
            );
          }
          break;
        case ApiErrorType.INSPECTION_FORM_Template_NOT_FOUND:
          if (!isDeleted) {
            window.location.href = "/404";
          } else {
            store.dispatch(
              setError({
                title,
                content,
                btn,
                onClick,
              })
            );
          }
          break;
        default:
          if (!expireResult) {
            store.dispatch(
              setError({
                title,
                content,
                btn,
                onClick,
              })
            );
          }
          break;
      }
      return Promise.reject(error);
    };

    const reqInterceptors = api.interceptors.request.use(
      reqInterceptor,
      reqErrInterceptor
    );

    const resInterceptors = api.interceptors.response.use(
      resInterceptor,
      resErrInterceptor
    );

    return () => {
      api.interceptors.request.eject(reqInterceptors);
      api.interceptors.response.eject(resInterceptors);
    };
  }, []);

  return null;
};
