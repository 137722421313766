import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GroupsOutlined } from "@mui/icons-material";
import { useStoreDispatch } from "@app/hooks";
import { setGroups, RootState } from "@app/store";
import { GroupsRequestDef } from "@app/types";
import { apiGroups } from "@app/api";
import { isMobile } from "@app/helpers";
import { CURRENT_PAGE, LIMIT_PAGE, TOTAL_PAGE } from "@app/constants";
import {
  DataPagination,
  DataTable,
  PageTitle,
  DataUsageDialog,
} from "@app/components/atoms";
import { Helmet } from "@app/components/atoms";
import { useTranslation } from "react-i18next";
import { StorageKey } from "@app/constants";
import { getItem } from "@app/helpers";
import { useSelector } from "react-redux";

const columns = [
  {
    key: "groupName",
    title: "tableGroup.groupName",
    dataIndex: "groupName",
    align: "left",
    sorted: true,
    style: {
      minWidth: isMobile() ? 213 : 420,
    },
    render: (value: any, _record: any) => (
      <Link className="txt-link txt-pre-wrap" to={`/groups/${_record.groupId}`}>
        {value}
      </Link>
    ),
    className: "txt-pre-wrap",
  },
  {
    key: "machineCount",
    title: "tableGroup.machineCount",
    dataIndex: "machineCount",
    align: "right",
    sorted: true,
    whiteSpace: "nowrap",
    style: {
      minWidth: 112,
    },
    render: (value: any, record: any) => (
      <Link className={`txt-link`} to={`/groups/${record.groupId}`}>
        {value}
      </Link>
    ),
  },
  {
    key: "reportCount",
    title: "tableGroup.reportCount",
    dataIndex: "reportCount",
    align: "right",
    sorted: true,
    whiteSpace: "nowrap",
    style: {
      minWidth: 100,
    },
  },
  {
    key: "inspectionFormCount",
    title: "tableGroup.inspectionFormCount",
    dataIndex: "inspectionFormCount",
    align: "right",
    sorted: true,
    whiteSpace: "nowrap",
    render: (value: any, record: any) => (
      <>
        {record.permissions.allowCreateEditDeleteInspectionForm ? (
          <Link
            className={`txt-link`}
            to={`/groups/${record.groupId}/inspection-form`}
          >
            {value}
          </Link>
        ) : (
          value
        )}
      </>
    ),
    style: {
      minWidth: 172,
    },
  },
  {
    key: "memberCount",
    title: "tableGroup.memberCount",
    dataIndex: "memberCount",
    align: "right",
    sorted: true,
    whiteSpace: "nowrap",
    style: {
      minWidth: 111,
    },
  },
  {
    key: "companyName",
    title: "tableGroup.companyName",
    dataIndex: "companyName",
    align: "left",
    style: {
      minWidth: isMobile() ? 209 : 250,
    },
    className: "txt-pre-wrap",
  },
  {
    key: "location",
    title: "tableGroup.location",
    dataIndex: "location",
    align: "left",
    style: {
      minWidth: isMobile() ? 242 : 250,
    },
    className: "txt-pre-wrap",
  },
];

type groupType = {
  id: string;
  groupName: string;
  companyName: string;
  machineCount: number;
  reportCount: number;
  location: string;
  memberCount: number;
  inspectionFormCount: string;
};

export function GroupList() {
  const dispatch = useStoreDispatch();
  const [page, setPage] = useState(CURRENT_PAGE);
  const [rows, setRow] = useState<groupType[]>();
  const [field, setField] = useState<string>("");
  const [order, setOrder] = useState<string | undefined>();
  const { t } = useTranslation();
  const [pageInfo, setPageInfo] = useState({
    total: TOTAL_PAGE,
    page: CURRENT_PAGE,
    limit: LIMIT_PAGE,
  });

  const { suppressDataUsagePopup } = useSelector(
    (state: RootState) => state?.dataUsage
  );

  const isShownDataUsagePopup = getItem(StorageKey.IS_SHOWN_DATA_USAGE_POPUP);

  useEffect(() => {
    getGroups({
      limit: LIMIT_PAGE,
      page: page,
      ...(field && order ? { orderBys: [{ field, order }] } : {}),
    });
  }, [page, field, order]);

  const convertDataRow = (data: groupType[]) => {
    return data.map((row: any) => ({
      ...row,
      id: row?.groupId,
    }));
  };

  const getGroups = (payload: GroupsRequestDef) => {
    return apiGroups(payload).then((res) => {
      const { data, meta }: { data: groupType[]; meta: any } = res.data;
      const formatData = convertDataRow(data);
      setRow(formatData);
      setPageInfo(meta.pageInfo);
      dispatch(setGroups(data));
    });
  };

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleSort = (order: string | undefined, property: string) => {
    setField(property);
    setOrder(order);
  };

  return (
    <div className="group-list-page">
      <Helmet title={t("metaInfo.groupList")} />
      <div className="container">
        <div className="row">
          <PageTitle title="group" className="mb-25 sp-p24">
            <GroupsOutlined />
          </PageTitle>
          <DataTable
            columns={columns}
            dataSource={rows}
            onRequestSort={handleSort}
            textEmptyData="noGroup"
          />
          <DataPagination
            isHidden={!rows?.length}
            currentPage={pageInfo.page}
            limitPage={pageInfo.limit}
            totalPage={pageInfo.total}
            handleChangePage={handleChangePage}
            className="mt-25 sp-p24"
          />
        </div>
      </div>
      {isShownDataUsagePopup && !suppressDataUsagePopup && <DataUsageDialog />}
    </div>
  );
}
