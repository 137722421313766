import { createSlice } from "@reduxjs/toolkit";

interface GroupDetailProps {
  isRequestedGroupDetail: boolean;
}

const initialState = {
  isRequestedGroupDetail: false,
} as GroupDetailProps;

export const groupDetailSlice = createSlice({
  name: "groupDetail",
  initialState,
  reducers: {
    setIsRequestedGroupDetail: (state, action) => {
      state.isRequestedGroupDetail = action.payload;
    },
  },
});

export const { setIsRequestedGroupDetail } = groupDetailSlice.actions;

export default groupDetailSlice.reducer;
