export function IconSort() {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00012 4.6225L11.3776 7L12.4351 5.9425L9.00012 2.5L5.55762 5.9425L6.62262 7L9.00012 4.6225ZM9.00012 13.8775L6.62262 11.5L5.56512 12.5575L9.00012 16L12.4426 12.5575L11.3776 11.5L9.00012 13.8775Z"
        fill="#ADAAAF"
      />
    </svg>
  );
}
