import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  typography: {
    fontFamily: "Noto Sans",
    fontWeightMedium: 400,
    allVariants: {
      color: "#1b232a"
    }
  },
});
