import { AxiosResponse } from "axios";
import { api, ENDPOINTS } from "@app/api";
import {
  GroupDetailInspectionFormsRequestDef,
  GroupMachinesRequestDef,
  GroupsRequestDef,
} from "@app/types";

export const apiGroups = (params: GroupsRequestDef): Promise<AxiosResponse> => {
  return api.get(ENDPOINTS.groups, { params });
};

export const apiGroupDetail = (id: string): Promise<AxiosResponse> => {
  return api.get(ENDPOINTS.groupDetail(id));
};

export const apiGroupDetailCondition = (id: string): Promise<AxiosResponse> => {
  return api.get(ENDPOINTS.groupDetailCondition(id));
};

export const apiGroupDetailInspectionForms = (
  id: string,
  params: GroupDetailInspectionFormsRequestDef
): Promise<AxiosResponse> => {
  return api.get(ENDPOINTS.groupDetailInspectionForms(id), { params });
};

export const apiGroupDetailInspectionFormDelete = (
  groupId: string,
  machineId: string,
  customInspectionFormId: string,
): Promise<AxiosResponse> => {
  return api.delete(ENDPOINTS.groupDetailInspectionDeleteOrUpdate(groupId, machineId, customInspectionFormId));
};

export const apiGroupDetailInspectionFormUpdate = (
  groupId: string,
  machineId: string,
  customInspectionFormId: string,
  params: any
): Promise<AxiosResponse> => {
  return api.put(ENDPOINTS.groupDetailInspectionDeleteOrUpdate(groupId, machineId, customInspectionFormId), params);
};

export const apiGroupDetailInspectionFormStatus = (
  id: string
): Promise<AxiosResponse> => {
  return api.get(ENDPOINTS.groupDetailInspectionFormStatus(id));
};

export const apiGroupMachinesDetail = (
  id: string,
  params: GroupMachinesRequestDef
): Promise<AxiosResponse> => {
  return api.get(ENDPOINTS.groupMachinesDetail(id), { params });
};
