export function IconInvalidUrl() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 86 86"
      fill="none"
    >
      <g opacity="0.24">
        <path
          d="M81.0013 75.9488L10.0513 4.99878L4.99878 10.0513L13.2763 18.3646V65.5571C13.2763 69.4988 16.5013 72.7238 20.4429 72.7238H67.6354L75.9129 81.0013L81.0013 75.9488ZM20.4429 65.5571V25.5313L44.9529 50.0413L41.9429 53.8038L34.7763 44.0571L24.0263 58.3904H53.3021L60.4688 65.5571H20.4429ZM30.5838 15.3904L23.4171 8.22378H70.6096C74.5513 8.22378 77.7763 11.4488 77.7763 15.3904V62.5829L70.6096 55.4163V15.3904H30.5838Z"
          fill="#47464F"
        />
      </g>
    </svg>
  );
}
