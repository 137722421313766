export function IconSelected({ className = "" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_20079_4044)">
        <rect y="0.5" width="40" height="40" rx="20" fill="#DAFBE1" />
        <rect
          y="0.5"
          width="40"
          height="40"
          rx="20"
          fill="white"
          fillOpacity="0.5"
        />
        <path
          d="M16.7951 24.3749L12.6251 20.2049L11.2051 21.6149L16.7951 27.2049L28.7951 15.2049L27.3851 13.7949L16.7951 24.3749Z"
          fill="#1A7F37"
        />
      </g>
      <defs>
        <clipPath id="clip0_20079_4044">
          <rect y="0.5" width="40" height="40" rx="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
