import { createBrowserRouter } from "react-router-dom";
import App from "@app/App";
import { msalInstance } from "@app/helpers";
import FeaturesRoutes from "@app/pages/Features/Features.routes";
import { ErrorsRoutes } from "@app/pages/Errors";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App instance={msalInstance} />,
    children: [...FeaturesRoutes, ...ErrorsRoutes],
  },
]);

export default router;
