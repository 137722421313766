export function IconVector({ className = "" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="52"
      viewBox="0 0 60 52"
      fill="none"
      className={className}
    >
      <path
        d="M0.666656 51.3327H59.3333L30 0.666016L0.666656 51.3327ZM32.6667 43.3327H27.3333V37.9993H32.6667V43.3327ZM32.6667 32.666H27.3333V21.9993H32.6667V32.666Z"
        fill="#DA093B"
      />
    </svg>
  );
}
