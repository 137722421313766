import { RouteObject } from "react-router-dom";
import { ErrorAccount } from "@app/pages/Errors";

export const ErrorsRoutes: RouteObject[] = [
  {
    path: "/",
    children: [
      {
        path: "/error-account",
        element: <ErrorAccount />,
      },
    ],
  },
];
