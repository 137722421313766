import { createSlice } from "@reduxjs/toolkit";

interface DataUsageProps {
  suppressDataUsagePopup: boolean;
}

const initialState = {
  suppressDataUsagePopup: true,
} as DataUsageProps;

export const dataUsageSlice = createSlice({
  name: "dataUsage",
  initialState,
  reducers: {
    setSuppressDataUsagePopup: (state, action) => {
      state.suppressDataUsagePopup = action.payload;
    },
  },
});

export const { setSuppressDataUsagePopup } = dataUsageSlice.actions;

export default dataUsageSlice.reducer;
