import { IconLoading } from "@app/components/icons";
import { useEffect } from "react";

export function Loading() {
  useEffect(() => {
    const dialog = document.querySelector(".MuiDialog-root");
    if (!!dialog) {
      return;
    }
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div className="loading-data">
      <IconLoading />
    </div>
  );
}
