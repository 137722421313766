import {
  apiGroupMachinesDetail,
  getCustomInspectionFormTemplates,
  getInspectionFormTemplates,
  getInspectionFormTemplatesDetail,
} from "@app/api";
import { Button, DataDialog, DataTooltip } from "@app/components/atoms";
import { SelectMachineEnum } from "@app/constants";
import { isMobile } from "@app/helpers";
import { useStoreDispatch } from "@app/hooks";
import { setInspectionsTemplate, setMachineId } from "@app/store";
import {
  CreateInspectionFormProps,
  TemplateList,
  VehicleNumber,
} from "@app/types";
import {
  Box,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export function CreateInspectionForm({
  open,
  setOpen,
  clickSubmit,
}: CreateInspectionFormProps) {
  const dispatch = useStoreDispatch();
  const { t } = useTranslation();
  const [dbMachineList, setDbMachineList] = useState<VehicleNumber[]>();
  const [dbTemplateList, setDbTemplateList] = useState<TemplateList>();
  const { id } = useParams();
  const {
    register,
    setValue,
    reset,
    handleSubmit,
    watch,
    trigger,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      vehicleNumber: {
        machineId: "",
        machineName: "",
      },
      selectedTemplateId: "",
    },
  });
  const vehicleNumber = watch("vehicleNumber");
  const selectedTemplateId = watch("selectedTemplateId");

  const resetDb = () => {
    setDbMachineList(undefined);
    setDbTemplateList(undefined);
    reset();
  };

  const handleChangeSelectedTemplateId = (event: SelectChangeEvent) => {
    setValue("selectedTemplateId", event.target.value);
  };

  const onSubmit = (data: any) => {
    let selectedTemplate: any = {};
    Object.keys(dbTemplateList as any).forEach((key: any) => {
      (dbTemplateList as any)[key].forEach((item: any) => {
        if (
          item?.inspectionFormTemplateId === selectedTemplateId ||
          item?.customInspectionFormId === selectedTemplateId
        ) {
          selectedTemplate = item;
        }
      });
    });
    if (!!id && !!selectedTemplate?.inspectionFormTemplateId) {
      getInspectionFormTemplatesDetail(
        id,
        data?.vehicleNumber?.machineId,
        selectedTemplate?.inspectionFormTemplateId
      ).then((db) => {
        handleAfterSubmit(data, db);
      });
    } else {
      !!id &&
        getCustomInspectionFormTemplates(
          id,
          data?.vehicleNumber?.machineId,
          selectedTemplate?.customInspectionFormId
        ).then((db) => {
          handleAfterSubmit(data, db);
        });
    }
  };

  const handleAfterSubmit = (data: any, db: any) => {
    clickSubmit();
    dispatch(setInspectionsTemplate(db));
    dispatch(setMachineId(data?.vehicleNumber?.machineId));
    resetDb();
  };

  useEffect(() => {
    if (!!id && !!open) {
      apiGroupMachinesDetail(id, {
        select: [SelectMachineEnum.MACHINE_NAME],
      })
        .then((db) => {
          setDbMachineList(db?.data?.data as VehicleNumber[]);
        })
        .catch(() => setOpen(false));
    }
  }, [id, open]);

  useEffect(() => {
    if (!!vehicleNumber.machineId && id) {
      setValue("selectedTemplateId", "");
      setDbTemplateList(undefined);
      getInspectionFormTemplates(id, vehicleNumber.machineId).then((db) => {
        setDbTemplateList(db);
      });
    }
  }, [vehicleNumber.machineId, id]);

  return (
    <>
      <DataDialog title={t("titleCreateCard")} open={open} setOpen={setOpen}>
        <Box
          component="form"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormControl
            fullWidth
            className="mt-10 select-field"
            error={!!errors.vehicleNumber?.type}
          >
            <InputLabel
              id={`${
                !!errors.vehicleNumber?.type
                  ? "demo-simple-select-error-label"
                  : "demo-simple-select-label"
              }`}
              htmlFor="grouped-select"
            >
              {t("customerVehicleNumber")}*
            </InputLabel>
            <Select
              labelId={`${
                !!errors.vehicleNumber?.type
                  ? "demo-simple-select-error-label"
                  : "demo-simple-select-label"
              }`}
              id={`${
                !!errors.vehicleNumber?.type
                  ? "demo-simple-select-error"
                  : "demo-simple-select"
              }`}
              defaultValue=""
              label={`${t("customerVehicleNumber")}*`}
              {...register("vehicleNumber", {
                validate: {
                  required: (value) => !!value?.machineId,
                },
              })}
              error={!!errors.vehicleNumber?.type}
              onClose={() => trigger("vehicleNumber")}
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: 216,
                    width: isMobile() ? window.innerWidth - 80 : 512,
                  },
                },
              }}
            >
              {!!dbMachineList &&
                dbMachineList?.map((option: any) => (
                  <MenuItem
                    key={option.machineId}
                    value={option}
                    sx={{ color: "#1b232a", padding: "8px 12px" }}
                  >
                    <DataTooltip isTextPre width="100%">
                      {option.machineName}
                    </DataTooltip>
                  </MenuItem>
                ))}
            </Select>
            {errors?.vehicleNumber?.type ? (
              <span className="txt-red fs-12 mt-5 ml-16">
                {errors.vehicleNumber?.type === "required" &&
                  t("messages.dropdownRequire")}
              </span>
            ) : (
              <span className="fs-12 mt-5 ml-16 txt-gray-1">
                &#42;{t("required")}
              </span>
            )}
          </FormControl>
          <FormControl
            fullWidth
            className="mt-24 select-field"
            error={!!errors.selectedTemplateId?.type}
          >
            <InputLabel
              id={`${
                !!errors.selectedTemplateId?.type
                  ? "demo-simple-select-error-label"
                  : "demo-simple-select-label"
              }`}
              htmlFor="grouped-select"
            >
              {t("template")}*
            </InputLabel>
            <Select
              {...register("selectedTemplateId", {
                validate: {
                  required: (value) => !!value,
                },
              })}
              onChange={handleChangeSelectedTemplateId}
              value={selectedTemplateId}
              defaultValue=""
              id={`${
                !!errors.selectedTemplateId?.type
                  ? "demo-simple-select-error"
                  : "grouped-select"
              }`}
              label={`${t("template")}*`}
              disabled={!vehicleNumber.machineId}
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: 216,
                    width: isMobile() ? window.innerWidth - 80 : 512,
                  },
                },
              }}
              error={!!errors.selectedTemplateId?.type}
              onClose={() => trigger("selectedTemplateId")}
            >
              {!!dbTemplateList?.inspectionFormTemplates.length && (
                <ListSubheader>{t("inspectionFormTemplates")}</ListSubheader>
              )}
              {!!dbTemplateList &&
                dbTemplateList?.inspectionFormTemplates?.map((option: any) => [
                  <MenuItem
                    key={option?.inspectionFormTemplateId}
                    value={option?.inspectionFormTemplateId}
                    sx={{ color: "#1b232a", padding: "8px 12px" }}
                  >
                    <DataTooltip isTextPre width="100%">
                      {option.inspectionFormTemplateName}
                    </DataTooltip>
                  </MenuItem>,
                ])}
              {!!dbTemplateList?.customInspectionForms.length && (
                <ListSubheader>{t("customInspectionForms")}</ListSubheader>
              )}
              {!!dbTemplateList &&
                dbTemplateList?.customInspectionForms?.map((option: any) => [
                  <MenuItem
                    key={option?.customInspectionFormId}
                    value={option?.customInspectionFormId}
                  >
                    <DataTooltip isTextPre width="100%">
                      {option.name}
                    </DataTooltip>
                  </MenuItem>,
                ])}
            </Select>
            {errors?.selectedTemplateId?.type ? (
              <span className="txt-red fs-12 mt-5 ml-16">
                {errors.selectedTemplateId?.type === "required" &&
                  t("messages.dropdownRequire")}
              </span>
            ) : (
              <span className="fs-12 ml-16 mt-5 txt-gray-1">
                &#42;{t("required")}
              </span>
            )}
          </FormControl>
          <div className="flex align-items-center justify-content-end mt-24">
            <Button
              className="btn-no-border"
              onClick={() => {
                resetDb();
                setOpen(false);
              }}
            >
              {t("cancel")}
            </Button>
            <Button
              className="btn-primary ml-16"
              disabled={!selectedTemplateId}
              type="submit"
            >
              {t("create")}
            </Button>
          </div>
        </Box>
      </DataDialog>
    </>
  );
}
