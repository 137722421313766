import { AxiosResponse } from "axios";
import { api, ENDPOINTS } from "@app/api";
import {
  MachinesRequestDef,
  Machine,
  machineSummaryRequestDef,
} from "@app/types";

export const apiMachineInfo = (
  groupId: string,
  machineId: string
): Promise<AxiosResponse> => {
  return api.get(ENDPOINTS.machineInfo(groupId, machineId));
};

export const apiMachineReports = (
  groupId: string,
  machineId: string,
  params?: MachinesRequestDef
): Promise<AxiosResponse> => {
  return api.get(ENDPOINTS.machineReport(groupId, machineId), {
    params,
  });
};

export const apiGetMachineReportDetail = (
  groupId: string,
  machineId: string,
  machineReportId: string
): Promise<AxiosResponse> => {
  return api.get(
    ENDPOINTS.machineReportDetail(groupId, machineId, machineReportId)
  );
};

export const apiMachineReportInfo = (
  groupId: string,
  machineId: string,
  startDate: string,
  utc?: string
): Promise<AxiosResponse> => {
  return api.get(ENDPOINTS.machineReportInfo(groupId, machineId), {
    params: { startDate, utc },
  });
};

export const apiMachineSummary = (
  groupId: string,
  machineId: string,
  params: machineSummaryRequestDef
): Promise<AxiosResponse> => {
  return api.get(ENDPOINTS.machineSummary(groupId, machineId), { params });
};

export const getMachineSummary = (
  groupId: string,
  machineId: string,
  params: machineSummaryRequestDef
) => {
  return apiMachineSummary(groupId, machineId, params).then((db) => {
    const { data, meta }: { data: any; meta: any } = db.data;
    return { data, meta };
  });
};

export const getMachineReportInfo = (
  groupId: string,
  machineId: string,
  startDate: string,
  utc?: string
) => {
  return apiMachineReportInfo(groupId, machineId, startDate, utc).then((db) => {
    const { data } = db.data;
    return data;
  });
};

export const getMachineReportDetail = (
  groupId: string,
  machineId: string,
  machineReportId: string
) => {
  return apiGetMachineReportDetail(groupId, machineId, machineReportId).then(
    (db) => {
      const { data } = db.data;
      return data;
    }
  );
};

export const getMachineInfo = (groupId: string, machineId: string) => {
  return apiMachineInfo(groupId, machineId).then((db) => {
    const { data } = db.data;
    return data;
  });
};

export const getMachineReports = (
  groupId: string,
  machineId: string,
  params?: MachinesRequestDef
) => {
  return apiMachineReports(groupId, machineId, params).then((db) => {
    const { data, meta }: { data: Machine[]; meta: any } = db.data;
    return { data, meta };
  });
};

export const apiGetMachineReportResponses = (
  groupId: string,
  machineId: string,
  machineReportId: string
): Promise<AxiosResponse> => {
  return api.get(
    ENDPOINTS.machineReportResponses(groupId, machineId, machineReportId)
  );
};

export const apiPostMachineReportResponses = (
  groupId: string,
  machineId: string,
  machineReportId: string,
  param: any
): Promise<AxiosResponse> => {
  return api.post(
    ENDPOINTS.machineReportResponses(groupId, machineId, machineReportId),
    param
  );
};

export const apiMachineReportStatus = (
  groupId: string,
  machineId: string,
  machineReportId: string,
  param: any
): Promise<AxiosResponse> => {
  return api.put(
    ENDPOINTS.machineReportStatus(groupId, machineId, machineReportId),
    param
  );
};

export const apiMachineReportActionChoices = (): Promise<AxiosResponse> => {
  return api.get(ENDPOINTS.machineReportActionChoices());
};

export const updateMachineReportStatus = (
  groupId: string,
  machineId: string,
  machineReportId: string,
  isRead: boolean
) => {
  return apiMachineReportStatus(groupId, machineId, machineReportId, {
    isRead,
  });
};

export const apiGetMachineOperationReport = (
  groupId: string,
  machineId: string,
  machineReportId: string
): Promise<AxiosResponse> => {
  return api.get(
    ENDPOINTS.machineOperationReport(groupId, machineId, machineReportId)
  );
};

export const apiGetMaintenanceReport = (
  groupId: string,
  machineId: string,
  machineReportId: string
): Promise<AxiosResponse> => {
  return api.get(
    ENDPOINTS.maintenanceReport(groupId, machineId, machineReportId)
  );
};
