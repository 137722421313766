import { Helmet, NavigationItem, PageTitle } from "@app/components/atoms";
import { DescriptionOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import licenseData from "@app/pages/Features/License/license-data.json";

const licenseHeaderTitles = [
  {
    title: "name",
    width: 256,
  },
  {
    title: "type",
    width: 107,
  },
  {
    title: "link",
    width: 446,
  },
  {
    title: "version",
    width: 124,
  },
  {
    title: "author",
    width: 464,
  },
];

export function License() {
  const { t } = useTranslation();

  return (
    <div className="group-list-page license">
      <Helmet title={t("metaInfo.license")} />
      <div className="container">
        <div className="row">
          <div className="sp-p24">
            <NavigationItem title={t("license.backToHome")} />
            <PageTitle title="license.title" className="mt-24 mb-25">
              <DescriptionOutlined />
            </PageTitle>
          </div>
          <div className="table-wrapper">
            <table>
              <thead>
                <tr>
                  {licenseHeaderTitles.map((key, index) => (
                    <th key={index} style={{ minWidth: key?.width }}>
                      {t(`license.${key?.title}`)}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {licenseData.map(
                  (
                    data: {
                      name: string;
                      licenseType: string;
                      link: string;
                      installedVersion: string;
                      author: string;
                    },
                    index
                  ) => (
                    <tr key={index}>
                      <td>{data.name}</td>
                      <td>{data.licenseType}</td>
                      <td>{data.link}</td>
                      <td>{data.installedVersion}</td>
                      <td>{data.author}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
