import { postUploadUrls, putUploadStore } from "@app/api";
import { StorageKey, TYPE_ALLOW } from "@app/constants";
import { delay, removeItem, setItem } from "@app/helpers";

/**
 * Function to download a file.
 *
 * Adds `a` html element with link to file,
 * and triggers a click to download the file,
 * and finally removes the element from the DOM
 * @param url Path to the file
 * @param filename Name of the file
 */
export const download = async (url: string, filename: string) => {
  const link = document.createElement("a");
  link.download = filename;
  link.href = url;
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();

  // Chrome requires the timeout
  await delay(100);
  document.body.removeChild(link);
};

/**
 * Function to initiate download of multiple files
 * @param files Array of path to files
 */
export const downloadMultipleFiles = (files: string[]) => {
  files.forEach((file, index) => {
    (async () => {
      const filename = file.substring(file.lastIndexOf("/") + 1);
      await delay(index * 1000);
      download(file, filename);
    })();
  });
};

/**
 * Convert blob file to csv file, and initiates a download of the file
 * @param blobData Data from api
 * @param fileName Name of file
 */
export const processBlobToCSV = (blobData: string, fileName: string) => {
  const contentType = "text/csv";
  const blob = new Blob([blobData], { type: contentType });
  const url = window.URL.createObjectURL(blob);
  const filename = `${fileName}.csv`;
  download(url, filename);
};

/**
 * Return Bytes format Name
 * Ex: 0 Bytes, 10KB, 1MB
 * @param {*} bytes
 * @param {*} decimals
 */
export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

/**
 * Check is wrong file type
 * @param files: [file, file] or file
 * @param typeAllow
 * @returns
 */
export const isWrongFileType = (files: File[] | File, typeAllow?: string[]) => {
  const getFiles = files instanceof Array ? files : [files];
  const typeAllows: string[] = typeAllow || TYPE_ALLOW;
  // Handle for windows not show file type CSV
  const handleFileType = (fileName: string, fileType: string): string => {
    let modify = fileType;
    if (!fileType) {
      const getType = fileName.toLowerCase().split(".").pop();
      switch (getType) {
        case "csv":
          modify = "text/csv";
          break;
        case "heic":
          modify = "image/heic";
          break;
        default:
          break;
      }
    }
    return modify;
  };
  // Not allow file .jfif
  const isFileJFIF = (fileName: string) => {
    const getType = fileName.split(".").pop();
    return getType === "jfif";
  };
  return (
    !!getFiles.length &&
    getFiles.some(
      (file) =>
        isFileJFIF(file.name) ||
        !typeAllows.includes(handleFileType(file.name, file.type))
    )
  );
};

/**
 * Check file name is long
 * @param files
 * @param maxLength
 * @returns
 */
export const isLongFileName = (files: File[] | File, maxLength: number) => {
  const getFiles = files instanceof Array ? files : [files];
  return Array.from(getFiles).some(
    (file) => file.name.split(".")[0].length > maxLength
  );
};

/**
 * Convert file to Base64
 * @param file
 * @returns
 */
export const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

/**
 * Convert multiple file to Base64
 * @param files: [File, File]
 * @returns
 */
export const multipleToBase64 = async (files: File[]) => {
  const promises = files.map(async (item: File) => {
    const url = await toBase64(item);
    (item as any).url = url;
    return item;
  });
  return Promise.all(promises);
};

/**
 * Detect is file name have XSS
 * Ex: pic"<img src =q onerror=prompt('XSS')>asd.jpg
 * @param {*} files
 */
export const isFileNameHasXss = (name: string) => {
  return /(<img).+(>)/g.test(name);
};

export const handleUploadStore = async (typePutFile: string, file: any) => {
  try {
    const hasThumbnail = file.type?.includes("video");
    setItem(StorageKey.NOT_SHOWN_POPUP, "true");
    const postUploadUrlsRes = await postUploadUrls({
      type: typePutFile,
      fileName: file?.name,
    });
    const { thumbnailSasUrl, sasUrl, filePath } = postUploadUrlsRes;
    setItem(StorageKey.MEDIA, {
      baseUrl: sasUrl,
      contentType: file.type,
    });
    await putUploadStore(file);
    if (hasThumbnail) {
      setItem(StorageKey.MEDIA, {
        baseUrl: thumbnailSasUrl,
        contentType: file.thumbnail.type,
      });
      await putUploadStore(file.thumbnail);
    }
    removeItem(StorageKey.MEDIA);
    const formatFile = Object.defineProperty(file, "filePath", {
      value: filePath,
      configurable: true,
    });
    return formatFile;
  } catch (error) {
    removeItem(StorageKey.MEDIA);
    return null;
  } finally {
    removeItem(StorageKey.NOT_SHOWN_POPUP);
  }
};
