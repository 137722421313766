import { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  styled,
} from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export function DataDialog({
  title,
  open,
  setOpen,
  children,
  className,
  subTitle,
  isIcon,
  action,
  dialogActions,
}: any) {
  useEffect(() => {
    if (globalThis?.document) {
      const body = globalThis.document.body;
      const observer = new MutationObserver(() => {
        body.style.touchAction = body.style.overflow === "hidden" ? "none" : "";
      });
      observer.observe(body, {
        attributes: true,
        attributeFilter: ["style"],
      });
    }
  }, []);

  return (
    <BootstrapDialog
      aria-labelledby="customized-dialog-title"
      open={open}
      className={className}
    >
      <div
        className={`${
          !!action ? "flex align-items-center justify-content-between" : ""
        } p-24`}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, color: (theme) => theme.palette.common.black }}
          id="customized-dialog-title"
        >
          <div className="flex align-items-center txt-pre-wrap word-break">
            {isIcon && (
              <ArrowBackIos
                className="icon-back pointer"
                onClick={() => setOpen(false)}
              />
            )}
            {title}
          </div>
          {!!subTitle && subTitle}
        </DialogTitle>
        {action}
      </div>
      <DialogContent dividers>{children}</DialogContent>
      {dialogActions && (
        <DialogActions sx={{ padding: "0 24px 24px !important" }}>
          {dialogActions}
        </DialogActions>
      )}
    </BootstrapDialog>
  );
}
