import qrIconSrc from "@app/components/icons/IconGoogleQR/Google.jpg";

export function IconGoogleQR() {
  return (
    <img
      style={{ width: "100px", height: "100px" }}
      alt="google qr icon"
      src={qrIconSrc}
      width="100%"
      height="100%"
    />
  );
}
