export function IconLogoMobile() {
  return (
    <svg
      width="96"
      height="35"
      viewBox="0 0 96 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M73.4844 9.97285C73.4844 8.21322 73.9643 6.84017 74.9241 5.85371C75.8972 4.86725 77.2369 4.37402 78.9432 4.37402C80.6629 4.37402 81.9893 4.85392 82.9224 5.81372C83.8555 6.77352 84.3221 8.13324 84.3221 9.89287C84.3221 11.6658 83.8422 13.0455 82.8824 14.032C81.9359 15.0185 80.5962 15.5117 78.8633 15.5117C77.1303 15.5117 75.7972 15.0318 74.8641 14.072C73.9443 13.1122 73.4844 11.7458 73.4844 9.97285ZM77.1036 9.93286C77.1036 10.786 77.2503 11.4459 77.5435 11.9124C77.8368 12.379 78.29 12.6123 78.9032 12.6123C79.5298 12.6123 79.983 12.379 80.263 11.9124C80.5562 11.4459 80.7029 10.786 80.7029 9.93286C80.7029 9.06637 80.5562 8.40651 80.263 7.95327C79.983 7.4867 79.5298 7.25342 78.9032 7.25342C78.29 7.25342 77.8368 7.4867 77.5435 7.95327C77.2503 8.40651 77.1036 9.06637 77.1036 9.93286Z"
        fill="#140A9A"
      />
      <path
        d="M85.8164 4.59923H89.3157V5.79898C89.8222 5.1991 90.3355 4.79919 90.8554 4.59923C91.3752 4.38594 91.9085 4.2793 92.455 4.2793C93.5748 4.2793 94.4346 4.60589 95.0345 5.25909C95.6344 5.91229 95.9343 6.83876 95.9343 8.03851V15.097H92.335V8.69837C92.335 8.19181 92.2351 7.82522 92.0351 7.5986C91.8485 7.35865 91.5552 7.23868 91.1553 7.23868C90.5821 7.23868 90.1355 7.43197 89.8156 7.81856C89.549 8.0985 89.4157 8.47842 89.4157 8.95832V15.097H85.8164V4.59923Z"
        fill="#140A9A"
      />
      <path
        d="M59.9448 11.1774V7.41819H58.2651V4.59878H59.9448V1.83936H63.544V4.59878H65.8635V7.41819H63.544V11.1374C63.544 11.9639 63.9373 12.3772 64.7238 12.3772C64.9104 12.3772 65.0904 12.3638 65.2637 12.3372C65.437 12.3105 65.6103 12.2772 65.7836 12.2372L66.2435 14.8566C65.8302 15.0033 65.4236 15.1099 65.0237 15.1766C64.6238 15.2565 64.1706 15.2965 63.664 15.2965C62.291 15.2965 61.3245 14.9633 60.7646 14.2968C60.2181 13.6302 59.9448 12.5904 59.9448 11.1774Z"
        fill="#140A9A"
      />
      <path
        d="M47.0303 12.1976C47.0303 11.3045 47.2636 10.6046 47.7301 10.0981C48.21 9.57819 48.8499 9.20493 49.6497 8.97831C50.4496 8.75169 51.3294 8.62505 52.2892 8.59839L53.6489 8.5584V8.19848C53.6489 7.67859 53.5422 7.30533 53.329 7.07871C53.1157 6.83876 52.7757 6.71879 52.3092 6.71879C51.8693 6.71879 51.5293 6.8121 51.2894 6.99873C51.0628 7.17202 50.9161 7.43197 50.8495 7.77856L47.6301 7.51862C47.9901 5.35907 49.5897 4.2793 52.4291 4.2793C54.0288 4.2793 55.2019 4.63922 55.9484 5.35907C56.6949 6.06559 57.0682 7.1387 57.0682 8.5784V15.097H53.7889V13.9773C53.3623 14.4305 52.889 14.7771 52.3692 15.017C51.8626 15.2437 51.2694 15.357 50.5895 15.357C49.5231 15.357 48.6633 15.077 48.0101 14.5172C47.3569 13.9573 47.0303 13.1841 47.0303 12.1976ZM50.3896 11.9577C50.3896 12.6242 50.7961 12.9575 51.6093 12.9575C52.2358 12.9575 52.7291 12.7842 53.089 12.4376C53.4623 12.091 53.6489 11.6311 53.6489 11.0579V10.578L52.4091 10.638C51.676 10.678 51.1561 10.8046 50.8495 11.0179C50.5429 11.2312 50.3896 11.5444 50.3896 11.9577Z"
        fill="#140A9A"
      />
      <path
        d="M39.3809 4.59891H42.8801V5.87864C43.6133 4.90551 44.5931 4.41895 45.8195 4.41895C46.0061 4.41895 46.1794 4.43228 46.3394 4.45894C46.5127 4.47227 46.6793 4.49226 46.8393 4.51892L46.4594 7.55829C46.0861 7.47831 45.7129 7.43831 45.3396 7.43831C44.6864 7.43831 44.1465 7.59161 43.72 7.89822C43.2267 8.25814 42.9801 8.81136 42.9801 9.55787V15.0967H39.3809V4.59891Z"
        fill="#140A9A"
      />
      <path
        d="M27.3994 10.0479C27.3994 8.2483 27.8726 6.8486 28.8191 5.84881C29.7789 4.84902 31.072 4.34912 32.6983 4.34912C34.3246 4.34912 35.571 4.82902 36.4375 5.78882C37.3173 6.73529 37.7572 8.03502 37.7572 9.688V10.7078H30.8387C30.9053 11.5076 31.0786 12.0875 31.3586 12.4474C31.6519 12.794 32.0918 12.9673 32.6783 12.9673C33.1849 12.9673 33.5648 12.874 33.8181 12.6874C34.0847 12.4874 34.2646 12.2075 34.358 11.8476H37.6773C37.464 13.034 36.9441 13.9404 36.1176 14.567C35.3044 15.1802 34.178 15.4868 32.7383 15.4868C31.0187 15.4868 29.6989 15.0136 28.7791 14.0671C27.8593 13.1206 27.3994 11.7809 27.3994 10.0479ZM34.4379 8.80819C34.3979 8.14166 34.2646 7.64843 34.038 7.3285C33.8114 6.99523 33.3715 6.8286 32.7183 6.8286C32.1184 6.8286 31.6785 6.98857 31.3986 7.3085C31.1186 7.62843 30.9387 8.12833 30.8587 8.80819H34.4379Z"
        fill="#140A9A"
      />
      <path
        d="M15.624 4.59923H19.0833V5.73899C19.8431 4.76586 20.8563 4.2793 22.1227 4.2793C23.4957 4.2793 24.5422 4.7592 25.262 5.719C25.9819 6.66546 26.3418 7.99185 26.3418 9.69816C26.3418 11.4711 25.9485 12.8708 25.162 13.8973C24.3755 14.9104 23.3024 15.417 21.9427 15.417C21.3961 15.417 20.8829 15.3103 20.403 15.097C19.9365 14.8837 19.5432 14.5638 19.2233 14.1372V18.9762H15.624V4.59923ZM22.7225 9.83813C22.7225 8.89166 22.5626 8.21181 22.2426 7.79856C21.9227 7.37198 21.5028 7.15869 20.9829 7.15869C20.3564 7.15869 19.9031 7.39198 19.6232 7.85855C19.3566 8.31179 19.2233 8.905 19.2233 9.63818V10.0181C19.2233 10.7913 19.3566 11.4045 19.6232 11.8577C19.8898 12.3109 20.3297 12.5376 20.9429 12.5376C21.5161 12.5376 21.956 12.3243 22.2626 11.8977C22.5692 11.4711 22.7225 10.7846 22.7225 9.83813Z"
        fill="#140A9A"
      />
      <path
        d="M0 8.03842C0 6.43876 0.279941 5.07904 0.839824 3.95928C1.41304 2.83951 2.2262 1.98636 3.27931 1.39981C4.34576 0.799938 5.61216 0.5 7.07852 0.5C9.26473 0.5 10.971 1.14653 12.1974 2.43959C13.4372 3.71933 14.0571 5.55228 14.0571 7.93844C14.0571 9.53811 13.7771 10.9045 13.2172 12.0376C12.6573 13.1574 11.8442 14.0172 10.7777 14.617C9.72463 15.2036 8.45823 15.4969 6.97854 15.4969C4.80566 15.4969 3.09935 14.8503 1.85961 13.5573C0.61987 12.2642 0 10.4246 0 8.03842ZM3.95917 7.99843C3.95917 9.35815 4.21245 10.4046 4.71901 11.1378C5.2389 11.8576 6.00541 12.2176 7.01853 12.2176C8.04498 12.2176 8.81149 11.8576 9.31805 11.1378C9.83794 10.4046 10.0979 9.35815 10.0979 7.99843C10.0979 6.65205 9.83794 5.61226 9.31805 4.87909C8.81149 4.14591 8.04498 3.77931 7.01853 3.77931C6.00541 3.77931 5.2389 4.14591 4.71901 4.87909C4.21245 5.61226 3.95917 6.65205 3.95917 7.99843Z"
        fill="#140A9A"
      />
      <path
        d="M68.2358 4.60919H71.8351V15.107H68.2358V4.60919ZM68.2358 0.75H71.8351V3.34946H68.2358V0.75Z"
        fill="#140A9A"
      />
      <path
        d="M51.102 30.2609V26.5017H49.4224V23.6823H51.102V20.9229H54.7013V23.6823H57.0208V26.5017H54.7013V30.2209C54.7013 31.0474 55.0945 31.4606 55.881 31.4606C56.0676 31.4606 56.2476 31.4473 56.4209 31.4207C56.5942 31.394 56.7675 31.3607 56.9408 31.3207L57.4007 33.9401C56.9874 34.0868 56.5809 34.1934 56.1809 34.2601C55.781 34.34 55.3278 34.38 54.8212 34.38C53.4482 34.38 52.4817 34.0468 51.9218 33.3802C51.3753 32.7137 51.102 31.6739 51.102 30.2609Z"
        fill="#140A9A"
      />
      <path
        d="M45.1227 31.1207C45.1227 30.8807 45.0427 30.6808 44.8828 30.5208C44.7228 30.3608 44.4296 30.2475 44.003 30.1809L42.8632 29.9809C41.4902 29.7276 40.4904 29.3477 39.8638 28.8412C39.2373 28.3346 38.924 27.6081 38.924 26.6616C38.924 25.6218 39.3439 24.8153 40.1838 24.2421C41.0236 23.6556 42.17 23.3623 43.6231 23.3623C45.0894 23.3623 46.2092 23.6356 46.9823 24.1821C47.7555 24.7287 48.2354 25.5019 48.422 26.5016L45.0628 26.8216C44.9694 26.4617 44.8095 26.1884 44.5829 26.0018C44.3696 25.8151 44.043 25.7218 43.6031 25.7218C43.1365 25.7218 42.8032 25.8018 42.6033 25.9618C42.4033 26.1217 42.3033 26.3284 42.3033 26.5816C42.3033 26.8216 42.39 27.0149 42.5633 27.1615C42.7366 27.2948 43.0498 27.4081 43.5031 27.5014L44.7028 27.7214C45.676 27.908 46.4425 28.1413 47.0023 28.4212C47.5622 28.7012 47.9621 29.0544 48.2021 29.481C48.442 29.9076 48.562 30.4475 48.562 31.1007C48.562 32.1805 48.1621 33.0203 47.3623 33.6202C46.5758 34.2067 45.396 34.5 43.823 34.5C42.2633 34.5 41.0369 34.2067 40.1438 33.6202C39.264 33.0336 38.7774 32.1205 38.6841 30.8807H42.1034C42.17 31.294 42.3366 31.5873 42.6033 31.7605C42.8832 31.9205 43.2698 32.0005 43.763 32.0005C44.2563 32.0005 44.6028 31.9272 44.8028 31.7805C45.0161 31.6339 45.1227 31.414 45.1227 31.1207Z"
        fill="#140A9A"
      />
      <path
        d="M28.7355 31.1207C28.7355 30.8807 28.6555 30.6808 28.4956 30.5208C28.3356 30.3608 28.0423 30.2475 27.6158 30.1809L26.476 29.9809C25.103 29.7276 24.1032 29.3477 23.4766 28.8412C22.8501 28.3346 22.5368 27.6081 22.5368 26.6616C22.5368 25.6218 22.9567 24.8153 23.7966 24.2421C24.6364 23.6556 25.7828 23.3623 27.2358 23.3623C28.7022 23.3623 29.822 23.6356 30.5951 24.1821C31.3683 24.7287 31.8482 25.5019 32.0348 26.5016L28.6755 26.8216C28.5822 26.4617 28.4223 26.1884 28.1956 26.0018C27.9824 25.8151 27.6557 25.7218 27.2158 25.7218C26.7493 25.7218 26.416 25.8018 26.2161 25.9618C26.0161 26.1217 25.9161 26.3284 25.9161 26.5816C25.9161 26.8216 26.0028 27.0149 26.1761 27.1615C26.3494 27.2948 26.6626 27.4081 27.1159 27.5014L28.3156 27.7214C29.2887 27.908 30.0553 28.1413 30.6151 28.4212C31.175 28.7012 31.5749 29.0544 31.8149 29.481C32.0548 29.9076 32.1748 30.4475 32.1748 31.1007C32.1748 32.1805 31.7749 33.0203 30.9751 33.6202C30.1886 34.2067 29.0088 34.5 27.4358 34.5C25.8761 34.5 24.6497 34.2067 23.7566 33.6202C22.8768 33.0336 22.3902 32.1205 22.2969 30.8807H25.7162C25.7828 31.294 25.9494 31.5873 26.2161 31.7605C26.496 31.9205 26.8826 32.0005 27.3758 32.0005C27.869 32.0005 28.2156 31.9272 28.4156 31.7805C28.6289 31.6339 28.7355 31.414 28.7355 31.1207Z"
        fill="#140A9A"
      />
      <path
        d="M17.8981 31.1207C17.8981 30.8807 17.8181 30.6808 17.6582 30.5208C17.4982 30.3608 17.2049 30.2475 16.7784 30.1809L15.6386 29.9809C14.2656 29.7276 13.2658 29.3477 12.6392 28.8412C12.0127 28.3346 11.6994 27.6081 11.6994 26.6616C11.6994 25.6218 12.1193 24.8153 12.9592 24.2421C13.799 23.6556 14.9454 23.3623 16.3984 23.3623C17.8648 23.3623 18.9846 23.6356 19.7577 24.1821C20.5309 24.7287 21.0108 25.5019 21.1974 26.5016L17.8381 26.8216C17.7448 26.4617 17.5849 26.1884 17.3582 26.0018C17.1449 25.8151 16.8184 25.7218 16.3784 25.7218C15.9119 25.7218 15.5786 25.8018 15.3786 25.9618C15.1787 26.1217 15.0787 26.3284 15.0787 26.5816C15.0787 26.8216 15.1654 27.0149 15.3387 27.1615C15.512 27.2948 15.8252 27.4081 16.2785 27.5014L17.4782 27.7214C18.4513 27.908 19.2178 28.1413 19.7777 28.4212C20.3376 28.7012 20.7375 29.0544 20.9775 29.481C21.2174 29.9076 21.3374 30.4475 21.3374 31.1007C21.3374 32.1805 20.9375 33.0203 20.1377 33.6202C19.3512 34.2067 18.1714 34.5 16.5984 34.5C15.0387 34.5 13.8123 34.2067 12.9192 33.6202C12.0394 33.0336 11.5528 32.1205 11.4595 30.8807H14.8788C14.9454 31.294 15.112 31.5873 15.3786 31.7605C15.6586 31.9205 16.0452 32.0005 16.5384 32.0005C17.0316 32.0005 17.3782 31.9272 17.5782 31.7805C17.7915 31.6339 17.8981 31.414 17.8981 31.1207Z"
        fill="#140A9A"
      />
      <path
        d="M0 31.2806C0 30.3875 0.233281 29.6876 0.69985 29.1811C1.17975 28.6612 1.81962 28.2879 2.61945 28.0613C3.41929 27.8347 4.2991 27.7081 5.2589 27.6814L6.61862 27.6414V27.2815C6.61862 26.7616 6.51196 26.3883 6.29868 26.1617C6.08539 25.9218 5.74546 25.8018 5.27889 25.8018C4.83898 25.8018 4.49906 25.8951 4.25911 26.0817C4.03249 26.255 3.88585 26.515 3.8192 26.8616L0.599873 26.6016C0.959797 24.4421 2.55947 23.3623 5.39887 23.3623C6.99854 23.3623 8.17162 23.7222 8.91813 24.4421C9.66464 25.1486 10.0379 26.2217 10.0379 27.6614V34.18H6.75859V33.0603C6.33201 33.5135 5.85877 33.8601 5.33888 34.1001C4.83232 34.3267 4.23911 34.44 3.55925 34.44C2.49281 34.44 1.63299 34.16 0.97979 33.6002C0.326594 33.0403 0 32.2671 0 31.2806ZM3.3593 31.0407C3.3593 31.7072 3.76588 32.0405 4.57904 32.0405C5.20558 32.0405 5.6988 31.8672 6.05873 31.5206C6.43198 31.174 6.61862 30.7141 6.61862 30.1409V29.661L5.37887 29.721C4.64569 29.761 4.1258 29.8876 3.8192 30.1009C3.5126 30.3142 3.3593 30.6275 3.3593 31.0407Z"
        fill="#140A9A"
      />
      <path
        d="M33.6094 23.6922H37.2086V34.19H33.6094V23.6922ZM33.6094 19.833H37.2086V22.4325H33.6094V19.833Z"
        fill="#140A9A"
      />
    </svg>
  );
}
