import { IconCompany, IconLocation } from "@app/components/icons";
import { FC } from "react";
import { Box, Typography } from "@mui/material";

interface TitleDetailProps {
  groupName: string;
  company: string;
  location: string;
}

export const TitleDetail: FC<TitleDetailProps> = ({
  groupName,
  company,
  location,
}) => {
  return (
    <div className="title-detail txt-pre-wrap">
      <Typography
        sx={{ fontSize: "24px", fontWeight: 700, marginBottom: "16px" }}
        variant="h3"
      >
        {groupName}
      </Typography>
      <Box sx={{ fontSize: "14px", color: "#4c5459" }}>
        <>
          <span className="text-detail">
            <IconCompany />
          </span>
          {company || "-"}
        </>
        <div className="text-detail-break"></div>
        <>
          <span className="text-detail location">
            <IconLocation />
          </span>
          {location || "-"}
        </>
      </Box>
    </div>
  );
};
