import React from "react";

type Props = {
  children: React.ReactNode;
  size?: number;
};

export function Container({ children, size }: Props) {
  return (
    <div className="container" style={{ maxWidth: size }}>
      {children}
    </div>
  );
}
