export function IconBox() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="54"
      height="54"
      viewBox="0 0 54 54"
      fill="none"
    >
      <g clipPath="url(#clip0_15833_199)">
        <path
          d="M45.3773 19.5201L52.026 11.6248L33.9915 2.06738L27.0936 9.87954L20.1956 2.06738L2.16113 11.6248L8.80978 19.6863L2.16113 27.7478L9.22532 31.9863H8.80978V41.8762L27.0936 51.5167L45.3773 41.8762V31.9863H45.0449L52.026 27.7478L45.3773 19.5201Z"
          fill="#E2F2FF"
        />
        <path
          opacity="0.25"
          d="M45.3773 19.9356L27.0936 29.4931L35.1551 37.8039L52.026 27.9971L45.3773 19.9356ZM8.80978 19.6863L2.16113 11.6248L20.1956 2.06738L27.0105 9.87954L8.80978 19.6863Z"
          fill="white"
        />
        <path
          opacity="0.25"
          d="M27.0107 10.2949L8.81006 20.1017L27.0938 29.4929L45.3776 19.9355L27.0107 10.2949Z"
          fill="#140A9A"
        />
        <path
          opacity="0.15"
          d="M33.9915 2.06738L27.0105 9.87954L45.3773 19.5201L52.026 11.5417L33.9915 2.06738ZM8.80978 19.6863L2.16113 27.5816L8.80978 31.4045V41.8762L27.0936 51.9322V29.4931H26.678L27.0936 29.0775L8.80978 19.6863Z"
          fill="#140A9A"
        />
        <path
          d="M8.81006 32.0693V42.2916L27.0938 51.9322L45.3776 42.2916V32.0693"
          stroke="#140A9A"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.0936 51.9327V29.4935M27.0936 29.4935L19.1152 37.7212L2.16113 27.9976L8.80978 20.1023L2.16113 12.0408L20.1956 2.4834L27.0105 10.2956L33.9915 2.4834L52.026 11.9577L45.3773 19.9361L52.026 27.9976L35.1551 37.8043L27.0936 29.4935Z"
          stroke="#140A9A"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.0107 10.2949L8.81006 20.1017L27.0938 29.4929L45.3776 19.9355L27.0107 10.2949Z"
          stroke="#140A9A"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_15833_199">
          <rect
            width="53.1892"
            height="53.1892"
            fill="white"
            transform="translate(0.499023 0.405273)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
