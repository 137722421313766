import { useSelector } from "react-redux";
import { Footer, Header } from "@app/components/organisms";
import { DataAlert, ErrorPopup, Loading } from "@app/components/atoms";
import { RootState } from "@app/store";
import { useEffect } from "react";
import { useForceLogout } from "@app/hooks/useForceLogout";
import { useAuth } from "@app/hooks";

type Props = {
  children: React.ReactNode;
};

export function DefaultLayout({ children }: Props) {
  const error = useSelector((state: RootState) => state?.error?.data);
  const msg = useSelector((state: RootState) => state?.msg?.value);
  const { getTimer, setTimer, isExpired } = useForceLogout();
  const { logout } = useAuth();
  const isLoading = useSelector(
    (state: RootState) => state?.loading?.isLoading
  );
  const isMachinePage = useSelector(
    (state: RootState) => state?.inspection?.isMachinePage
  );

  // set observer for listening click action
  // logout when user inactive over 60min
  useEffect(() => {
    const handleExpire = (e: StorageEvent | MouseEvent) => {
      const timer = getTimer();

      const expireResult = isExpired();

      // logout when user inactive over 60min OR storage event with no timer(timer taken out because of logout happened on another tab)
      if (expireResult || (e.type === "storage" && !timer)) {
        logout();
        return;
      }

      setTimer();
    };

    document.addEventListener("click", handleExpire);
    // for the case when user open multiple tabs
    window.addEventListener("storage", handleExpire);

    return () => {
      document.removeEventListener("click", handleExpire);
      window.addEventListener("storage", handleExpire);
    };
  }, []);

  return (
    <>
      <Header />
      <main className="page-main min-height-no-footer">{children}</main>
      {!isMachinePage && <Footer />}
      {!!msg && <DataAlert content={msg} />}
      {!!error.title && <ErrorPopup error={error} />}
      {!!isLoading && <Loading />}
    </>
  );
}
