export function IconCellular() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="13"
      viewBox="0 0 20 13"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5027 1.18138C19.5027 0.548339 19.0252 0.0351562 18.4361 0.0351562H17.3694C16.7803 0.0351562 16.3027 0.548339 16.3027 1.18138V11.1153C16.3027 11.7484 16.7803 12.2616 17.3694 12.2616H18.4361C19.0252 12.2616 19.5027 11.7484 19.5027 11.1153V1.18138ZM12.0686 2.48044H13.1353C13.7244 2.48044 14.202 3.00594 14.202 3.65417V11.0878C14.202 11.7361 13.7244 12.2616 13.1353 12.2616H12.0686C11.4795 12.2616 11.002 11.7361 11.002 11.0878V3.65417C11.002 3.00594 11.4795 2.48044 12.0686 2.48044ZM7.73685 5.12949H6.67018C6.08108 5.12949 5.60352 5.66168 5.60352 6.31817V11.0729C5.60352 11.7294 6.08108 12.2616 6.67018 12.2616H7.73685C8.32595 12.2616 8.80352 11.7294 8.80352 11.0729V6.31817C8.80352 5.66168 8.32595 5.12949 7.73685 5.12949ZM2.43607 7.57478H1.3694C0.780297 7.57478 0.302734 8.09937 0.302734 8.74648V11.0899C0.302734 11.737 0.780297 12.2616 1.3694 12.2616H2.43607C3.02517 12.2616 3.50273 11.737 3.50273 11.0899V8.74648C3.50273 8.09937 3.02517 7.57478 2.43607 7.57478Z"
        fill="black"
      />
    </svg>
  );
}
