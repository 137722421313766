import React, { useEffect, useState } from "react";
import Alert from "@mui/material/Alert";
import { useDispatch } from "react-redux";
import { setShowAlert } from "@app/store";
import { FOUR_THREE_SECONDS } from "@app/constants";

type AlertProps = {
  content: string;
};

export function DataAlert({ content = "" }: AlertProps) {
  const [show, setShow] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const timeId = setTimeout(() => {
      setShow(false);
      dispatch(setShowAlert(""));
    }, FOUR_THREE_SECONDS);
    return () => {
      clearTimeout(timeId);
    };
  }, []);

  if (!show) {
    return null;
  }

  return (
    <Alert
      className="data-alert flex-centered justify-content-between"
      severity="warning"
      icon={false}
      onClose={() => {
        setShow(false);
      }}
    >
      {content}
    </Alert>
  );
}
