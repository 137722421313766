import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { DndProvider } from "react-dnd";
import { Outlet, useNavigate } from "react-router-dom";
import { TouchBackend } from "react-dnd-touch-backend";
import { HTML5Backend } from "react-dnd-html5-backend";
import { MsalProvider } from "@azure/msal-react";
import {
  InteractionRequiredAuthError,
  PublicClientApplication,
} from "@azure/msal-browser";
import { useAuth, useToken } from "@app/hooks";
import { msalConfig } from "@app/api";
import { StorageKey } from "@app/constants";
import { isMobileDevice } from "@app/helpers";

function App({ instance }: { instance: PublicClientApplication }) {
  const [cookies] = useCookies([StorageKey.AUTH_ACCESS_TOKEN]);
  const { saveTokens } = useToken();
  const { login } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!!cookies?.tokens) {
      return;
    }
    const fn = async () => {
      await instance.initialize();
      instance
        .handleRedirectPromise()
        .then((response) => {
          if (response) {
            login(response);
          } else {
            const currentAccounts = instance.getAllAccounts();
            if (!currentAccounts.length) {
              instance.loginRedirect({
                scopes: [],
                ...(["/error-account", "/404"].includes(
                  window.location.pathname
                )
                  ? { redirectStartPage: msalConfig.auth.redirectUri }
                  : {}),
              });
            } else {
              const account = currentAccounts[0];
              const accessTokenRequest = {
                scopes: [],
                account,
              };
              instance
                .acquireTokenSilent(accessTokenRequest)
                .then((accessTokenResponse) => {
                  // Acquire token silent success
                  saveTokens({ token: accessTokenResponse.idToken });
                })
                .catch((error) => {
                  // Acquire token silent failure, and send an interactive request
                  if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(accessTokenRequest);
                  }
                });
            }
          }
        })
        .catch((error) => {
          // User does not exist
          if (error.errorMessage.includes("AADB2C99002")) {
            navigate("/error-account");
          }
        });
    };
    fn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MsalProvider instance={instance}>
      <DndProvider backend={isMobileDevice ? TouchBackend : HTML5Backend}>
        <Outlet />
      </DndProvider>
    </MsalProvider>
  );
}

export default App;
