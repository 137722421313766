import userIconSrc from "@app/components/icons/IconUser/iconUser.jpg";

export function IconUser({ size = 20 }) {
  return (
    <img
      style={{
        borderRadius: "50%",
        minWidth: `${size}px`,
        minHeight: `${size}px`,
      }}
      alt="user icon"
      src={userIconSrc}
      width="100%"
      height="100%"
    />
  );
}
