import { deleteUser } from "@app/api";
import { Button, Helmet } from "@app/components/atoms";
import { IconVector } from "@app/components/icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@app/hooks";

export function DeleteAccount() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleDeleteAccount = () => {
    deleteUser().then(() => {
      logout();
    });
  };

  return (
    <div className="page-not-found">
      <Helmet title={t("metaInfo.deleteAccount")} />
      <div className="container">
        <div className="row">
          <div className="wrapper-content sp-p24">
            <div className="text-center bg-white content">
              <IconVector className="mt-5" />
              <h3 className="m-0 txt-black-1 fw-700 fs-18 mt-24">
                {t("deleteAccount.title")}
              </h3>
              <p className="m-0 txt-black mt-8 fs-16 txt-pre-wrap">
                {t("deleteAccount.description")}
              </p>
              <div className="mt-64 mb-24">
                <Button className="btn-no-border" onClick={() => navigate("/")}>
                  {t("deleteAccount.btnBack")}
                </Button>
                <Button
                  className="btn-primary bg-red ml-16 pl-24 pr-24"
                  onClick={() => handleDeleteAccount()}
                >
                  {t("delete")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
