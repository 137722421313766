import { createSlice } from "@reduxjs/toolkit";

// Define a type for the slice state
interface ErrorState {
  data: {
    title: string;
    content: string;
    btn: {
      title: string;
      style: string;
    }
    onClick?: () => void;
  }
}

// Define the initial state using that type
const initialState = {
  data: {
    title: "",
    content: "",
    btn: {
      title: "",
      style: ""
    }
  },
} as ErrorState;

export const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setError: (state, action) => {
      state.data = {
        ...state.data,
        ...action.payload
      };
    },
    resetError: (state) => {
      state.data = initialState.data;
    }
  },
});

export const { setError, resetError } = errorSlice.actions;

export default errorSlice.reducer;
