import { differenceInYears, format } from "date-fns";

export const isSameOrAfter = (date1: any, date2: any): boolean => {
  return differenceInYears(date1, date2) > 0;
};

export const getCurrentUtc = () => {
  return format(new Date(), "XXX") === "Z"
    ? "+00:00"
    : format(new Date(), "XXX");
};
