export function IconActionOverflow() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.09375 10.5C4.99375 10.5 4.09375 11.4 4.09375 12.5C4.09375 13.6 4.99375 14.5 6.09375 14.5C7.19375 14.5 8.09375 13.6 8.09375 12.5C8.09375 11.4 7.19375 10.5 6.09375 10.5ZM18.0938 10.5C16.9937 10.5 16.0938 11.4 16.0938 12.5C16.0938 13.6 16.9937 14.5 18.0938 14.5C19.1938 14.5 20.0938 13.6 20.0938 12.5C20.0938 11.4 19.1938 10.5 18.0938 10.5ZM12.0938 10.5C10.9938 10.5 10.0938 11.4 10.0938 12.5C10.0938 13.6 10.9938 14.5 12.0938 14.5C13.1938 14.5 14.0938 13.6 14.0938 12.5C14.0938 11.4 13.1938 10.5 12.0938 10.5Z"
        fill="#ADAAAF"
      />
    </svg>
  );
}
