import { AxiosResponse } from "axios";
import { api, ENDPOINTS } from "@app/api";
import { InspectionsType } from "@app/types";
import {
  DATE_TIME_FORMAT_YEAR_AND_MONTH_1,
  DATE_TIME_FORMAT_YEAR_AND_MONTH_2,
} from "@app/constants";
import { format } from "date-fns";

export const apiInspectionFormTemplates = (
  groupId: string,
  machineId: string
): Promise<AxiosResponse> => {
  return api.get(ENDPOINTS.inspectionFormTemplates(groupId, machineId));
};

export const apiInspectionForms = (
  groupId: string,
  machineId: string
): Promise<AxiosResponse> => {
  return api.get(ENDPOINTS.inspectionForms(groupId, machineId));
};

export const apiInspectionTemplatesDetail = (
  groupId: string,
  machineId: string,
  inspectionFormId: string
): Promise<AxiosResponse> => {
  return api.get(
    ENDPOINTS.inspectionFormDetail(groupId, machineId, inspectionFormId)
  );
};

export const apiPutCustomInspectionForm = (
  groupId: string,
  machineId: string,
  inspectionFormId: string,
  payload: InspectionsType
): Promise<AxiosResponse> => {
  return api.put(
    ENDPOINTS.customInspectionFormDetail(groupId, machineId, inspectionFormId),
    { ...payload }
  );
};

export const apiCustomInspectionTemplatesDetail = (
  groupId: string,
  machineId: string,
  customInspectionFormId: string
): Promise<AxiosResponse> => {
  return api.get(
    ENDPOINTS.customInspectionFormDetail(
      groupId,
      machineId,
      customInspectionFormId
    )
  );
};

export const apiPostInspectionForms = (
  groupId: string,
  machineId: string,
  payload: any
): Promise<AxiosResponse> => {
  return api.post(ENDPOINTS.inspectionForm(groupId, machineId), {
    ...payload,
  });
};

export const apiGetInspectionForms = (
  groupId: string,
  machineId: string
): Promise<AxiosResponse> => {
  return api.get(ENDPOINTS.inspectionForm(groupId, machineId));
};

export const apiUploadUrls = (payload: any): Promise<AxiosResponse> => {
  return api.post(ENDPOINTS.uploadUrls, {
    ...payload,
  });
};

export const apiUploadStore = (file: File): Promise<AxiosResponse> => {
  return api.put(ENDPOINTS.uploadStore, file);
};

export const apiGetInspections = (
  groupId: string,
  machineId: string,
  params: {
    monthYear: string;
    customInspectionFormId: string;
  }
): Promise<AxiosResponse> => {
  return api.get(ENDPOINTS.inspections(groupId, machineId), { params });
};

export const apiGetInspectionsPDF = (
  groupId: string,
  machineId: string,
  params: {
    monthYear: string;
    customInspectionFormId: string;
  }
): Promise<AxiosResponse> => {
  return api.get(ENDPOINTS.inspectionPdf(groupId, machineId), {
    params,
  });
};

export const getInspectionFormTemplates = (
  groupId: string,
  machineId: string
) => {
  return apiInspectionFormTemplates(groupId, machineId).then((db) => {
    const { data } = db.data;
    return data;
  });
};

export const getInspectionForms = (groupId: string, machineId: string) => {
  return apiInspectionForms(groupId, machineId).then((db) => {
    const { data } = db.data;
    return data;
  });
};

export const getInspectionFormTemplatesDetail = (
  groupId: string,
  machineId: string,
  inspectionFormId: string
) => {
  return apiInspectionTemplatesDetail(
    groupId,
    machineId,
    inspectionFormId
  ).then((db) => {
    const { data } = db.data;
    return data;
  });
};

export const getCustomInspectionFormTemplates = (
  groupId: string,
  machineId: string,
  customInspectionFormId: string
) => {
  return apiCustomInspectionTemplatesDetail(
    groupId,
    machineId,
    customInspectionFormId
  ).then((db) => {
    const { data } = db.data;
    return data;
  });
};

export const postInspectionFormTemplates = (
  groupId: string,
  machineId: string,
  payload: InspectionsType
) => {
  return apiPostInspectionForms(groupId, machineId, payload);
};

export const getInspectionForm = (groupId: string, machineId: string) => {
  return apiGetInspectionForms(groupId, machineId).then((db) => {
    const { data } = db.data;
    return data;
  });
};

export const postUploadUrls = (payload: { type: string; fileName: string }) => {
  return apiUploadUrls(payload)
    .then((db) => {
      const { data } = db.data;
      return data;
    })
    .catch(() => {
      throw new Error();
    });
};

export const putCustomInspectionForm = (
  groupId: string,
  machineId: string,
  inspectionFormId: string,
  payload: InspectionsType
) => {
  return apiPutCustomInspectionForm(
    groupId,
    machineId,
    inspectionFormId,
    payload
  );
};

export const putUploadStore = (file: File) => {
  return apiUploadStore(file)
    .then((db) => {
      const { data } = db.data;
      return data;
    })
    .catch(() => {
      throw new Error();
    });
};

export const getInspections = (
  groupId: string,
  machineId: string,
  params: {
    monthYear: string;
    customInspectionFormId: string;
    utc?: string;
  }
) => {
  return apiGetInspections(groupId, machineId, params).then((db) => {
    const { data } = db.data;
    return data;
  });
};

const arrayBufferToFile = (arrayBuffer: any, fileName: string) => {
  const blob = new Blob([new Uint8Array(arrayBuffer)]);
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");

  a.style.display = "none";
  a.href = url;
  a.download = fileName;

  document.body.appendChild(a);
  a.click();
};

export const getInspectionsPDF = (
  groupId: string,
  machineId: string,
  params: {
    monthYear: Date;
    customInspectionFormId: string;
    utc?: string;
  }
) => {
  return apiGetInspectionsPDF(groupId, machineId, {
    ...params,
    monthYear: format(
      new Date(params?.monthYear),
      DATE_TIME_FORMAT_YEAR_AND_MONTH_1
    ),
  }).then((db) => {
    const { data } = db.data.data;
    const arrayBuffer = new Uint8Array(data).buffer;
    arrayBufferToFile(
      arrayBuffer,
      `${format(
        new Date(params?.monthYear),
        DATE_TIME_FORMAT_YEAR_AND_MONTH_2
      )}_InspectionResult.pdf`
    );
  });
};
