import { StorageKey } from "@app/constants";

/**
 * Set an item to storage
 * @param key
 * @param value
 */
export function setItem(key: StorageKey, value: any) {
  return localStorage.setItem(key, JSON.stringify(value));
}

/**
 * Get an item from storage
 * @param key
 */
export function getItem(key: StorageKey) {
  try {
    return JSON.parse(localStorage.getItem(key) || "null");
  } catch (e) {
    throw e;
  }
}

/**
 * Remove an item in storage
 */
export function removeItem(key: StorageKey) {
  return localStorage.removeItem(key);
}
