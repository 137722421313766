export function IconReport() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="2 2 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.98 3.75H8.52L3.25 9.02V16.48L8.52 21.75H15.98L21.25 16.48V9.02L15.98 3.75ZM19.25 15.65L15.15 19.75H9.35L5.25 15.65V9.85L9.35 5.75H15.15L19.25 9.85V15.65Z"
        fill="#DA9309"
      />
      <path
        d="M12.25 17.75C12.8023 17.75 13.25 17.3023 13.25 16.75C13.25 16.1977 12.8023 15.75 12.25 15.75C11.6977 15.75 11.25 16.1977 11.25 16.75C11.25 17.3023 11.6977 17.75 12.25 17.75Z"
        fill="#DA9309"
      />
      <path d="M11.25 7.75H13.25V14.75H11.25V7.75Z" fill="#DA9309" />
    </svg>
  );
}
