export function IconBack() {
  return (
    <svg
      width="23.75"
      height="24"
      viewBox="0 0 23.75 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2913 7.91L14.896 6.5L8.9585 12.5L14.896 18.5L16.2913 17.09L11.759 12.5L16.2913 7.91Z"
        fill="#140A9A"
      />
    </svg>
  );
}
