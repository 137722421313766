export function IconMaintenace() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
    >
      <g clipPath="url(#clip0_20079_4039)">
        <rect y="0.5" width="40" height="40" rx="20" fill="#FFF8C5" />
        <rect
          y="0.5"
          width="40"
          height="40"
          rx="20"
          fill="white"
          fillOpacity="0.5"
        />
        <path
          d="M20 29.5C21.1046 29.5 22 28.6046 22 27.5C22 26.3954 21.1046 25.5 20 25.5C18.8954 25.5 18 26.3954 18 27.5C18 28.6046 18.8954 29.5 20 29.5Z"
          fill="#DA9309"
        />
        <path d="M18.25 11.5H21.75V23.5H18.25V11.5Z" fill="#DA9309" />
      </g>
      <defs>
        <clipPath id="clip0_20079_4039">
          <rect y="0.5" width="40" height="40" rx="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
