import { useEffect, useRef, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
import { format, getDaysInMonth, getMonth, getYear } from "date-fns";
import {
  DATE_TIME_FORMAT,
  DATE_TIME_FORMAT_1,
  INVALID_URL,
  ItemInspectionEnum,
  ItemResultType,
} from "@app/constants";
import {
  IconInvalidUrl,
  IconLocationMachine,
  IconReport,
  IconTick,
  IconUser,
} from "@app/components/icons";
import { useTranslation } from "react-i18next";
import { Button, DataDialog, DataTooltip, Video } from "@app/components/atoms";
import {
  formatNumberWithCommas,
  formatTime,
  iOSversion,
  isMobile,
  resetScroll,
} from "@app/helpers";
import { Avatar, Box } from "@mui/material";
import { Inspection } from "@app/types";
import { Swiper, SwiperSlide } from "swiper/react";
import { useParams } from "react-router-dom";
import { getMachineReportDetail, updateMachineReportStatus } from "@app/api";
import { useDispatch } from "react-redux";
import { setIsUpdatedMachineReportResponses } from "@app/store";

const TABLE_MAX_HEIGHT = 450;

const TABLE_MIN_WIDTH = 864;

type TableInspectionFormTabProps = {
  displayData: any;
  currentDate: Date;
  didScrollTop?: () => void;
};

export function TableInspectionFormTab({
  displayData,
  currentDate,
  didScrollTop,
}: TableInspectionFormTabProps) {
  const { t } = useTranslation();
  const [dataCell, setDataCell] = useState<Inspection>();
  const [open, setOpen] = useState(false);
  const [openDialogReport, setOpenDialogReport] = useState(false);
  const [dbMachine, setDbMachine] = useState<any>();
  const { groupId, machineId } = useParams();
  const tableContainerRef = useRef<HTMLTableElement>(null);
  const dispatch = useDispatch();

  const convertHour = (time: Date) => {
    if (formatTime(time, "hh") === "12") {
      return `00:${formatTime(time, "mm")}`;
    }
    return formatTime(time, "hh:mm");
  };

  const handleMediaOnError = (index: number, mediaIndex: number) => {
    const newDbMachine = {
      ...dbMachine,
    };
    newDbMachine.machineReportResponses[index].machineReportMedias[
      mediaIndex
    ].mediaUrl = INVALID_URL;
    setDbMachine(newDbMachine);
  };

  const handleClickIconReport = (machineReportId: string) => {
    !!groupId &&
      !!machineId &&
      getMachineReportDetail(groupId, machineId, machineReportId).then((db) => {
        setDbMachine(db);
        setOpenDialogReport(true);
        !db?.isRead &&
          db?.isRead !== null &&
          updateMachineReportStatus(
            groupId,
            machineId,
            machineReportId,
            true
          ).then(() => {
            dispatch(setIsUpdatedMachineReportResponses(`${new Date()}`));
          });
      });
  };

  useEffect(() => {
    tableContainerRef.current &&
      resetScroll(tableContainerRef.current, () => {
        didScrollTop?.();
      });
  }, [displayData]);

  return (
    <>
      <TableContainer
        className={`table-inspection-form ${
          isMobile() && iOSversion() < 15 ? "iOS-less-than-15" : ""
        }`}
        component={Paper}
        style={{
          maxHeight: TABLE_MAX_HEIGHT,
          borderRadius: isMobile() ? "0" : "8px",
        }}
        ref={tableContainerRef}
      >
        <Table
          className="data-table"
          sx={{ minWidth: TABLE_MIN_WIDTH, maxHeight: TABLE_MAX_HEIGHT }}
          stickyHeader
          size="small"
          aria-labelledby="tableTitle"
        >
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  padding: "6.625px 12px 6.625px",
                }}
                align="left"
                rowSpan={2}
                style={{
                  minWidth: 197,
                  fontWeight: 700,
                  position: "sticky",
                  top: "-1px",
                  left: 0,
                  zIndex: 20,
                }}
                className="bg-purple txt-black fs-16"
              >
                {t("inspectionItem")}
              </TableCell>
              {Array.from({ length: getDaysInMonth(currentDate) })?.map(
                (_day, i) => (
                  <TableCell
                    className="bg-purple fs-16 txt-black-1 fw-700"
                    sx={{
                      padding: "6.625px 12px 6.625px",
                    }}
                    align="center"
                    key={i}
                    style={{ minWidth: 108, maxWidth: 108 }}
                    colSpan={
                      (
                        displayData?.[0]?.[
                          format(
                            new Date(
                              getYear(currentDate),
                              getMonth(currentDate),
                              i + 1
                            ),
                            DATE_TIME_FORMAT_1
                          )
                        ] as Array<any>
                      )?.length
                    }
                  >
                    {i + 1}
                  </TableCell>
                )
              )}
            </TableRow>
            <TableRow>
              {Array.from({ length: getDaysInMonth(currentDate) })?.map(
                (_day, i) => {
                  let records = displayData?.[0]?.[
                    format(
                      new Date(
                        getYear(currentDate),
                        getMonth(currentDate),
                        i + 1
                      ),
                      DATE_TIME_FORMAT_1
                    )
                  ] as any;
                  return !!records?.length ? (
                    records?.map((record: any, i: number) => (
                      <TableCell
                        style={{ minWidth: 108, maxWidth: 108 }}
                        align="center"
                        key={i}
                        className="bg-purple"
                      >
                        <p className="m-0 txt-gray-3 fw-500 fs-12">
                          {formatTime(record?.inspectionAt, "a")}
                        </p>
                        <p className="m-0 txt-gray-3 fw-500 fs-12">
                          {convertHour(record?.inspectionAt)}
                        </p>
                      </TableCell>
                    ))
                  ) : (
                    <TableCell className="bg-purple" key={i} align="center" />
                  );
                }
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {displayData?.map((row: any, index: number) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  <TableCell
                    align="left"
                    style={{
                      minWidth: 197,
                      fontWeight: 400,
                      position: "sticky",
                      left: 0,
                      zIndex: 1,
                    }}
                    className="bg-purple fs-16 txt-black-1"
                  >
                    <DataTooltip isTextPre width="173px">
                      {row?.name}
                    </DataTooltip>
                  </TableCell>
                  {Array.from({ length: getDaysInMonth(currentDate) })?.map(
                    (_day, i) => {
                      let records = row?.[
                        format(
                          new Date(
                            getYear(currentDate),
                            getMonth(currentDate),
                            i + 1
                          ),
                          DATE_TIME_FORMAT_1
                        )
                      ] as any;
                      return !!records?.length ? (
                        records?.map((record: any, i: number) => {
                          let item = record?.inspectionResults?.find(
                            (ele: any) =>
                              ele?.customInspectionItemId ===
                              row?.customInspectionItemId
                          );
                          let itemShow;
                          if (item) {
                            itemShow = !!item?.itemCode ? (
                              !!item.result && (
                                <span className="txt-right w-100">
                                  {formatNumberWithCommas(item.result)}
                                </span>
                              )
                            ) : item.result === ItemResultType.OK ? (
                              <IconTick fillColor="#0C7040" />
                            ) : (
                              <span
                                className="flex-centered pointer"
                                onClick={() =>
                                  handleClickIconReport(
                                    item?.machineReport?.machineReportId
                                  )
                                }
                              >
                                <IconReport />
                              </span>
                            );
                          } else {
                            switch (row?.inspectionItemName) {
                              case ItemInspectionEnum.STAFF_NAME:
                                itemShow = (
                                  <DataTooltip isTextPre width={"90px"}>
                                    {`${record?.surname} ${record?.givenName}`}
                                  </DataTooltip>
                                );
                                break;
                              case ItemInspectionEnum.LOCATION:
                                itemShow =
                                  record?.lat && record?.lng ? (
                                    <span
                                      onClick={() => {
                                        setDataCell(record);
                                        setOpen(true);
                                      }}
                                      className="flex-centered pointer"
                                    >
                                      <IconLocationMachine />
                                    </span>
                                  ) : undefined;
                                break;
                            }
                          }

                          return (
                            <TableCell
                              style={{ minWidth: 108, maxWidth: 108 }}
                              key={i}
                              align="center"
                            >
                              {!!itemShow && (
                                <span className="flex-centered fs-16 txt-black-1 lh-24">
                                  {itemShow}
                                </span>
                              )}
                            </TableCell>
                          );
                        })
                      ) : (
                        <TableCell
                          style={{ minWidth: 108, maxWidth: 108 }}
                          key={i}
                          align="center"
                        />
                      );
                    }
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {dataCell && (
        <DataDialog
          title={t("location")}
          open={open}
          setOpen={setOpen}
          className="dialog-location-report"
        >
          <iframe
            title="map-location"
            className="map-iframe"
            loading="lazy"
            src={`https://maps.google.com/maps?&q="+${dataCell?.lat}, ${dataCell?.lng}"&output=embed`}
          />
          <span className="fs-14 txt-gray-1 label-dialog">{t("dateTime")}</span>
          <p className="fs-16 txt-gray mt-4">
            {dataCell?.inspectionAt &&
              formatTime(new Date(dataCell?.inspectionAt), DATE_TIME_FORMAT)}
          </p>
          <span className="fs-14 txt-gray-1 label-dialog">
            {t("tableMachineDetail.reporter")}
          </span>
          <div className="flex-centered justify-content-start mt-8">
            {dataCell?.userPictureUrl ? (
              dataCell?.userPictureUrl === INVALID_URL ? (
                <Box
                  sx={{
                    width: 24,
                    height: 24,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <IconInvalidUrl />
                </Box>
              ) : (
                <Avatar
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                  alt="avatar-reporter"
                  src={dataCell?.userPictureUrl}
                  imgProps={{
                    onError: () => {
                      setDataCell({ ...dataCell, userPictureUrl: INVALID_URL });
                    },
                  }}
                />
              )
            ) : (
              <Box
                sx={{
                  width: 24,
                  height: 24,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconUser />
              </Box>
            )}
            <span className="fs-16 txt-gray ml-5 word-break txt-pre-wrap">
              {`${dataCell?.surname} ${dataCell?.givenName}`}
            </span>
          </div>
          <div className="flex justify-content-end">
            <Button onClick={() => setOpen(false)} className="btn-white">
              {t("close")}
            </Button>
          </div>
        </DataDialog>
      )}
      {!!dbMachine?.machineReportResponses?.length && (
        <DataDialog
          title={dbMachine?.reportTitle}
          open={openDialogReport}
          setOpen={setOpenDialogReport}
          className="dialog-report dialog-machine-report"
          dialogActions={
            <div className="flex justify-content-end">
              <Button
                onClick={() => {
                  setOpenDialogReport(false);
                  setDbMachine("");
                }}
                className="btn-white"
              >
                {t("close")}
              </Button>
            </div>
          }
        >
          {dbMachine?.machineReportResponses?.map(
            (item: any, index: number) => {
              return (
                <div
                  className="content pb-0 pt-0 ml-24 mr-24 bg-white"
                  key={index}
                >
                  <div className="report-card">
                    <div className="content-report border-0">
                      {!!item?.machineReportMedias?.length && (
                        <Swiper
                          cssMode={true}
                          navigation={true}
                          pagination={true}
                          mousewheel={true}
                          keyboard={true}
                          modules={[
                            Navigation,
                            Pagination,
                            Mousewheel,
                            Keyboard,
                          ]}
                          className="swiper mb-16"
                        >
                          {item?.machineReportMedias.map(
                            (media: any, mediaIndex: number) => (
                              <SwiperSlide key={mediaIndex}>
                                <div className="wrapper-file">
                                  {media?.mediaUrl === INVALID_URL ? (
                                    <Box
                                      sx={{
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <IconInvalidUrl />
                                    </Box>
                                  ) : media?.mimeType.includes("video") ? (
                                    <Video
                                      media={media}
                                      onError={() =>
                                        handleMediaOnError(index, mediaIndex)
                                      }
                                    />
                                  ) : (
                                    <Box
                                      className="swiper-image img-report"
                                      component={`${
                                        media.mimeType.includes("video")
                                          ? "video"
                                          : "img"
                                      }`}
                                      controls
                                      alt="swiper-image"
                                      src={media.mediaUrl}
                                      onError={() =>
                                        handleMediaOnError(index, mediaIndex)
                                      }
                                    />
                                  )}
                                </div>
                              </SwiperSlide>
                            )
                          )}
                        </Swiper>
                      )}
                      {!!item?.reportComment && (
                        <p className="fs-16 mt-0 txt-black txt-pre-wrap word-break">
                          {item?.reportComment}
                        </p>
                      )}
                      <p className="fs-14 txt-gray-1 m-0">
                        {t("tableMachineDetail.reporter")}
                      </p>
                      <div className="flex-centered justify-content-start mt-8">
                        {!!item?.userPictureUrl ? (
                          item.userPictureUrl === INVALID_URL ? (
                            <Box
                              sx={{
                                width: 24,
                                height: 24,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <IconInvalidUrl />
                            </Box>
                          ) : (
                            <Avatar
                              sx={{
                                width: 24,
                                height: 24,
                              }}
                              alt="avatar-reporter"
                              src={item?.userPictureUrl}
                              imgProps={{
                                onError: () => {
                                  const newDbMachine = {
                                    ...dbMachine,
                                  };
                                  newDbMachine.machineReportResponses[
                                    index
                                  ].userPictureUrl = INVALID_URL;
                                  setDbMachine(newDbMachine);
                                },
                              }}
                            />
                          )
                        ) : (
                          <Box
                            sx={{
                              width: 24,
                              height: 24,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <IconUser />
                          </Box>
                        )}
                        <span className="fs-16 txt-black-1 ml-5 word-break">
                          {`${item?.surname} ${item?.givenName}`}
                        </span>
                      </div>
                      <p className="fs-14 txt-gray-1 mt-16 mb-0">
                        {t("reportedAt")}
                      </p>
                      <p className="fs-16 txt-black-1 mt-5 mb-0">
                        {dbMachine?.reportedAt &&
                          format(
                            new Date(dbMachine?.reportedAt),
                            DATE_TIME_FORMAT
                          )}
                      </p>
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </DataDialog>
      )}
    </>
  );
}
