import { useTranslation } from "react-i18next";

type Props = {
  children?: JSX.Element;
  title: string;
  className?: string;
};

export function PageTitle({ children, title, className }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className={`page-title flex align-items-center ${className}`}>
      {children}
      <h2 className="title">{t(title)}</h2>
    </div>
  );
}
