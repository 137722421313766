export function IconCopy() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 1.75H4.5C3.4 1.75 2.5 2.65 2.5 3.75V17.75H4.5V3.75H16.5V1.75ZM19.5 5.75H8.5C7.4 5.75 6.5 6.65 6.5 7.75V21.75C6.5 22.85 7.4 23.75 8.5 23.75H19.5C20.6 23.75 21.5 22.85 21.5 21.75V7.75C21.5 6.65 20.6 5.75 19.5 5.75ZM19.5 21.75H8.5V7.75H19.5V21.75Z"
        fill="#47464F"
      />
    </svg>
  );
}
