import { useEffect, useState } from "react";
import { Button, DataDialog } from "@app/components/atoms";
import {
  IconBattery,
  IconCellular,
  IconInvalidUrl,
  IconMaintenace,
  IconSelected,
  IconWarningFormEdit,
} from "@app/components/icons";
import { useTranslation } from "react-i18next";
import { Check, ArrowBack, Wifi } from "@mui/icons-material";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
import { Box, TextField } from "@mui/material";
import { InspectionItem } from "@app/types";
import { INVALID_URL, ResultType } from "@app/constants";

export function PreviewInspectionDialog({ open, setOpen, data }: any) {
  const { t } = useTranslation();
  const [inspectionItemsAnomary, setInspectionItemsAnomary] = useState<any>();

  useEffect(() => {
    setInspectionItemsAnomary(
      data?.customInspectionItems?.filter(
        (item: InspectionItem) => item?.resultType === ResultType?.OK_OR_ANOMARY
      )
    );
  }, [data?.customInspectionItems]);

  return (
    <DataDialog
      className="dialog-preview-inspection dialog-inspection-form"
      open={open}
      setOpen={setOpen}
      title={t("titlePreviewMode")}
      isIcon
    >
      <div className="warning flex justify-content-start mt-0 mb-24">
        <IconWarningFormEdit />
        <span className="txt-black-1" style={{ marginLeft: 12, flex: 1 }}>
          {t("warningTextPreview")}
        </span>
      </div>
      <div className="preview-phone">
        <div className="header-phone flex-centered justify-content-between pointer-events-none">
          <span className="time">9:41</span>
          <div>
            <IconCellular />
            <Wifi className="ml-8 fs-18" />
            <IconBattery className="ml-8" />
          </div>
        </div>
        <div className="wrapper-title flex-centered justify-content-between pointer-events-none">
          <div className="flex">
            <ArrowBack />
            <h2 className="m-0 fs-18 ml-15 title-name txt-ellipsis txt-pre txt-black-1">
              {data?.name}
            </h2>
          </div>
        </div>
        <div className="pt-24 bg-gray" />
        <div className="wrapper-content-preview">
          {!!inspectionItemsAnomary?.length && (
            <>
              <ul className="inspection-list">
                {inspectionItemsAnomary?.map((item: any, index: number) => (
                  <li key={index} className="inspection-item bg-white">
                    <div className="flex justify-content-between align-items-center">
                      <div className="pointer-events-none">
                        <p className="m-0 fs-16 txt-black-1 txt-pre-wrap word-break">
                          {item?.name}
                        </p>
                        <p className="m-0 fs-14 txt-gray-1 txt-pre-wrap word-break">
                          {item?.description}
                        </p>
                      </div>
                      <div className="flex-centered ml-5">
                        <IconMaintenace />
                        <IconSelected className="ml-15" />
                      </div>
                    </div>
                    {!!item?.customInspectionItemMedias?.length && (
                      <Swiper
                        cssMode={true}
                        navigation={true}
                        pagination={true}
                        mousewheel={true}
                        keyboard={true}
                        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                        className="swiper mt-20"
                      >
                        {item?.customInspectionItemMedias?.map(
                          (media: any, mediaIndex: number) => (
                            <SwiperSlide key={mediaIndex}>
                              <div className="wrapper-file inspection-form">
                                {media?.mediaUrl === INVALID_URL ? (
                                  <Box
                                    sx={{
                                      width: "100%",
                                      height: 210,
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <IconInvalidUrl />
                                  </Box>
                                ) : (
                                  <Box
                                    className="swiper-image"
                                    component="img"
                                    alt="swiper-image"
                                    src={
                                      media?.mediaUrl
                                        ? media?.mediaUrl
                                        : URL.createObjectURL(media)
                                    }
                                    onError={() => {
                                      const newInspectionItemsAnomary = [
                                        ...inspectionItemsAnomary,
                                      ];
                                      newInspectionItemsAnomary[
                                        index
                                      ].customInspectionItemMedias[
                                        mediaIndex
                                      ].mediaUrl = INVALID_URL;
                                      setInspectionItemsAnomary(
                                        newInspectionItemsAnomary
                                      );
                                    }}
                                  />
                                )}
                              </div>
                            </SwiperSlide>
                          )
                        )}
                      </Swiper>
                    )}
                  </li>
                ))}
              </ul>
              <div className="flex-centered pt-16 pb-24 pointer-events-none bg-gray">
                <Button className="btn-secondary">
                  <Check className="icon-add" />
                  {t("selectAll")}
                </Button>
              </div>
            </>
          )}
          <ul className="inspection-list-numberic bg-white pointer-events-none">
            {data?.customInspectionItems
              ?.filter(
                (item: InspectionItem) =>
                  item?.resultType === ResultType?.NUMERIC
              )
              .map((itemNumberic: any, index: number) => (
                <li key={index} className="wrapper-input-field">
                  <TextField
                    id={"outlined-required"}
                    label={`${itemNumberic?.name} ${
                      !!itemNumberic?.isRequired ? "*" : ""
                    }`}
                    className="input-field"
                  />
                  {!!itemNumberic?.isRequired && (
                    <span className="fs-12 ml-15 txt-gray-1">
                      &#42;{t("required")}
                    </span>
                  )}
                </li>
              ))}
          </ul>
        </div>
      </div>
    </DataDialog>
  );
}
