import { createSlice } from "@reduxjs/toolkit";

interface ReportTypeProps {
  reportType: string;
}

const initialState = {
  reportType: "INSPECTION",
} as ReportTypeProps;

export const reportTypeSlide = createSlice({
  name: "card",
  initialState,
  reducers: {
    setReportType: (state, action) => {
      state.reportType = action.payload;
    },
  },
});

export const { setReportType } = reportTypeSlide.actions;

export default reportTypeSlide.reducer;
