export function IconCheckDone() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M8.99935 0.666992C4.39935 0.666992 0.666016 4.40033 0.666016 9.00033C0.666016 13.6003 4.39935 17.3337 8.99935 17.3337C13.5993 17.3337 17.3327 13.6003 17.3327 9.00033C17.3327 4.40033 13.5993 0.666992 8.99935 0.666992ZM7.33268 13.167L3.16602 9.00033L4.34102 7.82533L7.33268 10.8087L13.6577 4.48366L14.8327 5.66699L7.33268 13.167Z"
        fill="#1A7F37"
      />
    </svg>
  );
}
