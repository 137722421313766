import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Pagination } from "@mui/material";
import { isMobile } from "@app/helpers";
import { CURRENT_PAGE, LIMIT_PAGE, TOTAL_PAGE } from "@app/constants";

type PaginationProps = {
  limitPage?: number;
  totalPage?: number;
  currentPage?: number;
  handleChangePage?: (value: number) => void;
  className?: string;
  isHidden?: boolean;
};

export function DataPagination({
  limitPage = LIMIT_PAGE,
  totalPage = TOTAL_PAGE,
  currentPage = CURRENT_PAGE,
  handleChangePage = () => {},
  className = "",
  isHidden = false,
}: PaginationProps) {
  const [page, setPage] = useState(currentPage);
  const count = totalPage && limitPage && Math.ceil(totalPage / limitPage);
  const { t } = useTranslation();
  const [siblingCount, setSiblingCount] = useState(isMobile() ? 0 : 1); // value 0 make the pagination renders first 3 pages (instead of default 5 pages) in mobile.

  const getPaginationTotalText = () => {
    const firstRecordNumOfCurrentPage = (currentPage - 1) * limitPage + 1;
    const lastRecordNumOfCurrentPage =
      limitPage * currentPage < totalPage ? limitPage * currentPage : totalPage;
    return `${firstRecordNumOfCurrentPage} ~ ${lastRecordNumOfCurrentPage} /${totalPage}`;
  };

  useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);

  return (
    <>
      {!isHidden && (
        <div
          className={`data-pagination flex-centered justify-content-between ${className}`}
        >
          <div className="pagination-total-text">
            {getPaginationTotalText()}
            {t("record.count", {
              count: totalPage,
            })}
          </div>
          <Pagination
            className={`pagination ${
              page === 3 && count > 7 ? "has-page3" : ""
            }`}
            count={count}
            variant="outlined"
            shape="rounded"
            page={page}
            onChange={(_: React.ChangeEvent<unknown>, page: number) => {
              // In mobile, when click to page 3, setSiblingCount to 1 (default) to see next page 4 and reset it to 0 on click page 2 to see 3 pages at first.
              if (isMobile() && [1, 2, 3, 6, 7].includes(page)) {
                let siblingCount: number;
                switch (page) {
                  case 1:
                    siblingCount = 0;
                    break;
                  case 6:
                  case 7:
                    siblingCount = 1;
                    break;
                  default:
                    siblingCount = page % 2;
                    break;
                }
                setSiblingCount(siblingCount);
              }
              setPage(page);
              handleChangePage && handleChangePage(page);
            }}
            siblingCount={siblingCount}
          />
        </div>
      )}
    </>
  );
}
