import { Link } from "react-router-dom";
import { IconBack } from "@app/components/icons";

export const NavigationItem = ({ navigation = "/", title = "" }) => {
  return (
    <div className="navigation-item">
      <Link className="flex-centered justify-content-start" to={navigation}>
        <IconBack />
        <span className="navigation-item-title">{title}</span>
      </Link>
    </div>
  );
};
