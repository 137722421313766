export function IconPlus() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0938 10.25H9.84375V15.5H8.34375V10.25H3.09375V8.75H8.34375V3.5H9.84375V8.75H15.0938V10.25Z"
        fill="white"
      />
    </svg>
  );
}
