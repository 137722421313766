import { RouteObject } from "react-router-dom";
import { PrivateRoute } from "@app/components/atoms";
import {
  Features,
  GroupList,
  GroupDetail,
  MachineDetail,
  MachineTab,
  InspectionTab,
  NotFound,
  Report,
  InspectionHistory,
  DeleteAccount,
} from "@app/pages/Features";
import { AuthInterceptor } from "@app/api";
import { License } from "@app/pages/Features/License/License";

const FeaturesRoutes: RouteObject[] = [
  {
    path: "/",
    element: (
      <PrivateRoute>
        <AuthInterceptor />
        <Features />
      </PrivateRoute>
    ),
    children: [
      {
        path: "/",
        element: <GroupList />,
      },
      {
        path: "/groups/:groupId/machines/:machineId",
        element: <MachineDetail />,
        children: [
          {
            path: "/groups/:groupId/machines/:machineId/report",
            element: <Report />,
          },
          {
            path: "/groups/:groupId/machines/:machineId/inspection-history",
            element: <InspectionHistory />,
          },
        ],
      },
      {
        path: "/groups/:id",
        element: <GroupDetail />,
        children: [
          {
            path: "/groups/:id/",
            element: <MachineTab />,
          },
          {
            path: "/groups/:id/machines",
            element: <MachineTab />,
          },
          {
            path: "/groups/:id/inspection-form",
            element: <InspectionTab />,
          },
        ],
      },
      {
        path: "/license",
        element: <License />,
      },
      {
        path: "/delete-account",
        element: <DeleteAccount />,
      },
      {
        path: "/404",
        element: <NotFound />,
      },
      {
        path: "/*",
        element: <NotFound />,
      },
    ],
  },
];

export default FeaturesRoutes;
