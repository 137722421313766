export function IconUpdate() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.25 6V9.75L11.4375 11.64L12.015 10.68L9.375 9.1125V6H8.25ZM15.75 7.5V2.25L13.77 4.23C12.555 3.0075 10.8675 2.25 9 2.25C5.2725 2.25 2.25 5.2725 2.25 9C2.25 12.7275 5.2725 15.75 9 15.75C12.7275 15.75 15.75 12.7275 15.75 9H14.25C14.25 11.895 11.895 14.25 9 14.25C6.105 14.25 3.75 11.895 3.75 9C3.75 6.105 6.105 3.75 9 3.75C10.4475 3.75 11.76 4.3425 12.7125 5.2875L10.5 7.5H15.75Z"
        fill="white"
      />
    </svg>
  );
}
