import { Button, DataDialog } from "@app/components/atoms";
import { DialogTypes } from "@app/constants";
import { useTranslation } from "react-i18next";

export type ConfirmDialogProps = {
  open?: boolean;
  setOpen?: any;
  typeDialog?: DialogTypes;
  title?: string;
  content?: string;
  onCancel?: () => void;
  onSubmit?: () => void;
  btnCancelText?: string;
  btnSubmitText?: string;
  classBtnSubmit?: string;
};

export function ConfirmDialog(props: ConfirmDialogProps) {
  const { t } = useTranslation();
  const {
    typeDialog = DialogTypes.CONFIRM,
    open,
    setOpen,
    title,
    content,
    onCancel,
    onSubmit,
    btnSubmitText = "",
    btnCancelText = "cancel",
    classBtnSubmit,
  } = props;

  return (
    <DataDialog
      title={t(`${title}`)}
      open={open}
      setOpen={setOpen}
      id={`dialog-confirm${typeDialog}`}
    >
      <p className="txt-gray-1 fs-16">{t(`${content}`)}</p>
      <div className="wrapper-btn flex-centered justify-content-end mt-24">
        <Button className="btn-no-border" onClick={() => onCancel?.()}>
          {t(`${btnCancelText}`)}
        </Button>
        <Button
          className={`ml-16 ${classBtnSubmit}`}
          onClick={() => onSubmit?.()}
        >
          {t(`${btnSubmitText}`)}
        </Button>
      </div>
    </DataDialog>
  );
}
