import { useAuth } from "@app/hooks";
import { useState, MouseEvent, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  Box,
  IconButton,
  Typography,
  Menu,
  Avatar,
  MenuItem,
} from "@mui/material";
import {
  Logout,
  ExpandMore,
  ExpandLess,
  DescriptionOutlined,
} from "@mui/icons-material";
import {
  IconLogo,
  IconUser,
  IconTick,
  IconSetting,
  IconInvalidUrl,
  IconLogoMobile,
  IconDeleteGray,
} from "@app/components/icons";
import { DataTooltip } from "@app/components/atoms";
import { isMobile } from "@app/helpers";
import { RootState } from "@app/store";
import { UserInfo } from "@app/types";
import { apiAuthPutMe } from "@app/api";
import { INVALID_URL } from "@app/constants";

const LANGUAGES = [
  {
    isoLocaleCode: "ja",
    text: "日本語",
  },
  {
    isoLocaleCode: "en",
    text: "English",
  },
];

export function Header() {
  const { t, i18n } = useTranslation();
  const { logout } = useAuth();
  const userInfo: UserInfo = useSelector(
    (state: RootState) => state.user.value
  );
  const [userImageUrl, setUserImageUrl] = useState<string>("");
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [anchorElLanguage, setAnchorElLanguage] = useState<null | HTMLElement>(
    null
  );

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenLanguagesMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElLanguage(event.currentTarget);
  };

  const handleCloseLanguagesMenu = () => {
    setAnchorElLanguage(null);
  };

  const handleChangeLanguage = (isoLocaleCode: string) => {
    handleCloseLanguagesMenu();
    if (isoLocaleCode === i18n.language) {
      return;
    }
    return apiAuthPutMe({
      isoLocaleCode,
    }).then(() => {
      window.location.reload();
    });
  };

  useEffect(() => {
    if (userInfo.pictureUrl) {
      setUserImageUrl(userInfo.pictureUrl);
    }
  }, [userInfo]);

  return (
    <header className="page-header">
      <div className="container">
        <div className="row flex-centered">
          <Link to="/">{isMobile() ? <IconLogoMobile /> : <IconLogo />}</Link>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              maxWidth: "50%",
              flexGrow: 0,
              marginLeft: "auto",
            }}
          >
            <>
              {userImageUrl ? (
                userImageUrl === INVALID_URL ? (
                  <Box
                    sx={{
                      width: 32,
                      height: 32,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <IconInvalidUrl />
                  </Box>
                ) : (
                  <Avatar
                    sx={{
                      width: 32,
                      height: 32,
                    }}
                    alt="avatar"
                    src={userInfo.pictureUrl}
                    imgProps={{
                      onError: () => {
                        setUserImageUrl(INVALID_URL);
                      },
                    }}
                  />
                )
              ) : (
                <Box
                  sx={{
                    width: 32,
                    height: 32,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <IconUser />
                </Box>
              )}
            </>
            <Box className="flex w-name-header" onClick={handleOpenUserMenu}>
              <Typography
                variant="h4"
                noWrap
                component="div"
                sx={{
                  fontSize: 14,
                  flexGrow: 1,
                  m: "4px 2px 4px 4px",
                  textDecoration: "none",
                  cursor: "pointer",
                  flex: 1,
                }}
              >
                <DataTooltip isTextPre width="100%">
                  {userInfo?.fullName || ""}
                </DataTooltip>
              </Typography>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                sx={{ p: 0, marginRight: "-6px" }}
              >
                {!!anchorElUser ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Box>
            <Menu
              sx={{ mt: "31.5px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={!!anchorElUser}
              onClose={handleCloseUserMenu}
            >
              <MenuItem
                sx={{ p: "8px 12px" }}
                onClick={() => {
                  logout(true);
                }}
              >
                <Logout className="txt-gray-3" />
                <Typography ml={"8px"} textAlign="center">
                  {t("signOut")}
                </Typography>
              </MenuItem>
              <a
                href={`${process.env.REACT_APP_BLOBFILE_URL}/privacy-policy/index.html`}
                rel="noreferrer"
                target="_blank"
                onClick={handleCloseUserMenu}
              >
                <MenuItem sx={{ p: "8px 12px" }}>
                  <DescriptionOutlined className="txt-gray-3" />
                  <Typography ml={"8px"} textAlign="center">
                    {t("privacyPolicy")}
                  </Typography>
                </MenuItem>
              </a>
              <a
                href={`${process.env.REACT_APP_BLOBFILE_URL}/term-of-service/index.html`}
                rel="noreferrer"
                target="_blank"
                onClick={handleCloseUserMenu}
              >
                <MenuItem sx={{ p: "8px 12px" }}>
                  <DescriptionOutlined className="txt-gray-3" />
                  <Typography ml={"8px"} textAlign="center">
                    {t("termsOfUse")}
                  </Typography>
                </MenuItem>
              </a>
              <Link to="/license" onClick={handleCloseUserMenu}>
                <MenuItem sx={{ p: "8px 12px" }}>
                  <DescriptionOutlined className="txt-gray-3" />
                  <Typography ml={"8px"} textAlign="center">
                    {t("license.title")}
                  </Typography>
                </MenuItem>
              </Link>
              <Link to="/delete-account" onClick={handleCloseUserMenu}>
                <MenuItem sx={{ p: "8px 12px" }}>
                  <IconDeleteGray className="ml-5 mr-5" />
                  <Typography ml={"8px"} textAlign="center">
                    {t("deleteAccount.name")}
                  </Typography>
                </MenuItem>
              </Link>
            </Menu>
            <IconButton
              size="large"
              aria-label="languages of current user"
              aria-controls="menu-languages"
              aria-haspopup="true"
              color="inherit"
              onClick={handleOpenLanguagesMenu}
              sx={{ cursor: "pointer" }}
              className="ml-16 pd-4 mt-10 mb-10"
            >
              <IconSetting />
            </IconButton>
            <Menu
              sx={{ mt: "31.5px" }}
              id="menu-languages"
              anchorEl={anchorElLanguage}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={!!anchorElLanguage}
              onClose={handleCloseLanguagesMenu}
            >
              {LANGUAGES.map((lang, index) => {
                return (
                  <MenuItem
                    sx={{
                      p: "8px 12px",
                      width: 200,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    key={index}
                    selected={lang.isoLocaleCode === i18n.language}
                    value={lang.isoLocaleCode}
                    onClick={() => handleChangeLanguage(lang.isoLocaleCode)}
                  >
                    <Typography ml={"8px"} textAlign="center">
                      {lang.text}
                    </Typography>
                    {lang.isoLocaleCode === i18n.language && <IconTick />}
                  </MenuItem>
                );
              })}
            </Menu>
          </Box>
        </div>
      </div>
    </header>
  );
}
