import { screenPermission } from "@app/types";
import { createSlice } from "@reduxjs/toolkit";

interface screenPermissionState {
  value: screenPermission;
}

const initialState = {
  value: {},
} as screenPermissionState;

export const screenPermissionSlice = createSlice({
  name: "screenPermission",
  initialState,
  reducers: {
    saveScreenPermission: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { saveScreenPermission } = screenPermissionSlice.actions;

export default screenPermissionSlice.reducer;
