import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { putUserSettings } from "@app/api";
import { Button, DataDialog } from "@app/components/atoms";
import { Checkbox, FormControlLabel } from "@mui/material";
import { store, setSuppressDataUsagePopup } from "@app/store";
import { setItem } from "@app/helpers";
import { StorageKey } from "@app/constants";

export function DataUsageDialog() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  const [isChecked, setIsChecked] = useState(false);

  const handleClickClose = () => {
    if (isChecked) {
      putUserSettings({ suppressDataUsagePopup: true });
      store.dispatch(setSuppressDataUsagePopup(true));
    }
    setItem(StorageKey.IS_SHOWN_DATA_USAGE_POPUP, false);
    setOpen(false);
  };

  const handleChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <DataDialog
      title={t("dialogDataUsage.title")}
      open={open}
      setOpen={setOpen}
      dialogActions={
        <div className="flex align-items-center justify-content-end">
          <Button className="btn-white" onClick={() => handleClickClose()}>
            <span>{t("close")}</span>
          </Button>
        </div>
      }
    >
      <p className="fs-16 txt-gray-1 m-0">{t("dialogDataUsage.content")}</p>
      <div className="flex-centered mt-5">
        <FormControlLabel
          className="checkbox-field"
          control={<Checkbox checked={isChecked} onChange={handleChange} />}
          label={t("dialogDataUsage.label")}
        />
      </div>
    </DataDialog>
  );
}
