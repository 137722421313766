import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Tabs, Tab, Box } from "@mui/material";
import { apiGroupDetail } from "@app/api";
import { screenPermission } from "@app/types";
import {
  Container,
  Helmet,
  NavigationItem,
  TitleDetail,
} from "@app/components/atoms";
import {
  setIsRequestedGroupDetail,
  saveScreenPermission,
  store,
} from "@app/store";
import { resetIsContinuousLoading, setItem } from "@app/helpers";
import { StorageKey } from "@app/constants";

interface TabPanelProps {
  label: string;
  route: string;
  disabled?: boolean;
  className?: string;
}

type groupType = {
  groupId: string;
  groupName: string;
  companyName: string;
  machineCount: number;
  reportCount: number;
  location: string;
  memberCount: number;
  inspectionFormCount: number;
};

export function GroupDetail() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const [titleDetail, setTitleDetail] = useState<groupType>();
  const [screenPermission, setScreenPermission] = useState<screenPermission>();
  const [metaTitle, setMetaTitle] = useState<string>("");
  const tabPanels: TabPanelProps[] = [
    {
      label: t("groupDetailTab.machineTab"),
      route: `/groups/${id}/machines`,
    },
    {
      label: t("groupDetailTab.inspectionFormTab"),
      route: `/groups/${id}/inspection-form`,
      disabled: !screenPermission?.allowCreateEditDeleteInspectionForm,
      className: `${
        screenPermission?.allowCreateEditDeleteInspectionForm
          ? ""
          : "d-none-imp"
      }`,
    },
    // TODO: deploy later
    // {
    //   label: t("groupDetailTab.reportTab"),
    //   route: "",
    //   disabled: true,
    // },
    // {
    //   label: t("groupDetailTab.memberTab"),
    //   route: "",
    //   disabled: true,
    // },
  ];
  const route = () => {
    let route = window.location.pathname;
    if (!route.includes("inspection-form")) {
      route = tabPanels[0].route;
    }
    return route;
  };
  const [value, setValue] = useState(route);

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const getGroupDetail = (id: string) => {
    return apiGroupDetail(id).then((e) => {
      const { data, meta } = e.data;
      setTitleDetail(data);
      setScreenPermission(meta.screenPermission);
      store.dispatch(saveScreenPermission(meta.screenPermission));
    });
  };

  useEffect(() => {
    setItem(StorageKey.IS_CONTINUOUS_LOADING, "true");
    getGroupDetail(`${id}`).finally(() => {
      dispatch(setIsRequestedGroupDetail(true));
    });
  }, [id]);

  useEffect(() => {
    if (!!titleDetail) {
      setMetaTitle(
        t(
          `metaInfo.${
            value.includes("inspection-form")
              ? "groupDetailInspection"
              : "groupDetailMachine"
          }`,
          {
            groupName: titleDetail?.groupName,
          }
        )
      );
    } else {
      setMetaTitle("Operation assist");
    }
  }, [value, titleDetail]);

  useEffect(() => {
    setValue(route());
  }, [route()]);

  useEffect(() => {
    return () => {
      dispatch(setIsRequestedGroupDetail(false));
      resetIsContinuousLoading();
    };
  }, []);

  return (
    <div className="group-detail-page">
      <Helmet title={metaTitle} />
      <Container>
        <div className="row">
          <div className="sp-p24">
            <NavigationItem title={t("groupList")} />
            {titleDetail && (
              <TitleDetail
                groupName={titleDetail?.groupName}
                location={titleDetail?.location}
                company={titleDetail?.companyName}
              />
            )}
          </div>
          <Box className="data-tabs" sx={{ width: "100%", marginTop: "24px" }}>
            {titleDetail && (
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  TabIndicatorProps={{ style: { background: "#140A9A" } }}
                  scrollButtons={false}
                >
                  {tabPanels.map((tab, index) => (
                    <Tab
                      key={index}
                      value={tab.route}
                      label={tab.label}
                      className={tab.className}
                      onClick={() => navigate(tab.route)}
                      disabled={tab.disabled}
                    />
                  ))}
                </Tabs>
              </Box>
            )}
            <div role="tabpanel">
              <Outlet />
            </div>
          </Box>
        </div>
      </Container>
    </div>
  );
}
