import { useState } from "react";
import { useCookies } from "react-cookie";
import { useToken } from "@app/hooks/useToken";
import { useMsal } from "@azure/msal-react";
import { StorageKey } from "@app/constants";
import { useForceLogout } from "./useForceLogout";
import { msalConfig } from "@app/api";
import { setItem } from "@app/helpers/storage.helper";

export const useAuth = () => {
  const { saveTokens, getTokens, clearTokens } = useToken();
  const { clearTimer, setTimer } = useForceLogout();
  const [isLogged, setIsLogged] = useState(getTokens().accessToken);
  const { instance } = useMsal();
  const [cookies] = useCookies([StorageKey.AUTH_ACCESS_TOKEN]);

  const login = (data: any) => {
    const { idToken, account } = data;
    saveTokens({
      token: idToken,
      accountId: account.homeAccountId,
    });
    setIsLogged(idToken);
    // handle to show usage data dialog
    setItem(StorageKey.IS_SHOWN_DATA_USAGE_POPUP, true);
    // set force logout timer for observe if user active or not
    setTimer();
  };

  const logout = async (logOutHead?: boolean) => {
    await instance.logoutRedirect({
      account: instance.getAccountByHomeId(cookies.tokens.accountId),
      onRedirectNavigate() {
        clearTokens();
        setIsLogged("");
        clearTimer();
      },
      postLogoutRedirectUri: logOutHead
        ? msalConfig.auth.redirectUri
        : window.location.pathname,
    });
  };

  return { login, logout, isLogged };
};
