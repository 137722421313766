import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {
  DataDialog,
  DataPagination,
  DataTable,
  DatePickerInput,
} from "@app/components/atoms";
import {
  IconLocationMachine,
  IconUser,
  IconInvalidUrl,
} from "@app/components/icons";
import { Box, Avatar, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Button } from "@app/components/atoms";
import { Machine, screenPermission, pageInfo } from "@app/types";
import {
  DATE_TIME_FORMAT,
  INVALID_URL,
  ReportType,
  ResponseStatus,
  CURRENT_PAGE,
  TOTAL_PAGE,
  LIMIT_PAGE,
  DATE_TIME_FORMAT_1,
  DEFAULT_START_DATE_FROM_CURRENT_DATE,
  DATE_TIME_FORMAT_2,
  ReportTypeDialog,
  MAINTENANCE_REPORTS,
  INCIDENT_REPORTS,
  INSPECTION,
  StorageKey,
} from "@app/constants";
import { getMachineReportInfo, getMachineSummary } from "@app/api";
import {
  isMobile,
  scrollToTop,
  resetIsContinuousLoading,
  formatNumberWithCommas,
  getCurrentUtc,
  setItem,
} from "@app/helpers";
import { RootState, setIdReport, setReportType } from "@app/store";
import { useStoreDispatch } from "@app/hooks";
import { useSelector } from "react-redux";

type SummaryFilterType = {
  label: string;
  filterCondition: string;
  count: number;
  key: string;
};

type MachineSummaryType = {
  reportCount: number;
  machineOperationReportCount: number;
  fuelMaintenanceReportCount: number;
  incidentReportCount: number;
  inspectionReportCount: number;
};

const dataCountSummaryFilter: SummaryFilterType[] = [
  {
    label: "all",
    filterCondition: "",
    count: 0,
    key: "reportCount",
  },
  {
    label: "abnormalityOrIssue",
    filterCondition: INCIDENT_REPORTS,
    count: 0,
    key: "incidentReportCount",
  },
  {
    label: "maintenanceReport",
    filterCondition: MAINTENANCE_REPORTS,
    count: 0,
    key: "maintenanceReportCount",
  },
  {
    label: "inspectionRecord",
    filterCondition: INSPECTION,
    count: 0,
    key: "inspectionReportCount",
  },
];

export function Report() {
  const { t } = useTranslation();
  const [dataCell, setDataCell] = useState<Machine>();
  const [dataSource, setDataSource] = useState<Machine[]>();
  const dispatch = useStoreDispatch();
  const [open, setOpen] = useState(false);
  const { groupId, machineId } = useParams();
  const [field, setField] = useState("");
  const [isInit, setIsInit] = useState(true);
  const [order, setOrder] = useState<string | undefined>("");
  const [pageInfo, setPageInfo] = useState({
    total: TOTAL_PAGE,
    page: CURRENT_PAGE,
    limit: LIMIT_PAGE,
  });
  const { isRequestedMachineInfo } = useSelector(
    (state: RootState) => state.inspection
  );
  const { isUpdatedMachineReportResponses } = useSelector(
    (state: RootState) => state.inspection
  );
  const [filterCondition, setFilterCondition] = useState<ReportTypeDialog | "">(
    ""
  );

  const [valueStartDate, setValueStartDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() - DEFAULT_START_DATE_FROM_CURRENT_DATE);
    return date;
  });
  const [dataCountSummaryTabList, setDataCountSummaryTabList] = useState(
    dataCountSummaryFilter
  );
  const valueEndDate = format(new Date(), DATE_TIME_FORMAT_1);
  // const [totalFuelRefueled, setTotalFuelRefueled] = useState(0);
  const [latestServiceMeter, setLatestServiceMeter] = useState("-");
  const [latestServiceMeterUpdatedAt, setLatestServiceMeterUpdatedAt] =
    useState("");

  const handleClickLocation = (column: any) => {
    const convertColumn = {
      machineName: column?.reportTitle,
      ...column,
    };
    setDataCell(convertColumn);
    setOpen(true);
  };

  const columns = [
    {
      key: "reportedAt",
      title: "tableMachineDetail.date",
      dataIndex: "reportedAt",
      align: "left",
      sorted: true,
      style: {
        minWidth: 165,
        fontSize: 14,
      },
      render: (value: any) => {
        return format(new Date(value), DATE_TIME_FORMAT);
      },
      isLabel: true,
    },
    {
      key: "reportType",
      title: "tableMachineDetail.type",
      dataIndex: "reportType",
      align: "left",
      sorted: true,
      forceRender: true,
      style: {
        minWidth: 258,
      },
      render: (_: string, record: any) => (
        <div className="flex align-items-center justify-content-start">
          <div className="item-name ml-5 fs-14 word-break txt-pre-wrap">
            {record?.reportTypeMessage}
          </div>
          {!!record.reportSubType && (
            <span
              className={`item-label ml-8 txt-nowrap ${
                record?.reportSubType === ReportType.INSPECTION
                  ? "label-inquire"
                  : "label-report"
              }`}
            >
              {record?.reportSubType === ReportType.INSPECTION
                ? t("labelInspection")
                : t("labelReport")}
            </span>
          )}
        </div>
      ),
      isLabel: true,
    },
    {
      key: "reportItem",
      title: "tableMachineDetail.item",
      dataIndex: "reportItem",
      align: "left",
      sorted: true,
      style: {
        minWidth: isMobile() ? 220 : 252,
        fontSize: 14,
      },
      render: (value: string, record: any) => (
        <span
          className={`${
            record?.reportType !== ReportType.INSPECTION && "txt-link"
          }`}
          onClick={(e) => handleClick(e, record)}
        >
          {value}
        </span>
      ),
      className: "txt-pre-wrap",
    },
    {
      key: "reportResponseStatus",
      title: "tableMachineDetail.status",
      dataIndex: "reportResponseStatus",
      align: "left",
      sorted: true,
      style: {
        paddingLeft: 12,
        paddingRight: 9,
        minWidth: 130,
      },
      render: (value: ResponseStatus) =>
        !!value && (
          <span
            className={`label-status fs-14 ${
              value === ResponseStatus.RESOLVED
                ? "label-status-active"
                : "label-status-disable"
            } flex align-items-center justify-content-start`}
          >
            <span className="status" />
            <span className="label">
              {value === ResponseStatus.RESOLVED ? t("resolved") : t("open")}
            </span>
          </span>
        ),
      isLabel: true,
    },
    {
      key: "serviceMeter",
      title: "tableMachineDetail.serviceMeter",
      dataIndex: "serviceMeter",
      align: "right",
      style: {
        minWidth: 101,
        paddingLeft: 0,
        paddingRight: 0,
        fontSize: 14,
      },
      tableHeadingClassName: "txt-pre",
      forceRender: true,
      isLabel: true,
      sorted: true,
      render: (value: number) =>
        value !== null && formatNumberWithCommas(value),
    },
    {
      key: "userPictureUrl",
      title: "",
      dataIndex: "userPictureUrl",
      align: "center",
      sorted: true,
      forceRender: true,
      render: (_: string, record: any) => (
        <div className="flex-centered justify-content-start">
          {!!record?.userPictureUrl ? (
            record?.userPictureUrl === INVALID_URL ? (
              <Box
                sx={{
                  width: 24,
                  height: 24,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconInvalidUrl />
              </Box>
            ) : (
              <Avatar
                sx={{
                  width: 24,
                  height: 24,
                }}
                alt="avatar-reporter"
                src={record?.userPictureUrl}
                imgProps={{
                  onError: () => {
                    const newRows = dataSource?.map((item) => {
                      if (item.id === record.id) {
                        item.userPictureUrl = INVALID_URL;
                      }
                      return item;
                    });
                    setDataSource(newRows);
                  },
                }}
              />
            )
          ) : (
            <Box
              sx={{
                width: 24,
                height: 24,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconUser />
            </Box>
          )}
        </div>
      ),
      className: "pr-4",
      tableHeadingClassName: "d-none-imp",
      style: {
        minWidth: 40,
      },
    },
    {
      key: "reporterName",
      title: "tableMachineDetail.reporter",
      dataIndex: "reporterName",
      align: "left",
      sorted: true,
      style: {
        minWidth: 100,
        fontSize: 14,
      },
      tableHeadingColspan: 2,
      className: "pl-0 txt-pre-wrap",
    },
    {
      key: "lat",
      title: "tableMachineDetail.location",
      dataIndex: "lat",
      style: {
        minWidth: 100,
      },
      align: "center",
      render: (_: string, record: any) =>
        record?.lat &&
        record?.lng && (
          <span
            className="flex-centered pointer"
            onClick={() => handleClickLocation(record)}
          >
            <IconLocationMachine />
          </span>
        ),
      isLabel: true,
    },
  ];

  const handleClick = (_: React.MouseEvent<HTMLElement>, target: any) => {
    dispatch(setIdReport(target.reportedId));
    dispatch(setReportType(target.reportType));
  };

  const convertDataRow = (data: Machine[]) => {
    return data.map((row: any) => ({
      ...row,
      id: row?.reportedId,
      reporterName: `${row?.surname} ${row?.givenName}`,
    }));
  };

  const handleChangePage = (page: number) => {
    setPageInfo({
      ...pageInfo,
      page,
    });
  };

  const handleSort = (orderBy: string | undefined, property: string) => {
    setField(property);
    setOrder(orderBy);
  };

  const handleClickfilterCondition = (
    _: React.MouseEvent<HTMLElement>,
    filterCondition: ReportTypeDialog | ""
  ) => {
    setPageInfo({ ...pageInfo, page: CURRENT_PAGE });
    setFilterCondition(filterCondition);
  };

  const initScrollToTop = () => {
    if (isInit) {
      scrollToTop();
      setIsInit(false);
    }
  };

  const handleChangeStartDate = (newValue: Date) => {
    resetData();
    setValueStartDate(newValue);
  };

  const resetData = () => {
    setFilterCondition("");
    setPageInfo({ ...pageInfo, page: CURRENT_PAGE });
  };

  useEffect(() => {
    !!groupId &&
      !!machineId &&
      getMachineReportInfo(
        groupId,
        machineId,
        format(valueStartDate, DATE_TIME_FORMAT_2),
        getCurrentUtc()
      ).then((db) => {
        // setTotalFuelRefueled(
        //   db?.totalFuelRefueled === null ? 0 : db?.totalFuelRefueled
        // );
        setLatestServiceMeter(
          db?.latestServiceMeter === null
            ? "-"
            : formatNumberWithCommas(db?.latestServiceMeter)
        );
        setLatestServiceMeterUpdatedAt(db?.latestServiceMeterUpdatedAt);
        const newDataMachineSummaryTabs = dataCountSummaryFilter.map((tab) => {
          tab.count = db[tab.key as keyof MachineSummaryType];
          return tab;
        });
        setDataCountSummaryTabList(newDataMachineSummaryTabs);
      });
  }, [valueStartDate, filterCondition, isUpdatedMachineReportResponses]);

  useEffect(() => {
    if (
      groupId &&
      machineId &&
      !!isRequestedMachineInfo &&
      !!isUpdatedMachineReportResponses
    ) {
      setItem(StorageKey.IS_CONTINUOUS_LOADING, "true");
      getMachineSummary(groupId, machineId, {
        limit: LIMIT_PAGE,
        page: pageInfo?.page,
        ...(!!filterCondition && { filter: filterCondition }),
        startDate: format(valueStartDate, DATE_TIME_FORMAT_2),
        ...(field && order && { orderBys: [{ field: field, order: order }] }),
        utc: getCurrentUtc(),
      }).then(
        (db: {
          data: Machine[];
          meta: {
            pageInfo: pageInfo;
            screenPermission: screenPermission;
          };
        }) => {
          setDataSource(convertDataRow(db?.data));
          setPageInfo(db?.meta.pageInfo);
          if (!db?.data.length) {
            initScrollToTop();
            resetIsContinuousLoading();
          }
        }
      );
    }
  }, [
    pageInfo?.page,
    field,
    order,
    isRequestedMachineInfo,
    isUpdatedMachineReportResponses,
    valueStartDate,
    filterCondition,
  ]);

  return (
    <div className="pb-40">
      <div className="wrap-filter-date mt-24 flex align-items-start justify-content-between">
        <div className="date-range flex-centered justify-content-start sp-p24">
          <DatePickerInput
            label={t("report.labelStartDate")}
            value={valueStartDate}
            onChange={(newValue) => {
              handleChangeStartDate(newValue);
            }}
            onAccept={(newValue) => {
              handleChangeStartDate(newValue);
            }}
          />
          <h3 className="wrap-end-date m-0 fs-24 txt-black-1 fw-700 lh-24">
            <span className="symbol">~</span>
            {valueEndDate}
          </h3>
        </div>
        <div className="wrap-right flex align-items-start justify-content-start pr-24 ml-25 sp-p24">
          {/* <div className="wrap-total-refueling">
            <div className="text-right ml-24 mr-24 content">
              <p className="txt-gray-1 fs-12 fw-600 m-0">
                {t("report.labelTotal")}
              </p>
              <p className="fs-18 fw-700 lh-24 txt-black-1 m-0 value-pc word-break">
                {totalFuelRefueled} ℓ
              </p>
              <p className="txt-gray-3 fs-12 fw-500 m-0">
                {format(valueStartDate, DATE_TIME_FORMAT_1)} ~ {valueEndDate}
              </p>
            </div>
            <p className="fs-18 fw-700 lh-24 txt-black-1 m-0 value-tl word-break">
              {totalFuelRefueled} ℓ
            </p>
          </div> */}
          <div className="wrap-service-meter">
            <div className="text-right ml-40 content">
              <p className="txt-gray-1 fs-12 fw-600 m-0">
                {t("report.labelSeviceMeter")}
              </p>
              <p className="fs-18 fw-700 lh-24 txt-black-1 m-0 value-pc word-break">
                {latestServiceMeter} h
              </p>
              {!!latestServiceMeterUpdatedAt && (
                <p className="txt-gray-3 fs-12 fw-500 m-0">
                  {format(latestServiceMeterUpdatedAt, DATE_TIME_FORMAT)}
                </p>
              )}
            </div>
            <p className="fs-18 fw-700 lh-24 txt-black-1 m-0 value-tl word-break">
              {latestServiceMeter} h
            </p>
          </div>
        </div>
      </div>
      {!!dataCountSummaryTabList[0].count && (
        <div className="toggle-btn-group sp-p24">
          <ToggleButtonGroup
            sx={{ overflow: "auto", marginTop: "24px" }}
            exclusive
            value={filterCondition}
          >
            {dataCountSummaryTabList.map((tab, index) => (
              <ToggleButton
                disabled={!tab.count}
                key={index}
                value={tab.filterCondition}
                className="flex-centered filter-tab"
                onClick={handleClickfilterCondition}
              >
                {t(tab.label)}
                <span style={{ marginLeft: "8px" }}>({tab.count})</span>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </div>
      )}
      <Box className="data-tabs" sx={{ width: "100%", marginTop: "24px" }}>
        <DataTable
          columns={columns}
          dataSource={dataSource}
          textEmptyData="noReport"
          onRequestSort={handleSort}
          didScrollTop={() => {
            initScrollToTop();
            resetIsContinuousLoading();
          }}
        />
        <DataPagination
          isHidden={!dataSource?.length}
          currentPage={pageInfo?.page}
          limitPage={pageInfo?.limit}
          totalPage={pageInfo?.total}
          className="mt-24 sp-p24"
          handleChangePage={handleChangePage}
        />
      </Box>
      {dataCell && (
        <DataDialog
          title={t("location")}
          open={open}
          setOpen={setOpen}
          className="dialog-location-report"
        >
          <iframe
            title="map-location"
            className="map-iframe"
            loading="lazy"
            src={`https://maps.google.com/maps?&q="+${dataCell?.lat}, ${dataCell?.lng}"&output=embed`}
          />
          <span className="fs-14 txt-gray-1 label-dialog">{t("dateTime")}</span>
          <p className="fs-16 txt-gray mt-4">
            {dataCell?.firstReportedAt &&
              format(new Date(dataCell?.firstReportedAt), DATE_TIME_FORMAT)}
          </p>
          <span className="fs-14 txt-gray-1 label-dialog">
            {t("tableMachineDetail.reporter")}
          </span>
          <div className="flex-centered justify-content-start mt-8">
            {dataCell?.userPictureUrl ? (
              dataCell.userPictureUrl === INVALID_URL ? (
                <Box
                  sx={{
                    width: 24,
                    height: 24,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <IconInvalidUrl />
                </Box>
              ) : (
                <Avatar
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                  alt="avatar-reporter"
                  src={dataCell?.userPictureUrl}
                  imgProps={{
                    onError: () => {
                      setDataCell({
                        ...dataCell,
                        userPictureUrl: INVALID_URL,
                      });
                    },
                  }}
                />
              )
            ) : (
              <Box
                sx={{
                  width: 24,
                  height: 24,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconUser />
              </Box>
            )}
            <span className="fs-16 txt-gray ml-5 word-break txt-pre-wrap">
              {dataCell?.reporterName}
            </span>
          </div>
          <div className="flex justify-content-end">
            <Button onClick={() => setOpen(false)} className="btn-white">
              {t("close")}
            </Button>
          </div>
        </DataDialog>
      )}
    </div>
  );
}
