export const DATE_TIME_FORMAT = "yyyy/MM/dd HH:mm";
export const DATE_TIME_FORMAT_1 = "yyyy/MM/dd";
export const DATE_TIME_FORMAT_2 = "yyyy-MM-dd";
export const DATE_TIME_FORMAT_YEAR_AND_MONTH = "yyyy/MM";
export const DATE_TIME_FORMAT_YEAR_AND_MONTH_1 = "yyyy-MM";
export const DATE_TIME_FORMAT_YEAR_AND_MONTH_2 = "yyyyMM";
export const DATE_TIME_FORMAT_JP = "MMMdo(EEE)";
export const MIN_DATE_CREATE_INSPECTION = "2024/01";
export const MIN_DATE_REPORT_SUMMARY = "2024/01/01";
export const DEFAULT_START_DATE_FROM_CURRENT_DATE = 30;
export const DEFAULT_MAX_YEAR = 50;
