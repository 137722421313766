import { createSlice } from "@reduxjs/toolkit";

interface groupsState {
  groupList: number;
}

const initialState = {
  groupList: 0,
} as groupsState;

export const groupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    setGroups: (state, action) => {
      state.groupList = action.payload.data;
    },
  },
});

export const { setGroups } = groupsSlice.actions;

export default groupsSlice.reducer;
