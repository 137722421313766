export function IconWarningFormEdit() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00033 4.32602L13.0203 12.9993H2.98033L8.00033 4.32602ZM8.00033 1.66602L0.666992 14.3327H15.3337L8.00033 1.66602ZM8.66699 10.9993H7.33366V12.3327H8.66699V10.9993ZM8.66699 6.99935H7.33366V9.66602H8.66699V6.99935Z"
        fill="#DA9309"
      />
    </svg>
  );
}
