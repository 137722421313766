import { StorageKey } from "@app/constants";
import { useCookies } from "react-cookie";

type Props = {
  children: React.ReactNode;
};

export function PrivateRoute({ children }: Props): any {
  const [cookies] = useCookies([StorageKey.AUTH_ACCESS_TOKEN]);

  if (!!cookies?.tokens) {
    return children;
  }

  return null;
}
