import React, { useState, useRef, useEffect } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  debounce,
} from "@mui/material";
import { IconSort, IconBox } from "@app/components/icons";
import { useTranslation } from "react-i18next";
import { isMobile, resetScroll } from "@app/helpers";
import { DEBOUNCE_TIME } from "@app/constants";

const TABLE_MAX_HEIGHT = 450;

const TABLE_MIN_WIDTH = 864;

type Order = "asc" | "desc" | undefined;

type EnhancedTableHeadProps = {
  order?: "asc" | "desc" | undefined;
  orderBy?: string;
  onRequestSort?: (event: React.MouseEvent<unknown>, property: string) => void;
  columns?: any[];
};

type DataTableProps = {
  columns?: any[];
  dataSource?: any[];
  textEmptyData: string;
  onRequestSort: (order: string | undefined, property: string) => void;
  onCellClick?: (data: any) => void;
  isLabel?: boolean;
  didScrollTop?: () => void;
};

// toggle the sort order
// asc -> desc -> undefined
const orderToggle = {
  asc: "desc",
  desc: undefined,
};

function EnhancedTableHead({
  order,
  orderBy = "name",
  onRequestSort = (_event: React.MouseEvent<unknown>, _property: string) => {},
  columns = [],
}: EnhancedTableHeadProps) {
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        {columns.map((column: any) => (
          <TableCell
            sx={{
              padding: "6.625px 12px 6.625px",
              whiteSpace: column.whiteSpace,
            }}
            style={{ ...column.style }}
            key={column.key}
            align={column.align}
            sortDirection={orderBy === column.dataIndex ? order : false}
            width={column.width}
            className={column.tableHeadingClassName}
            colSpan={column.tableHeadingColspan}
          >
            <TableSortLabel
              sx={{
                flexDirection: "initial",
                gap: "4px",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#1b232a",
                fontWeight: 700,
                letterSpacing: "0.15px",
                cursor: column.sorted ? "pointer" : "initial",
              }}
              hideSortIcon={!column.sorted}
              direction={orderBy === column.dataIndex ? order : "asc"}
              onClick={column.sorted && createSortHandler(column.dataIndex)}
              IconComponent={
                orderBy === column.dataIndex && order !== undefined
                  ? undefined
                  : IconSort
              }
            >
              {t(column.title)}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export function DataTable({
  columns,
  dataSource,
  textEmptyData,
  onRequestSort,
  onCellClick,
  didScrollTop,
}: DataTableProps) {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<any>("name");
  const { t } = useTranslation();
  const tableContainerRef = useRef<HTMLTableElement>(null);

  const handleRequestSort = debounce(
    (_: React.MouseEvent<unknown>, property: string) => {
      // set sort order
      const sortOrder = !!order ? orderToggle[order] : "asc";
      // check condition if user click same header or not, then apply proper value
      const currentOrder = (orderBy === property ? sortOrder : "asc") as Order;
      const upperCaseOrder = currentOrder?.toUpperCase(); // format before sending to API
      setOrder(currentOrder);
      setOrderBy(property);
      onRequestSort(upperCaseOrder, property);
    },
    DEBOUNCE_TIME
  );

  useEffect(() => {
    !!dataSource?.length &&
      tableContainerRef.current &&
      resetScroll(tableContainerRef.current, () => {
        didScrollTop?.();
      });
  }, [dataSource]);

  return (
    <>
      {dataSource &&
        (!!dataSource?.length ? (
          <TableContainer
            ref={tableContainerRef}
            component={Paper}
            style={{
              maxHeight: TABLE_MAX_HEIGHT,
              borderRadius: isMobile() ? "0" : "8px",
            }}
          >
            <Table
              className="data-table"
              sx={{ minWidth: TABLE_MIN_WIDTH, maxHeight: TABLE_MAX_HEIGHT }} // change to maxHeight instead of height to make a scalable table with a few records.
              size="small"
              stickyHeader
              aria-labelledby="tableTitle"
            >
              <EnhancedTableHead
                columns={columns}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {dataSource.map((data: any) => {
                  return (
                    <TableRow hover key={data.id}>
                      {columns?.map((column: any) => (
                        <TableCell
                          sx={{
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.5px",
                            padding: "8px 12px",
                          }}
                          style={{ ...column.style }}
                          key={column.key}
                          width={column.width}
                          align={column.align}
                          className={column.className}
                          onClick={() => {
                            if (column.clickedCell) {
                              onCellClick?.(data);
                            }
                          }}
                        >
                          {(!!(data as any)[column.dataIndex] ||
                            column.forceRender) &&
                            (column.render
                              ? column.render(
                                  (data as any)[column.dataIndex],
                                  data
                                )
                              : (data as any)[column.dataIndex])}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div className="empty-table bg-white">
            <div className="text-center">
              <IconBox />
              <p className="text-content">{t(textEmptyData)}</p>
            </div>
          </div>
        ))}
    </>
  );
}
