import qrIconSrc from "@app/components/icons/IconAppleQR/Apple.jpg";

export function IconAppleQR() {
  return (
    <img
      style={{ width: "100px", height: "100px" }}
      alt="apple qr icon"
      src={qrIconSrc}
      width="100%"
      height="100%"
    />
  );
}
