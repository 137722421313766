export function IconCompany() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.75 5.75V2.75H2.25V16.25H17.25V5.75H9.75ZM8.25 14.75H3.75V13.25H8.25V14.75ZM8.25 11.75H3.75V10.25H8.25V11.75ZM8.25 8.75H3.75V7.25H8.25V8.75ZM8.25 5.75H3.75V4.25H8.25V5.75ZM15.75 14.75H9.75V7.25H15.75V14.75ZM14.25 8.75H11.25V10.25H14.25V8.75ZM14.25 11.75H11.25V13.25H14.25V11.75Z"
        fill="#4C5459"
      />
    </svg>
  );
}
