import { setItem } from "@app/helpers";
import { MAX_WIDTH_MOBILE_SCREEN, StorageKey, DEFAULT_MAX_YEAR } from "@app/constants";
import { format, sub } from "date-fns";
import { setIsLoading, store } from "@app/store";

/**
 * Check if a string looks like an external URL
 */
export const isURL = (str: string) => {
  return /http|www/.test(str);
};

/**
 * A promise to delay an async function
 * @param ms how many milliseconds to wait
 */
export const delay = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export const getInitials = (name: string, maxChar: number) => {
  return name
    .split(/\s/)
    .map((word) => word[0])
    .join("")
    .substr(0, maxChar)
    .toUpperCase();
};

/**
 * Scroll to top of screen smoothly,
 * or fallback to instant scroll to top
 */
export const scrollToTop = () => {
  try {
    // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  } catch (error) {
    // fallback for older browsers
    window.scrollTo(0, 0);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  }
};

/**
 * Native scrollTo with callback
 * @param offset - offset to scroll to
 * @param callback - callback function
 */
export const scrollTo = (el: Element, callback = () => {}) => {
  const offset = el.scrollHeight;
  const onScroll = () => {
    if (offset - el.clientHeight - el.scrollTop <= 1) {
      el.removeEventListener("scroll", onScroll);
      callback();
    }
  };
  el.addEventListener("scroll", onScroll);
  onScroll();
  try {
    el.scrollTo({
      top: offset,
      left: 0,
      behavior: "smooth",
    });
  } catch (error) {
    // fallback for older browsers
    el.scrollTo(offset, 0);
  }
};

/**
 * Reset scroll with callback
 * @param offset - offset to scroll to
 * @param callback - callback function
 */
export const resetScroll = (el: Element, callback = () => {}) => {
  const onScroll = () => {
    if (el.scrollTop === 0) {
      el.removeEventListener("scroll", onScroll);
      callback();
    }
  };
  el.addEventListener("scroll", onScroll);
  onScroll();
  try {
    el.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  } catch (error) {
    // fallback for older browsers
    el.scrollTo(0, 0);
  }
};

export const generateId = () => {
  const randomId = [
    Math.floor(Math.random() * 4000),
    Math.floor(Math.random() * 4000),
    Math.floor(Math.random() * 4000),
  ];
  return randomId.join("-");
};

export const isMobile = () => {
  return window.innerWidth < MAX_WIDTH_MOBILE_SCREEN;
};

export const isMobileDevice =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

export const isSafari = /^((?!chrome|android).)*safari/i.test(
  navigator.userAgent
);

export const formatNumberWithCommas = (input: number | string, unit?: string) => {
  const [integerPart, decimalPart = "0"] = input.toString().split(".");
  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const formattedNumber = `${formattedIntegerPart}.${decimalPart}`;
  return unit ? `${formattedNumber} ${unit}` : formattedNumber;
};

export const formatTime = (time: Date, typeFormat: string) => {
  return format(
    sub(time, {
      hours: new Date().getTimezoneOffset() / -60,
    }),
    typeFormat
  );
};

export const iOSversion = () => {
  if (/iP(hone|od|ad)/.test(navigator.userAgent)) {
    const v = navigator.userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
    if (v) {
      return parseInt(v[1], 10);
    }
  }
  return -1; // undefined version or not iOS
};

export const resetIsContinuousLoading = () => {
  setItem(StorageKey.IS_CONTINUOUS_LOADING, "");
  store.dispatch(setIsLoading(false));
};

export const getMaxDate = () => {
  const currentDate = new Date();
  return new Date(currentDate.setFullYear(currentDate.getFullYear() + DEFAULT_MAX_YEAR));
}
