export function IconError() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2.5C6.48 2.5 2 6.98 2 12.5C2 18.02 6.48 22.5 12 22.5C17.52 22.5 22 18.02 22 12.5C22 6.98 17.52 2.5 12 2.5ZM13 17.5H11V15.5H13V17.5ZM13 13.5H11V7.5H13V13.5Z"
        fill="#DA093B"
      />
    </svg>
  );
}
