export enum ItemModalEnum {
  ADD = "add",
  EDIT = "edit",
}
export const ItemTypes = {
  CARD: "card",
};
export enum DevicePlatform {
  WEB = "WEB",
}
export enum DialogTypes {
  ERROR = "error",
  SUCCESS = "success",
  CONFIRM = "confirm",
  WARNING = "warning",
}
export enum ItemCodeEnum {
  ODOMETER = "ODOMETER",
  SERVICE_METER = "SERVICE_METER",
}
export enum ResultType {
  NUMERIC = "NUMERIC",
  OK_OR_ANOMARY = "OK_OR_ANOMARY",
  ODOMETER = "ODOMETER",
}
export enum SelectMachineEnum {
  MACHINE_NAME = "machineName",
}
export enum CurrentStatusPostEnum {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
}
export enum ItemResultType {
  OK = "OK",
  ANOMARY = "ANOMARY",
}
export enum ItemInspectionEnum {
  STAFF_NAME = "STAFF_NAME",
  LOCATION = "LOCATION",
}
export enum SelectLanguage {
  JA = "ja",
  EN = "en",
}
