export function IconLogo() {
  return (
    <svg
      width="219"
      height="25"
      viewBox="0 0 219 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100.294 12.9136C100.294 10.6279 100.949 8.84446 102.259 7.56312C103.587 6.28178 105.416 5.64111 107.744 5.64111C110.091 5.64111 111.902 6.26447 113.175 7.51117C114.449 8.75788 115.086 10.5241 115.086 12.8097C115.086 15.1126 114.431 16.9048 113.121 18.1861C111.829 19.4675 110 20.1081 107.635 20.1081C105.27 20.1081 103.451 19.4848 102.177 18.2381C100.922 16.9913 100.294 15.2165 100.294 12.9136ZM105.234 12.8616C105.234 13.9698 105.434 14.8269 105.834 15.433C106.234 16.039 106.853 16.342 107.69 16.342C108.545 16.342 109.163 16.039 109.546 15.433C109.946 14.8269 110.146 13.9698 110.146 12.8616C110.146 11.7361 109.946 10.879 109.546 10.2903C109.163 9.68426 108.545 9.38124 107.69 9.38124C106.853 9.38124 106.234 9.68426 105.834 10.2903C105.434 10.879 105.234 11.7361 105.234 12.8616Z"
        fill="#140A9A"
      />
      <path
        d="M210.306 14.4786V9.59561H208.013V5.93341H210.306V2.34912H215.218V5.93341H218.384V9.59561H215.218V14.4266C215.218 15.5002 215.755 16.0369 216.828 16.0369C217.083 16.0369 217.328 16.0196 217.565 15.985C217.802 15.9504 218.038 15.9071 218.275 15.8551L218.902 19.2576C218.338 19.4481 217.783 19.5866 217.238 19.6732C216.692 19.7771 216.073 19.829 215.382 19.829C213.508 19.829 212.189 19.3961 211.425 18.5304C210.679 17.6646 210.306 16.314 210.306 14.4786Z"
        fill="#140A9A"
      />
      <path
        d="M202.146 15.5951C202.146 15.2835 202.036 15.0237 201.818 14.8159C201.6 14.6082 201.2 14.461 200.617 14.3744L199.062 14.1147C197.188 13.7857 195.823 13.2922 194.968 12.6342C194.113 11.9762 193.685 11.0325 193.685 9.80314C193.685 8.45254 194.259 7.40495 195.405 6.66039C196.551 5.89852 198.116 5.51758 200.099 5.51758C202.1 5.51758 203.628 5.87254 204.684 6.58247C205.739 7.2924 206.394 8.2967 206.649 9.59535L202.064 10.0109C201.936 9.54341 201.718 9.18844 201.409 8.94602C201.118 8.70361 200.672 8.5824 200.071 8.5824C199.435 8.5824 198.98 8.68629 198.707 8.89408C198.434 9.10186 198.298 9.37025 198.298 9.69924C198.298 10.0109 198.416 10.262 198.652 10.4525C198.889 10.6256 199.316 10.7728 199.935 10.894L201.572 11.1797C202.901 11.4221 203.947 11.7251 204.711 12.0888C205.475 12.4524 206.021 12.9112 206.348 13.4653C206.676 14.0194 206.84 14.7207 206.84 15.5692C206.84 16.9717 206.294 18.0626 205.202 18.8418C204.129 19.6036 202.519 19.9846 200.372 19.9846C198.243 19.9846 196.569 19.6036 195.35 18.8418C194.149 18.0799 193.485 16.8938 193.358 15.2835H198.025C198.116 15.8202 198.343 16.2012 198.707 16.4263C199.089 16.6341 199.617 16.7379 200.29 16.7379C200.963 16.7379 201.436 16.6427 201.709 16.4522C202 16.2618 202.146 15.9761 202.146 15.5951Z"
        fill="#140A9A"
      />
      <path
        d="M179.78 15.5951C179.78 15.2835 179.671 15.0237 179.452 14.8159C179.234 14.6082 178.834 14.461 178.252 14.3744L176.696 14.1147C174.822 13.7857 173.457 13.2922 172.602 12.6342C171.747 11.9762 171.32 11.0325 171.32 9.80314C171.32 8.45254 171.893 7.40495 173.039 6.66039C174.185 5.89852 175.75 5.51758 177.733 5.51758C179.734 5.51758 181.263 5.87254 182.318 6.58247C183.373 7.2924 184.028 8.2967 184.283 9.59535L179.698 10.0109C179.571 9.54341 179.352 9.18844 179.043 8.94602C178.752 8.70361 178.306 8.5824 177.706 8.5824C177.069 8.5824 176.614 8.68629 176.341 8.89408C176.068 9.10186 175.932 9.37025 175.932 9.69924C175.932 10.0109 176.05 10.262 176.287 10.4525C176.523 10.6256 176.951 10.7728 177.569 10.894L179.207 11.1797C180.535 11.4221 181.581 11.7251 182.345 12.0888C183.109 12.4524 183.655 12.9112 183.983 13.4653C184.31 14.0194 184.474 14.7207 184.474 15.5692C184.474 16.9717 183.928 18.0626 182.836 18.8418C181.763 19.6036 180.153 19.9846 178.006 19.9846C175.877 19.9846 174.203 19.6036 172.984 18.8418C171.784 18.0799 171.12 16.8938 170.992 15.2835H175.659C175.75 15.8202 175.977 16.2012 176.341 16.4263C176.723 16.6341 177.251 16.7379 177.924 16.7379C178.597 16.7379 179.07 16.6427 179.343 16.4522C179.634 16.2618 179.78 15.9761 179.78 15.5951Z"
        fill="#140A9A"
      />
      <path
        d="M164.988 15.5951C164.988 15.2835 164.879 15.0237 164.661 14.8159C164.443 14.6082 164.042 14.461 163.46 14.3744L161.904 14.1147C160.031 13.7857 158.666 13.2922 157.811 12.6342C156.956 11.9762 156.528 11.0325 156.528 9.80314C156.528 8.45254 157.101 7.40495 158.248 6.66039C159.394 5.89852 160.958 5.51758 162.942 5.51758C164.943 5.51758 166.471 5.87254 167.526 6.58247C168.582 7.2924 169.237 8.2967 169.491 9.59535L164.906 10.0109C164.779 9.54341 164.561 9.18844 164.252 8.94602C163.96 8.70361 163.515 8.5824 162.914 8.5824C162.277 8.5824 161.823 8.68629 161.55 8.89408C161.277 9.10186 161.14 9.37025 161.14 9.69924C161.14 10.0109 161.259 10.262 161.495 10.4525C161.732 10.6256 162.159 10.7728 162.778 10.894L164.415 11.1797C165.743 11.4221 166.79 11.7251 167.554 12.0888C168.318 12.4524 168.864 12.9112 169.191 13.4653C169.519 14.0194 169.682 14.7207 169.682 15.5692C169.682 16.9717 169.137 18.0626 168.045 18.8418C166.971 19.6036 165.361 19.9846 163.214 19.9846C161.086 19.9846 159.412 19.6036 158.193 18.8418C156.992 18.0799 156.328 16.8938 156.201 15.2835H160.867C160.958 15.8202 161.186 16.2012 161.55 16.4263C161.932 16.6341 162.459 16.7379 163.133 16.7379C163.806 16.7379 164.279 16.6427 164.552 16.4522C164.843 16.2618 164.988 15.9761 164.988 15.5951Z"
        fill="#140A9A"
      />
      <path
        d="M140.56 15.8029C140.56 14.6428 140.878 13.7337 141.515 13.0757C142.17 12.4004 143.044 11.9156 144.135 11.6213C145.227 11.3269 146.428 11.1624 147.738 11.1278L149.593 11.0758V10.6083C149.593 9.933 149.448 9.44817 149.157 9.15381C148.866 8.84213 148.402 8.68629 147.765 8.68629C147.164 8.68629 146.701 8.8075 146.373 9.04992C146.064 9.27502 145.864 9.61267 145.773 10.0629L141.379 9.72522C141.87 6.92012 144.053 5.51758 147.929 5.51758C150.112 5.51758 151.713 5.98509 152.732 6.92012C153.751 7.83784 154.26 9.23173 154.26 11.1018V19.569H149.784V18.1145C149.202 18.7032 148.556 19.1534 147.847 19.4651C147.155 19.7595 146.346 19.9067 145.418 19.9067C143.962 19.9067 142.789 19.543 141.897 18.8158C141.006 18.0886 140.56 17.0843 140.56 15.8029ZM145.145 15.4912C145.145 16.357 145.7 16.7899 146.81 16.7899C147.665 16.7899 148.338 16.5648 148.829 16.1146C149.339 15.6644 149.593 15.067 149.593 14.3225V13.6991L147.901 13.777C146.901 13.829 146.191 13.9935 145.773 14.2705C145.354 14.5476 145.145 14.9545 145.145 15.4912Z"
        fill="#140A9A"
      />
      <path
        d="M117.125 5.93315H121.901V7.49153C122.592 6.71234 123.293 6.19288 124.002 5.93315C124.712 5.6561 125.44 5.51758 126.186 5.51758C127.714 5.51758 128.887 5.9418 129.706 6.79026C130.525 7.63871 130.934 8.84213 130.934 10.4005V19.569H126.022V11.2576C126.022 10.5996 125.885 10.1235 125.612 9.82911C125.358 9.51743 124.957 9.36159 124.412 9.36159C123.629 9.36159 123.02 9.61267 122.583 10.1148C122.219 10.4784 122.037 10.9719 122.037 11.5953V19.569H117.125V5.93315Z"
        fill="#140A9A"
      />
      <path
        d="M81.8144 14.4786V9.59561H79.522V5.93341H81.8144V2.34912H86.7268V5.93341H89.8925V9.59561H86.7268V14.4266C86.7268 15.5002 87.2635 16.0369 88.3369 16.0369C88.5916 16.0369 88.8373 16.0196 89.0738 15.985C89.3103 15.9504 89.5468 15.9071 89.7834 15.8551L90.411 19.2576C89.847 19.4481 89.2921 19.5866 88.7463 19.6732C88.2005 19.7771 87.5819 19.829 86.8905 19.829C85.0165 19.829 83.6975 19.3961 82.9333 18.5304C82.1874 17.6646 81.8144 16.314 81.8144 14.4786Z"
        fill="#140A9A"
      />
      <path
        d="M64.1885 15.8029C64.1885 14.6428 64.5069 13.7337 65.1437 13.0757C65.7986 12.4004 66.6719 11.9156 67.7636 11.6213C68.8552 11.3269 70.056 11.1624 71.366 11.1278L73.2218 11.0758V10.6083C73.2218 9.933 73.0762 9.44817 72.7851 9.15381C72.494 8.84213 72.0301 8.68629 71.3933 8.68629C70.7929 8.68629 70.3289 8.8075 70.0014 9.04992C69.6921 9.27502 69.492 9.61267 69.401 10.0629L65.0072 9.72522C65.4984 6.92012 67.6817 5.51758 71.557 5.51758C73.7403 5.51758 75.3414 5.98509 76.3602 6.92012C77.3791 7.83784 77.8885 9.23173 77.8885 11.1018V19.569H73.4128V18.1145C72.8306 18.7032 72.1847 19.1534 71.4751 19.4651C70.7838 19.7595 69.9741 19.9067 69.0463 19.9067C67.5907 19.9067 66.4172 19.543 65.5257 18.8158C64.6342 18.0886 64.1885 17.0843 64.1885 15.8029ZM68.7733 15.4912C68.7733 16.357 69.3283 16.7899 70.4381 16.7899C71.2932 16.7899 71.9664 16.5648 72.4576 16.1146C72.967 15.6644 73.2218 15.067 73.2218 14.3225V13.6991L71.5297 13.777C70.5291 13.829 69.8195 13.9935 69.401 14.2705C68.9826 14.5476 68.7733 14.9545 68.7733 15.4912Z"
        fill="#140A9A"
      />
      <path
        d="M53.7485 5.93346H58.5244V7.59574C59.5251 6.33172 60.8624 5.69971 62.5362 5.69971C62.7909 5.69971 63.0275 5.71702 63.2458 5.75165C63.4823 5.76897 63.7097 5.79494 63.9281 5.82957L63.4095 9.77748C62.9001 9.67359 62.3907 9.62164 61.8812 9.62164C60.9897 9.62164 60.2529 9.82077 59.6707 10.219C58.9975 10.6865 58.6609 11.4051 58.6609 12.3748V19.5693H53.7485V5.93346Z"
        fill="#140A9A"
      />
      <path
        d="M37.396 13.0112C37.396 10.6736 38.0419 8.85552 39.3337 7.55687C40.6436 6.25821 42.4084 5.60889 44.6281 5.60889C46.8477 5.60889 48.5489 6.23224 49.7315 7.47895C50.9323 8.70834 51.5327 10.3966 51.5327 12.5437V13.8683H42.09C42.181 14.9072 42.4175 15.6605 42.7996 16.128C43.1999 16.5782 43.8003 16.8033 44.6008 16.8033C45.2922 16.8033 45.8107 16.6821 46.1564 16.4397C46.5203 16.1799 46.7659 15.8163 46.8932 15.3488H51.4235C51.1324 16.8899 50.4229 18.0673 49.2948 18.8811C48.185 19.6776 46.6476 20.0759 44.6827 20.0759C42.3357 20.0759 40.5345 19.4612 39.2791 18.2318C38.0237 17.0024 37.396 15.2622 37.396 13.0112ZM47.0024 11.4009C46.9478 10.5351 46.7659 9.89444 46.4566 9.47888C46.1473 9.04599 45.5469 8.82955 44.6554 8.82955C43.8367 8.82955 43.2363 9.03733 42.8542 9.4529C42.4721 9.86847 42.2265 10.5178 42.1173 11.4009H47.0024Z"
        fill="#140A9A"
      />
      <path
        d="M21.3242 5.93315H26.0455V7.41361C27.0826 6.14959 28.4653 5.51758 30.1938 5.51758C32.0677 5.51758 33.496 6.14093 34.4784 7.38764C35.4609 8.61703 35.9521 10.3399 35.9521 12.5563C35.9521 14.8592 35.4154 16.6773 34.342 18.0106C33.2685 19.3266 31.8039 19.9846 29.9481 19.9846C29.2022 19.9846 28.5017 19.8461 27.8467 19.569C27.21 19.292 26.6732 18.8764 26.2366 18.3223V24.6078H21.3242V5.93315ZM31.0125 12.7381C31.0125 11.5087 30.7942 10.6256 30.3575 10.0888C29.9209 9.53475 29.3477 9.2577 28.6382 9.2577C27.7831 9.2577 27.1645 9.56072 26.7824 10.1668C26.4185 10.7555 26.2366 11.526 26.2366 12.4784V12.9719C26.2366 13.9761 26.4185 14.7727 26.7824 15.3614C27.1463 15.9501 27.7467 16.2445 28.5836 16.2445C29.3659 16.2445 29.9663 15.9674 30.3848 15.4133C30.8033 14.8592 31.0125 13.9675 31.0125 12.7381Z"
        fill="#140A9A"
      />
      <path
        d="M0 10.4007C0 8.3229 0.382073 6.55673 1.14622 5.10223C1.92856 3.64774 3.03839 2.53955 4.47571 1.77768C5.93123 0.998484 7.65965 0.608887 9.66098 0.608887C12.6448 0.608887 14.9736 1.44868 16.6475 3.12828C18.3395 4.79056 19.1855 7.17142 19.1855 10.2709C19.1855 12.3487 18.8034 14.1236 18.0393 15.5954C17.2752 17.0499 16.1653 18.1667 14.7098 18.9459C13.2725 19.7078 11.5441 20.0887 9.52453 20.0887C6.55892 20.0887 4.23009 19.2489 2.53806 17.5693C0.846018 15.8897 0 13.5002 0 10.4007ZM5.4036 10.3488C5.4036 12.115 5.74929 13.4742 6.44066 14.4266C7.15022 15.3616 8.19637 15.8291 9.57911 15.8291C10.98 15.8291 12.0262 15.3616 12.7176 14.4266C13.4271 13.4742 13.7819 12.115 13.7819 10.3488C13.7819 8.59994 13.4271 7.24934 12.7176 6.29699C12.0262 5.34465 10.98 4.86847 9.57911 4.86847C8.19637 4.86847 7.15022 5.34465 6.44066 6.29699C5.74929 7.24934 5.4036 8.59994 5.4036 10.3488Z"
        fill="#140A9A"
      />
      <path
        d="M93.1304 5.9464H98.0427V19.5823H93.1304V5.9464ZM93.1304 0.933594H98.0427V4.3101H93.1304V0.933594Z"
        fill="#140A9A"
      />
      <path
        d="M186.431 5.9464H191.344V19.5823H186.431V5.9464ZM186.431 0.933594H191.344V4.3101H186.431V0.933594Z"
        fill="#140A9A"
      />
    </svg>
  );
}
