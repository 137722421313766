export function IconDeleteRow() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 9.75V19.75H8V9.75H16ZM14.5 3.75H9.5L8.5 4.75H5V6.75H19V4.75H15.5L14.5 3.75ZM18 7.75H6V19.75C6 20.85 6.9 21.75 8 21.75H16C17.1 21.75 18 20.85 18 19.75V7.75Z"
        fill="#DA093B"
      />
    </svg>
  );
}
