import { useState } from "react";
import { useDispatch } from "react-redux";
import { resetError } from "@app/store";
import { useTranslation } from "react-i18next";
import { Button, DataDialog } from "@app/components/atoms";

type ErrorPopupProps = {
  title: string;
  content: string;
  btn: {
    title: string;
    style: string;
  };
  onClick?: Function;
};

export function ErrorPopup({ error }: { error: ErrorPopupProps }) {
  const { t } = useTranslation();
  const { title, content, btn, onClick } = error;
  const [show, setShow] = useState(true);
  const dispatch = useDispatch();

  const handleOnClick = () => {
    setShow(false);
    dispatch(resetError());
    if (onClick) {
      onClick();
    }
  };

  if (!show) {
    return null;
  }

  return (
    <DataDialog
      className="error-popup"
      title={t(`${title}`)}
      open={show}
      setOpen={setShow}
    >
      <p className="txt-gray-1 fs-16">{t(`${content}`)}</p>
      <div className="wrapper-btn flex-centered justify-content-end mt-24">
        <Button className={`btn-primary ${btn.style}`} onClick={handleOnClick}>
          {t(`${btn.title}`)}
        </Button>
      </div>
    </DataDialog>
  );
}
