import { HelmetProvider, Helmet as HelmetPage } from "react-helmet-async";

export function Helmet({ title = "" }) {
  return (
    <HelmetProvider>
      <HelmetPage>
        <meta charSet="utf-8" />
        <title>{title}</title>
      </HelmetPage>
    </HelmetProvider>
  );
}
