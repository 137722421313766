import {
  ArrowForwardIos,
  ArrowBackIos,
  PictureAsPdfOutlined,
} from "@mui/icons-material";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Button, DataTooltip } from "@app/components/atoms";
import { useTranslation } from "react-i18next";
import { IconBox } from "@app/components/icons";
import { addMonths, format, getDaysInMonth, getMonth, getYear } from "date-fns";
import {
  getInspectionForms,
  getInspections,
  getInspectionsPDF,
} from "@app/api";
import { useParams } from "react-router-dom";
import { CustomInspectionFormsType } from "@app/types";
import {
  DATE_TIME_FORMAT_1,
  DATE_TIME_FORMAT_YEAR_AND_MONTH,
  DATE_TIME_FORMAT_YEAR_AND_MONTH_1,
  ItemInspectionEnum,
  MIN_DATE_CREATE_INSPECTION,
} from "@app/constants";
import { TableInspectionFormTab } from "@app/pages/Features";
import { useSelector } from "react-redux";
import { RootState } from "@app/store";
import {
  formatTime,
  isMobileDevice,
  scrollToTop,
  resetIsContinuousLoading,
  isSafari,
  isMobile,
  getCurrentUtc,
} from "@app/helpers";

export function InspectionHistory() {
  const { t } = useTranslation();
  const { groupId, machineId } = useParams();
  const [dateInspection, setDateInspection] = useState(new Date());
  const [dbTemplateList, setDbTemplateList] =
    useState<CustomInspectionFormsType[]>();
  const [inspectionFormName, setInspectionFormName] = useState<string>();
  const [displayData, setDisplayData] = useState([]);
  const { isRequestedMachineInfo } = useSelector(
    (state: RootState) => state.inspection
  );
  const [isRequestInspections, setIsRequestInspections] = useState(false);
  const [isInit, setIsInit] = useState(true);
  const [inspectionFormNameStatus, setInspectionFormNameStatus] =
    useState(false);

  const handleChange = (event: SelectChangeEvent) => {
    setInspectionFormName(event.target.value);
  };

  const handleChangeMonth = (value: number) => {
    setDateInspection(addMonths(dateInspection, value));
  };

  const handleEditForm = () => {
    // TODO: Implement it on next sprint
  };

  const handleOutputPdf = () => {
    !!groupId &&
      !!machineId &&
      !!inspectionFormName &&
      getInspectionsPDF(groupId, machineId, {
        monthYear: dateInspection,
        customInspectionFormId: inspectionFormName,
        utc: getCurrentUtc(),
      });
  };

  const handleTouchStart = (event: any) => {
    const { target } = event;
    const selectPaperProps = document.querySelector(".select-options-js");
    if (!selectPaperProps?.contains(target)) {
      setInspectionFormNameStatus(false);
    }
  };

  const initScrollToTop = () => {
    if (isInit) {
      scrollToTop();
      setIsInit(false);
    }
  };

  useEffect(() => {
    !!isRequestedMachineInfo &&
      !!groupId &&
      !!machineId &&
      getInspectionForms(groupId, machineId)
        .then((db) => {
          setDbTemplateList(db);
          setInspectionFormName(db[0]?.customInspectionFormId);
          if (!db.length) {
            initScrollToTop();
          }
        })
        .finally(() => {
          setIsRequestInspections(true);
        });
  }, [isRequestedMachineInfo]);

  useEffect(() => {
    if (
      !!isRequestInspections &&
      !!groupId &&
      !!machineId &&
      !!inspectionFormName
    ) {
      getInspections(groupId, machineId, {
        monthYear: format(
          new Date(dateInspection),
          DATE_TIME_FORMAT_YEAR_AND_MONTH_1
        ),
        utc: getCurrentUtc(),
        customInspectionFormId: inspectionFormName,
      })
        .then((db) => {
          let result = [
            ...(db?.customInspectionItems || {}),
            ...[
              {
                name: t("reporter"),
                inspectionItemName: ItemInspectionEnum.STAFF_NAME,
              },
              {
                name: t("machineLocation"),
                inspectionItemName: ItemInspectionEnum.LOCATION,
              },
            ],
          ].map((item: any) => {
            let ob = { ...item } as any;
            Array.from({ length: getDaysInMonth(dateInspection) })?.forEach(
              (_day, i) => {
                ob[
                  format(
                    new Date(
                      getYear(dateInspection),
                      getMonth(dateInspection),
                      i + 1
                    ),
                    DATE_TIME_FORMAT_1
                  )
                ] = [];
              }
            );
            return ob;
          });
          db?.customInspections?.forEach((item: any) => {
            result.forEach((element: any) => {
              element?.[
                formatTime(item?.inspectionAt, DATE_TIME_FORMAT_1)
              ]?.push(item);
            });
          });
          setDisplayData(result as any);
        })
        .finally(() => {
          resetIsContinuousLoading();
        });
    } else {
      resetIsContinuousLoading();
    }
  }, [
    dateInspection,
    groupId,
    inspectionFormName,
    machineId,
    isRequestInspections,
  ]);

  useEffect(() => {
    if (isMobileDevice && isSafari) {
      document.addEventListener("touchstart", handleTouchStart);
      return () => {
        document.removeEventListener("touchstart", handleTouchStart);
      };
    }
  }, []);

  return (
    <>
      {dbTemplateList &&
        (!!dbTemplateList?.length ? (
          <div className="inspection-form-tab">
            <div className="wrap-action-inspection flex-centered justify-content-between sp-p24 mb-24 mt-24">
              <div className="wrap-date flex-centered justify-content-start">
                <h3 className="m-0 fs-24 txt-black-1 fw-700">
                  {format(dateInspection, DATE_TIME_FORMAT_YEAR_AND_MONTH)}
                </h3>
                <div>
                  <Button
                    className="btn-no-border btn-action-icon ml-20"
                    disabled={
                      format(
                        dateInspection,
                        DATE_TIME_FORMAT_YEAR_AND_MONTH
                      ) === MIN_DATE_CREATE_INSPECTION
                    }
                    onClick={() => handleChangeMonth(-1)}
                  >
                    <ArrowBackIos className="pointer fs-12" />
                  </Button>
                  <Button
                    className="btn-no-border btn-action-icon"
                    disabled={
                      format(
                        dateInspection,
                        DATE_TIME_FORMAT_YEAR_AND_MONTH
                      ) === format(new Date(), DATE_TIME_FORMAT_YEAR_AND_MONTH)
                    }
                    onClick={() => handleChangeMonth(1)}
                  >
                    <ArrowForwardIos className="pointer fs-12" />
                  </Button>
                </div>
                <FormControl className="select-field select-inspection ml-16">
                  <Select
                    open={inspectionFormNameStatus}
                    onOpen={() => setInspectionFormNameStatus(true)}
                    onClose={() => setInspectionFormNameStatus(false)}
                    defaultValue=""
                    id="grouped-select"
                    value={inspectionFormName}
                    onChange={handleChange}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: 216,
                          width: isMobile() ? window.innerWidth - 48 : 342,
                        },
                        className: `select-options-js ${
                          inspectionFormNameStatus ? "d-block" : "d-none-imp"
                        }`,
                      },
                    }}
                  >
                    {!!dbTemplateList &&
                      dbTemplateList?.map((option: any) => [
                        <MenuItem
                          key={option?.customInspectionFormId}
                          value={option?.customInspectionFormId}
                        >
                          <DataTooltip isTextPre width="100%">
                            {option.name}
                          </DataTooltip>
                        </MenuItem>,
                      ])}
                  </Select>
                </FormControl>
              </div>
              <div className="wrap-btns flex-centered justify-content-start">
                {/* TODO: implement to next phase */}
                {/* <Button className="btn-white" onClick={() => handleEditForm()}>
                <IconEdit className="icon-edit" fillColor="#140a9a" />
                {t("formAction.edit")}
              </Button> */}
                <Button
                  className="btn-primary"
                  onClick={() => handleOutputPdf()}
                >
                  <PictureAsPdfOutlined className="icon-pdf fs-18" />
                  {t("pdfOutput")}
                </Button>
              </div>
            </div>
            <TableInspectionFormTab
              displayData={displayData}
              currentDate={dateInspection}
              didScrollTop={initScrollToTop}
            />
          </div>
        ) : (
          <div className="empty-table bg-white mt-24">
            <div className="text-center">
              <IconBox />
              <p className="text-content">{t("emptyInspectionData")}</p>
            </div>
          </div>
        ))}
    </>
  );
}
