import { createSlice } from "@reduxjs/toolkit";

interface CardProps {
  msgUpload: any;
}

const initialState = {
  msgUpload: {},
} as CardProps;

export const cardSlice = createSlice({
  name: "card",
  initialState,
  reducers: {
    setErrorsUploadFile: (state, action) => {
      state.msgUpload = action.payload || "";
    },
  },
});

export const { setErrorsUploadFile } = cardSlice.actions;

export default cardSlice.reducer;
