import { Box, Tooltip } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";

export interface TooltipProps {
  width: number | string;
  children: string | JSX.Element;
  isLabel?: boolean;
  isTextPre?: boolean;
}

export const DataTooltip: FC<TooltipProps> = ({
  width,
  children,
  isLabel,
  isTextPre,
  ...props
}) => {
  const ref = useRef<HTMLSpanElement>(null);
  const [disableHoverListener, setDisableHoverListener] = useState(false);

  useEffect(() => {
    if (ref.current) {
      setDisableHoverListener(
        ref.current.scrollWidth > ref.current.clientWidth
      );
    }
  }, [children]);

  return (
    <Tooltip
      enterTouchDelay={100}
      disableHoverListener={!disableHoverListener}
      disableTouchListener={!disableHoverListener}
      title={
        <span className={`${isTextPre ? "txt-pre-wrap" : ""}`}>{children}</span>
      }
      PopperProps={{
        sx: {
          "&[data-popper-reference-hidden]": {
            visibility: "hidden",
            pointerEvents: "none",
          },
        },
      }}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -14],
              },
            },
          ],
        },
      }}
    >
      <Box
        ref={ref}
        className={`${isLabel ? "" : "txt-ellipsis"} ${
          isTextPre ? "txt-pre" : ""
        }`}
        style={{ width }}
        {...props}
      >
        <span>{children}</span>
      </Box>
    </Tooltip>
  );
};
