import { createSlice } from "@reduxjs/toolkit";

// Define a type for the slice state
interface MsgState {
  value: string;
}

// Define the initial state using that type
const initialState = {
  value: "",
} as MsgState;

export const mgsSlice = createSlice({
  name: "msg",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setShowAlert: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { setShowAlert } = mgsSlice.actions;
export default mgsSlice.reducer;
