import { msalInstance } from "@app/helpers";
import { useToken } from "./useToken";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { msalConfig } from "@app/api";
import { useAuth } from "./useAuth";

export const useCustomMsal = () => {
  msalInstance.initialize();
  const currentAccounts = msalInstance.getAllAccounts();
  const { saveTokens } = useToken();
  const { login } = useAuth();

  const accessTokenRequest = {
    scopes: [],
    account: currentAccounts[0],
  };

  const refreshToken = async (): Promise<string | null> => {
    const newToken = await msalInstance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        // Acquire token silent success
        saveTokens({ token: accessTokenResponse.idToken });
        return accessTokenResponse.idToken;
      })
      .catch((error) => {
        // Acquire token silent failure, and send an interactive request
        if (error instanceof InteractionRequiredAuthError) {
          msalInstance.acquireTokenRedirect(accessTokenRequest);
        }
        return null;
      });

    return newToken;
  };

  const checkLoginAuthentication = async () => {
    const response = await msalInstance.handleRedirectPromise();
    if (response) {
      login(response);
    } else {
      const currentAccounts = msalInstance.getAllAccounts();
      if (!currentAccounts.length) {
        msalInstance.loginRedirect({
          scopes: [],
          redirectStartPage: msalConfig.auth.redirectUri,
        });
        return;
      }
    }
  };

  return {
    refreshToken,
    checkLoginAuthentication,
  };
};
