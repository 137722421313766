type IconProps = {
  className?: string;
};
export function IconLocationMachine({ className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      className={`icon-location ${className}`}
    >
      <rect x="1" y="0.5" width="29" height="29" rx="14.5" stroke="#E0E0E0" />
      <path
        d="M15.4993 6.66699C12.2743 6.66699 9.66602 9.27533 9.66602 12.5003C9.66602 16.8753 15.4993 23.3337 15.4993 23.3337C15.4993 23.3337 21.3327 16.8753 21.3327 12.5003C21.3327 9.27533 18.7243 6.66699 15.4993 6.66699ZM11.3327 12.5003C11.3327 10.2003 13.1993 8.33366 15.4993 8.33366C17.7993 8.33366 19.666 10.2003 19.666 12.5003C19.666 14.9003 17.266 18.492 15.4993 20.7337C13.766 18.5087 11.3327 14.8753 11.3327 12.5003Z"
        fill="#140A9A"
      />
      <path
        d="M15.4993 14.5837C16.6499 14.5837 17.5827 13.6509 17.5827 12.5003C17.5827 11.3497 16.6499 10.417 15.4993 10.417C14.3488 10.417 13.416 11.3497 13.416 12.5003C13.416 13.6509 14.3488 14.5837 15.4993 14.5837Z"
        fill="#140A9A"
      />
    </svg>
  );
}
