import { createSlice } from "@reduxjs/toolkit";

interface ReportProps {
  idReport: string;
}

const initialState = {
  idReport: "",
} as ReportProps;

export const reportSlide = createSlice({
  name: "card",
  initialState,
  reducers: {
    setIdReport: (state, action) => {
      state.idReport = action.payload || "";
    },
  },
});

export const { setIdReport } = reportSlide.actions;

export default reportSlide.reducer;
