export function IconLocation() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.75 9.5C8.925 9.5 8.25 8.825 8.25 8C8.25 7.175 8.925 6.5 9.75 6.5C10.575 6.5 11.25 7.175 11.25 8C11.25 8.825 10.575 9.5 9.75 9.5ZM14.25 8.15C14.25 5.4275 12.2625 3.5 9.75 3.5C7.2375 3.5 5.25 5.4275 5.25 8.15C5.25 9.905 6.7125 12.23 9.75 15.005C12.7875 12.23 14.25 9.905 14.25 8.15ZM9.75 2C12.9 2 15.75 4.415 15.75 8.15C15.75 10.64 13.7475 13.5875 9.75 17C5.7525 13.5875 3.75 10.64 3.75 8.15C3.75 4.415 6.6 2 9.75 2Z"
        fill="#4C5459"
      />
    </svg>
  );
}
