import { useEffect, useState } from "react";
import { DataTable, DataDialog, DataPagination } from "@app/components/atoms";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import {
  ToggleButtonGroup,
  ToggleButton,
  Avatar,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  TextField,
  Box,
  debounce,
} from "@mui/material";
import {
  IconPlus,
  IconActionOverflow,
  IconDeleteRow,
  IconEdit,
  IconCopy,
  IconWarningFormEdit,
  IconUser,
  IconInputError,
  IconInvalidUrl,
} from "@app/components/icons";
import {
  ConfirmDialog,
  ConfirmDialogProps,
  CreateInspectionForm,
  InspectionFormDialog,
} from "@app/pages/Features";
import {
  REGEX,
  isMobile,
  resetIsContinuousLoading,
  scrollToTop,
  setItem,
} from "@app/helpers";
import {
  CURRENT_PAGE,
  DATE_TIME_FORMAT,
  DEBOUNCE_TIME,
  DialogTypes,
  INVALID_URL,
  LIMIT_PAGE,
  StorageKey,
  TOTAL_PAGE,
} from "@app/constants";
import {
  apiGroupDetailInspectionFormDelete,
  apiGroupDetailInspectionFormStatus,
  apiGroupDetailInspectionFormUpdate,
  apiGroupDetailInspectionForms,
  getCustomInspectionFormTemplates,
} from "@app/api";
import { format } from "date-fns";
import {
  store,
  setShowAlert,
  setCurrentInspectionInfo,
  RootState,
  setInspectionsTemplate,
  setMachineId,
  setIsEditDraft,
} from "@app/store";
import { useSelector } from "react-redux";

enum INSPECTION_TYPE {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
}

type InspectionType = {
  id: number;
  currentStatus?: string;
  givenName?: string;
  inspectionFormName?: string;
  lastStatusUpdatedAt?: string;
  machineId?: string;
  machineName?: string;
  machinePictureUrl?: string;
  machineTypePictureUrl?: string;
  surname?: string;
  userPictureUrl?: string;
};

const INSPECTION_STATUS = {
  [INSPECTION_TYPE.DRAFT]: "draft",
  [INSPECTION_TYPE.PUBLISHED]: "published",
};

const dataInspectionFormStatus = [
  {
    label: "filterInspection.all",
    key: "inspectionFormCount",
    inspectionStatus: "",
    count: 0,
  },
  {
    label: "filterInspection.published",
    key: "publishedStatusCount",
    inspectionStatus: "PUBLISHED",
    count: 0,
  },
  {
    label: "filterInspection.draft",
    key: "draftStatusCount",
    inspectionStatus: "DRAFT",
    count: 0,
  },
];

export function InspectionTab() {
  const { t } = useTranslation();
  const [openInspectionsForm, setOpenInspectionsForm] = useState(false);
  const { id: groupId } = useParams();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [targetRow, setTargetRow] = useState<any>(null);
  const [inspectionStatus, setInspectionStatus] = useState<
    INSPECTION_TYPE | ""
  >("");
  const [open, setOpen] = useState(false);
  const [openFormDelete, setOpenFormDelete] = useState(false);
  const [openFormName, setOpenFormName] = useState(false);
  const openFormAction = Boolean(anchorEl);
  const [dataSource, setDataSource] = useState<any[]>();
  const [order, setOrder] = useState<string | undefined>();
  const [field, setField] = useState<string>();
  const [requestData, setRequestData] = useState(new Date());
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState<ConfirmDialogProps>({});
  const [isInit, setIsInit] = useState(true);
  const [pageInfo, setPageInfo] = useState({
    total: TOTAL_PAGE,
    page: CURRENT_PAGE,
    limit: LIMIT_PAGE,
  });
  const [dataInspectionTabList, setDataInspectionTabList] = useState(
    dataInspectionFormStatus
  );
  const currentInspectionInfo = useSelector(
    (state: RootState) => state?.inspection?.currentInspectionInfo
  );
  const screenPermission = useSelector(
    (state: RootState) => state?.screenPermission.value
  );
  const { isRequestedGroupDetail } = useSelector(
    (state: RootState) => state.groupDetail
  );
  const [
    allowCreateEditDeleteInspectionForm,
    setAllowCreateEditDeleteInspectionForm,
  ] = useState<boolean>(false);
  const {
    register,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const columns = [
    {
      key: "machinePictureUrl",
      title: "",
      dataIndex: "machinePictureUrl",
      forceRender: true,
      render: (_: any, record: any) => {
        return (
          <>
            {record.machinePictureUrl ? (
              <Avatar
                variant="square"
                src={record.machinePictureUrl}
                imgProps={{
                  onError: () => {
                    const newDataSource = dataSource?.map((item) => {
                      if (item.id === record.id) {
                        item.machinePictureUrl = null;
                      }
                      return item;
                    });
                    setDataSource(newDataSource);
                  },
                  sx: {
                    objectFit: "contain",
                  },
                }}
              />
            ) : (
              <Box
                sx={{
                  width: 40,
                  height: 40,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconInvalidUrl />
              </Box>
            )}
          </>
        );
      },
      className: "pr-4",
      tableHeadingClassName: "d-none-imp",
      width: 56,
    },
    {
      key: "machineName",
      title: "inspectionTable.customerMachineNo",
      dataIndex: "machineName",
      sorted: true,
      tableHeadingColspan: 2,
      className: "pl-0 txt-pre-wrap",
      whiteSpace: "nowrap",
      style: {
        minWidth: isMobile() ? 178 : 198,
      },
    },
    {
      key: "inspectionFormName",
      title: "inspectionTable.inspectionName",
      dataIndex: "inspectionFormName",
      sorted: true,
      style: {
        minWidth: isMobile() ? 220 : 515,
      },
      render: (value: string) => {
        return <span className="txt-pre-wrap">{value}</span>;
      },
      className: "txt-pre-wrap",
    },
    {
      key: "currentStatus",
      title: "inspectionTable.status",
      dataIndex: "currentStatus",
      style: {
        minWidth: 105,
      },
      render: (value: INSPECTION_TYPE) => {
        return (
          <div
            className={`status txt-nowrap ${
              value === INSPECTION_TYPE.PUBLISHED ? "active" : ""
            }`}
          >
            {t(`filterInspection.${INSPECTION_STATUS[value]}`)}
          </div>
        );
      },
    },
    {
      key: "lastStatusUpdatedAt",
      title: "inspectionTable.lastUpdate",
      dataIndex: "lastStatusUpdatedAt",
      sorted: true,
      align: "right",
      style: {
        minWidth: isMobile() ? 162 : 212,
      },
      render: (value: string) => {
        return (
          <span className="txt-nowrap">
            {format(new Date(value), DATE_TIME_FORMAT)}
          </span>
        );
      },
    },
    {
      key: "userPictureUrl",
      title: "",
      dataIndex: "userPictureUrl",
      render: (_: any, record: any) => {
        return (
          <>
            {!!record.userPictureUrl ? (
              record.userPictureUrl === INVALID_URL ? (
                <Box
                  sx={{
                    width: 24,
                    height: 24,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <IconInvalidUrl />
                </Box>
              ) : (
                <Avatar
                  sx={{ width: 24, height: 24 }}
                  src={record.userPictureUrl}
                  imgProps={{
                    onError: () => {
                      const newDataSource = dataSource?.map((item) => {
                        if (item.id === record.id) {
                          item.userPictureUrl = INVALID_URL;
                        }
                        return item;
                      });
                      setDataSource(newDataSource);
                    },
                  }}
                />
              )
            ) : (
              <Box
                sx={{
                  width: 24,
                  height: 24,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconUser />
              </Box>
            )}
          </>
        );
      },
      forceRender: true,
      className: "pr-4",
      tableHeadingClassName: "d-none-imp",
      width: 40,
    },
    {
      key: "updatedBy",
      title: "inspectionTable.updatedBy",
      dataIndex: "updatedBy",
      sorted: true,
      tableHeadingColspan: 2,
      className: "pl-0 txt-pre-wrap",
      style: {
        minWidth: isMobile() ? 120 : 185,
      },
    },
    {
      key: "id",
      title: "",
      dataIndex: "id",
      render: (_: string, record: any) => (
        <Button
          className="bnt-action-form"
          onClick={(e) => handleClick(e, record)}
        >
          <IconActionOverflow />
        </Button>
      ),
      className: "add",
      width: 48,
      align: "center",
    },
  ];

  const handleChangePage = (page: number) => {
    setPageInfo({
      ...pageInfo,
      page,
    });
  };

  const handleSort = (order: string | undefined, property: string) => {
    setOrder(order);
    setField(property);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>, target: any) => {
    setAnchorEl(event.currentTarget);
    setTargetRow(target);
    store.dispatch(setCurrentInspectionInfo(target));
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeInspectionStatus = (
    _: React.MouseEvent<HTMLElement>,
    inspectionStatus: INSPECTION_TYPE | ""
  ) => {
    setPageInfo({
      ...pageInfo,
      page: CURRENT_PAGE,
    });
    setInspectionStatus(inspectionStatus);
  };

  const handleFetchInspectionsForm = () => {
    setOpenInspectionsForm(true);
    setOpen(false);
  };

  const convertDataRow = (data: InspectionType[]) => {
    return data.map((row: any) => ({
      ...row,
      id: row?.inspectionFormId,
      updatedBy: `${row?.surname} ${row?.givenName}`,
    }));
  };

  const getGroupDetailInspectionFormStatus = (id: string) => {
    return apiGroupDetailInspectionFormStatus(id).then((res) => {
      const data = res.data.data as any;
      const newDataInspectionTabs = dataInspectionTabList.map((tab) => {
        tab.count = data[tab.key as keyof any];
        return tab;
      });
      setDataInspectionTabList(newDataInspectionTabs);
    });
  };

  const getGroupDetailInspectionForms = (id: string, params = {}) => {
    return apiGroupDetailInspectionForms(id, params).then((res) => {
      const { data, meta } = res.data;
      const formatData = convertDataRow(data);
      setDataSource(formatData);
      handlePageInfo(meta, !data.length);
      if (!data.length) {
        initScrollToTop();
        resetIsContinuousLoading();
      }
    });
  };

  const handlePageInfo = (meta: any, hasData: boolean) => {
    if (meta.pageInfo.total) {
      if (hasData) {
        meta.pageInfo.page = meta.pageInfo.page - 1;
      }
    } else {
      setInspectionStatus("");
    }
    setPageInfo(meta.pageInfo);
  };

  const handleDeleteRow = debounce(() => {
    if (!targetRow) {
      return;
    }
    const { inspectionFormId, machineId } = targetRow;
    return apiGroupDetailInspectionFormDelete(
      `${groupId}`,
      machineId,
      inspectionFormId
    )
      .then((res: any) => {
        store.dispatch(setShowAlert(res.data.meta.successMessage));
        setRequestData(new Date());
      })
      .finally(() => {
        setOpenFormDelete(false);
        setAnchorEl(null);
      });
  }, DEBOUNCE_TIME);

  const handleEditRow = debounce(() => {
    if (!targetRow) {
      return;
    }
    const { inspectionFormId, machineId } = targetRow;
    const inspectionFormName = getValues("inspectionFormName");
    return apiGroupDetailInspectionFormUpdate(
      `${groupId}`,
      machineId,
      inspectionFormId,
      {
        name: inspectionFormName,
      }
    )
      .then(() => {
        setRequestData(new Date());
      })
      .finally(() => {
        setOpenFormName(false);
        setAnchorEl(null);
      });
  }, DEBOUNCE_TIME);

  const handleShowCopyInspectionForm = () => {
    if (!!groupId && !!currentInspectionInfo) {
      store.dispatch(setMachineId(currentInspectionInfo.machineId));
      getCustomInspectionFormTemplates(
        groupId,
        currentInspectionInfo.machineId,
        currentInspectionInfo.inspectionFormId
      ).then((db) => {
        store.dispatch(setInspectionsTemplate(db));
        setOpenInspectionsForm(true);
      });
    }
  };

  const handleClickConfirm = () => {
    setOpenDialogConfirm(true);
    setConfirmDialog({
      classBtnSubmit: "btn-primary",
      typeDialog: DialogTypes.CONFIRM,
      title: "titleConfirm",
      content: "",
      btnSubmitText: "ok",
      onCancel() {
        setOpenDialogConfirm(false);
      },
      onSubmit() {
        setOpenDialogConfirm(false);
        reset({ inspectionFormName: "" });
        setOpenFormName(false);
      },
    });
  };

  const initScrollToTop = () => {
    if (isInit) {
      scrollToTop();
      setIsInit(false);
    }
  };

  useEffect(() => {
    if (!Object.keys(screenPermission).length) {
      return;
    }
    if (!!screenPermission?.allowCreateEditDeleteInspectionForm) {
      setAllowCreateEditDeleteInspectionForm(
        screenPermission.allowCreateEditDeleteInspectionForm
      );
    } else {
      window.location.href = `/groups/${groupId}`;
    }
  }, [screenPermission]);

  useEffect(() => {
    getGroupDetailInspectionFormStatus(`${groupId}`);
  }, [requestData]);

  useEffect(() => {
    if (isRequestedGroupDetail) {
      setItem(StorageKey.IS_CONTINUOUS_LOADING, "true");
      getGroupDetailInspectionForms(`${groupId}`, {
        limit: LIMIT_PAGE,
        page: pageInfo.page,
        ...(inspectionStatus ? { currentStatus: inspectionStatus } : {}),
        ...(field && order ? { orderBys: [{ field, order }] } : {}),
      });
    }
  }, [
    pageInfo.page,
    field,
    order,
    inspectionStatus,
    requestData,
    isRequestedGroupDetail,
  ]);

  useEffect(() => {
    return () => {
      resetIsContinuousLoading();
    };
  }, []);

  return (
    <div className="inspection-tab">
      <div
        className={`flex group-button ${
          dataInspectionTabList[0].count
            ? "justify-content-between"
            : "justify-content-end"
        }`}
        style={{ marginTop: "24px" }}
      >
        <div className="toggle-btn-group">
          {!!dataInspectionTabList[0].count && (
            <ToggleButtonGroup
              className="filter-button"
              value={inspectionStatus}
              exclusive
            >
              {dataInspectionTabList.map((tab, index) => (
                <ToggleButton
                  key={index}
                  disabled={!tab.count}
                  value={tab.inspectionStatus}
                  className="flex-centered filter-tab"
                  onClick={handleChangeInspectionStatus}
                >
                  {typeof tab.label === "string" ? t(tab.label) : tab.label}
                  <span style={{ marginLeft: "8px" }}>({tab.count})</span>
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          )}
        </div>
        {allowCreateEditDeleteInspectionForm && (
          <Button
            variant="contained"
            disableElevation
            className="btn-add-inspect"
            onClick={() => setOpen(true)}
          >
            <IconPlus />
            <span style={{ marginLeft: "8px" }}>{t("addInspection")}</span>
          </Button>
        )}
      </div>
      <div className="inspection-table-container">
        <DataTable
          dataSource={dataSource}
          columns={columns}
          textEmptyData="noInspectionForms"
          onRequestSort={handleSort}
          didScrollTop={() => {
            initScrollToTop();
            resetIsContinuousLoading();
          }}
        />
        <DataPagination
          isHidden={!dataSource?.length}
          className="mt-25 sp-p24"
          currentPage={pageInfo.page}
          limitPage={pageInfo.limit}
          totalPage={pageInfo.total}
          handleChangePage={handleChangePage}
        />
      </div>
      <CreateInspectionForm
        open={open}
        setOpen={setOpen}
        clickSubmit={async () => handleFetchInspectionsForm()}
      />
      <InspectionFormDialog
        open={openInspectionsForm}
        setOpen={setOpenInspectionsForm}
        onRequestData={() => setRequestData(new Date())}
      />
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={openFormAction}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {[INSPECTION_TYPE.PUBLISHED, INSPECTION_TYPE.DRAFT].includes(
          targetRow?.currentStatus
        ) && (
          <MenuItem
            onClick={() => {
              if (targetRow) {
                const { inspectionFormName } = targetRow;
                setValue("inspectionFormName", inspectionFormName);
              }
              if (targetRow?.currentStatus === INSPECTION_TYPE.PUBLISHED) {
                setOpenFormName(true);
              } else {
                store.dispatch(setIsEditDraft(true));
                setOpenInspectionsForm(true);
                handleShowCopyInspectionForm();
              }
              handleClose();
            }}
          >
            <ListItemIcon>
              <IconEdit />
            </ListItemIcon>
            <ListItemText>
              {t(
                `formAction.${
                  targetRow?.currentStatus === INSPECTION_TYPE.PUBLISHED
                    ? "editName"
                    : "edit"
                }`
              )}
            </ListItemText>
          </MenuItem>
        )}
        {targetRow?.currentStatus === INSPECTION_TYPE.PUBLISHED && (
          <MenuItem
            onClick={() => {
              handleShowCopyInspectionForm();
              handleClose();
            }}
          >
            <ListItemIcon>
              <IconCopy />
            </ListItemIcon>
            <ListItemText>{t("formAction.copy")}</ListItemText>
          </MenuItem>
        )}
        {targetRow?.currentStatus === INSPECTION_TYPE.DRAFT && (
          <MenuItem
            onClick={() => {
              setOpenFormDelete(true);
              handleClose();
            }}
          >
            <ListItemIcon>
              <IconDeleteRow />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={
                <Typography variant="body2" className="txt-red fs-16">
                  {t("formAction.delete")}
                </Typography>
              }
            />
          </MenuItem>
        )}
      </Menu>
      <DataDialog
        title={t("formDelete.title")}
        open={openFormDelete}
        setOpen={setOpenFormDelete}
      >
        <div className="form-confirm-delete">
          <p className="txt-gray-1 fs-16">{t("formDelete.content")}</p>
          <div className="button-form-delete flex justify-content-end">
            <Button
              onClick={() => setOpenFormDelete(false)}
              className="btn-cancel"
            >
              {t("formDelete.cancel")}
            </Button>
            <Button className="btn-delete" onClick={handleDeleteRow}>
              {t("formDelete.delete")}
            </Button>
          </div>
        </div>
      </DataDialog>
      <DataDialog
        open={openFormName}
        setOpen={setOpenFormName}
        title={t("formEdit.title")}
      >
        <div className="form-edit-name">
          <div className="wrapper-text-field mt-8">
            <TextField
              id={
                errors?.inspectionFormName?.type
                  ? "outlined-error"
                  : "outlined-controlled"
              }
              {...register("inspectionFormName", {
                validate: {
                  hasEmoji: (value) => REGEX.emojiRegex.test(value),
                  hasInvalidChar: (value) =>
                    REGEX.commonNameFieldRegex.test(value),
                  required: (value) => !!value.trim(),
                },
                onBlur: (e) =>
                  setValue("inspectionFormName", e.target.value?.trim()),
              })}
              className="input-field"
              error={
                !!errors.inspectionFormName?.type &&
                errors?.inspectionFormName?.type !== "maxLength"
              }
              label={`${t("formEdit.checkListName")}*`}
              inputProps={{ maxLength: 50 }}
            />
            {errors?.inspectionFormName?.type && <IconInputError />}
          </div>
          {errors?.inspectionFormName?.type ? (
            <p className="txt-red fs-12 mt-0 mb-0 ml-15">
              {errors?.inspectionFormName?.type === "required" &&
                t("messages.require")}
              {errors?.inspectionFormName?.type === "hasInvalidChar" &&
                t("messages.validCharacters")}
              {errors?.inspectionFormName?.type === "hasEmoji" &&
                t("messages.validCharacters")}
            </p>
          ) : (
            <span className="fs-12 ml-15 txt-gray-1">
              &#42;{t("formEdit.required")}
            </span>
          )}
          <div className="warning flex justify-content-start">
            <IconWarningFormEdit />
            <span style={{ marginLeft: 12, flex: 1 }} className="txt-black-1">
              {t("formEdit.warning")}
            </span>
          </div>
          <div className="flex justify-content-end btn-group-footer">
            <Button onClick={handleClickConfirm} className="btn btn-no-border">
              {t("cancel")}
            </Button>
            <Button
              disabled={!!errors.inspectionFormName?.type}
              className="btn btn-primary btn-save ml-10"
              onClick={handleEditRow}
            >
              {t("formEdit.save")}
            </Button>
          </div>
        </div>
      </DataDialog>
      <ConfirmDialog
        open={openDialogConfirm}
        setOpen={setOpenDialogConfirm}
        {...confirmDialog}
      />
    </div>
  );
}
