import { isMobileDevice } from "@app/helpers";

export const TYPE_ALLOW = [
  "image/png",
  "image/jpeg",
  "application/pdf",
  "image/heic",
];
export const TYPE_ALLOW_CSV = ["text/csv", "application/vnd.ms-excel"];
export const MAX_FILE_SIZE = 10 * 1024 * 1024; // 25MB
export const MAX_FILE_NAME_LENGTH = 250;
export const IMAGE_TYPE_FILE =
  "image/png, image/jpg, image/jpeg, image/gif, image/heic";
export const VIDEO_TYPE_FILE = ".mp4, .mov";
export const TYPE_FILE_UPLOAD = {
  image: IMAGE_TYPE_FILE,
  video: VIDEO_TYPE_FILE,
  imageAndVideo: `${IMAGE_TYPE_FILE}, ${VIDEO_TYPE_FILE} ${
    isMobileDevice ? ";capture=camera" : ""
  }`,
};
export const INVALID_URL = "INVALID_URL";
