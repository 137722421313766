export function IconLoading() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="49"
      viewBox="0 0 48 49"
      fill="none"
      className="icon-loading"
    >
      <path
        d="M24 6.5C27.5601 6.5 31.0402 7.55568 34.0003 9.53355C36.9604 11.5114 39.2675 14.3226 40.6298 17.6117C41.9922 20.9008 42.3487 24.52 41.6541 28.0116C40.9596 31.5033 39.2453 34.7106 36.7279 37.2279C34.2106 39.7453 31.0033 41.4596 27.5116 42.1541C24.02 42.8487 20.4008 42.4922 17.1117 41.1298C13.8226 39.7675 11.0114 37.4603 9.03355 34.5003C7.05568 31.5402 6 28.0601 6 24.5H9.96C9.96 27.2768 10.7834 29.9913 12.3262 32.3002C13.8689 34.6091 16.0617 36.4086 18.6271 37.4713C21.1926 38.5339 24.0156 38.812 26.7391 38.2702C29.4626 37.7285 31.9642 36.3913 33.9278 34.4278C35.8913 32.4642 37.2285 29.9626 37.7702 27.2391C38.312 24.5156 38.0339 21.6926 36.9713 19.1271C35.9086 16.5617 34.1091 14.3689 31.8002 12.8262C29.4913 11.2834 26.7768 10.46 24 10.46V6.5Z"
        fill="#140A9A"
      />
    </svg>
  );
}
