export function IconBattery({ className = "" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="14"
      viewBox="0 0 28 14"
      fill="none"
      className={className}
    >
      <rect
        opacity="0.35"
        x="0.802734"
        y="1"
        width="24"
        height="12"
        rx="3.8"
        stroke="black"
      />
      <path
        opacity="0.4"
        d="M26.3027 5.28125V9.35672C27.1075 9.01155 27.6308 8.20859 27.6308 7.31899C27.6308 6.42938 27.1075 5.62642 26.3027 5.28125Z"
        fill="black"
      />
      <rect x="2.30273" y="2.5" width="21" height="9" rx="2.5" fill="black" />
    </svg>
  );
}
