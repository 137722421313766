import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useDrop } from "react-dnd";
import { Box, Switch, TextField } from "@mui/material";
import {
  Add,
  VisibilityOutlined,
  DriveFileRenameOutlineOutlined,
} from "@mui/icons-material";
import { Button, DataDialog } from "@app/components/atoms";
import {
  Card,
  ConfirmDialog,
  ConfirmDialogProps,
  PreviewInspectionDialog,
} from "@app/pages/Features";
import {
  CurrentStatusPostEnum,
  DialogTypes,
  ItemCodeEnum,
  ItemTypes,
  ResultType,
  UploadFileType,
} from "@app/constants";
import { REGEX, handleUploadStore } from "@app/helpers";
import { IconInputError } from "@app/components/icons";
import {
  RootState,
  setInspectionsTemplate,
  setIsEditDraft,
  setMachineId,
  setShowAlert,
} from "@app/store";
import { postInspectionFormTemplates, putCustomInspectionForm } from "@app/api";
import { InspectionItem, InspectionsType } from "@app/types";
import { useStoreDispatch } from "@app/hooks";

type InspectionForm = {
  open: boolean;
  setOpen: any;
  onRequestData: any;
};

export function InspectionFormDialog({
  open,
  setOpen,
  onRequestData,
}: InspectionForm) {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  const [openDialogPreview, setOpenDialogPreview] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState<ConfirmDialogProps>({});
  const [meterItemList, setMeterItemList] = useState<InspectionItem[]>([]);
  const [isRequiredOdometer, setIsRequiredOdometer] = useState<boolean>(false);
  const [isRequiredSMR, setIsRequiredSMR] = useState<boolean>(false);
  const [dbPreview, setDbPreview] = useState<any>(null);
  const [dbInspections, setDbInspections] = useState<InspectionsType>();
  const dbInspectionsFromStore = useSelector(
    (state: RootState) => state?.inspection?.inspectionsTemplate
  );
  const { isEditDraft, machineId, currentInspectionInfo } = useSelector(
    (state: RootState) => state?.inspection
  );
  const [mediaUploadErrorMsg, setMediaUploadErrorMsg] = useState("");
  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
      inspectionItems: [
        {
          inspectionItemId: "",
          name: "",
          previousName: "",
          description: "",
          customInspectionItemMedias: [],
          isNew: false,
          isOpen: false,
          isForcedRequired: false,
          isImmutable: false,
          isRequired: false,
          itemCode: null,
          resultType: ResultType.OK_OR_ANOMARY,
        },
      ],
    },
  });
  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    reset,
    formState: { errors, isValid },
  } = methods;
  const { fields, update, remove, append, move } = useFieldArray({
    control,
    name: "inspectionItems",
  });
  const inspectionItemList = watch("inspectionItems");
  const name = watch("name");

  const [, drop] = useDrop(() => ({ accept: ItemTypes.CARD }));

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    move(dragIndex, hoverIndex);
  }, []);

  const renderCard = useCallback(
    (field: any, index: number) => {
      return (
        <Card
          index={index}
          key={index}
          id={field.inspectionItemId}
          card={field}
          moveCard={moveCard}
          updateCard={updateCard}
          setValue={setValue}
          deleteCard={remove}
          mediaUploadErrorMsg={mediaUploadErrorMsg}
        />
      );
    },
    [errors, register, moveCard, remove]
  );

  const updateCard = (index: number, object: any) => {
    update(index, object);
  };

  const handleAddInspectionItem = () => {
    const newInspectionItems = inspectionItemList.filter((item) => item.isNew);
    let latestNewInspectionItemId = Math.max(
      ...newInspectionItems.map((item) => +item.inspectionItemId)
    );
    if (latestNewInspectionItemId === -Infinity) {
      latestNewInspectionItemId = 0;
    }
    append({
      inspectionItemId: `${latestNewInspectionItemId + 1}`,
      name: "",
      previousName: "",
      description: "",
      customInspectionItemMedias: [],
      isNew: true,
      isOpen: true,
      isForcedRequired: false,
      isImmutable: false,
      isRequired: false,
      itemCode: null,
      resultType: ResultType.OK_OR_ANOMARY,
    });
  };

  const handleUploadMedia = async () => {
    const convertData = { ...(handleConvertData() as any) };
    const files: any = [];
    // Find if any items are uploaded
    convertData?.customInspectionItems?.map((item: any, index: number) => {
      item.customInspectionItemMedias?.map((media: any, mediaIndex: number) => {
        if (!media.filePath) {
          files.push({
            index,
            mediaIndex,
            media: media,
          });
        }
      });
    });
    if (!!files.length) {
      for await (const item of files) {
        const newMedia = (await handleUploadStore(
          UploadFileType.MACHINE_INSPECTIONS,
          item.media
        )) as any;
        item.media = newMedia
          ? {
              fileName: newMedia?.name,
              filePath: newMedia?.filePath,
              mediaUrl: newMedia?.filePath,
              mimeType: newMedia?.type,
            }
          : null;
      }
    }
    if (files?.some((item: any) => item.media === null)) {
      return null;
    }
    files?.map((file: any) => {
      convertData.customInspectionItems[file.index].customInspectionItemMedias[
        file.mediaIndex
      ] = file.media;
    });
    return convertData;
  };

  const handleConvertData = () => {
    if (!!dbInspections) {
      const newInspectionItemList = inspectionItemList.map(
        (item: InspectionItem) => {
          return {
            inspectionItemId: item?.isNew ? undefined : item?.inspectionItemId,
            name: item?.name,
            description: item?.description,
            customInspectionItemMedias: item?.customInspectionItemMedias?.length
              ? item?.customInspectionItemMedias
              : undefined,
            isForcedRequired: item?.isForcedRequired,
            isImmutable: item?.isImmutable,
            isRequired: item?.isRequired,
            itemCode: item?.itemCode,
            resultType: item?.resultType,
          };
        }
      );
      return {
        name,
        inspectionFormId: dbInspections?.inspectionFormId,
        inspectionFormType: dbInspections?.type,
        customInspectionItems: [...newInspectionItemList, ...meterItemList],
      };
    }
  };

  const handleSaveDraft = async () => {
    if (!!id && !!machineId) {
      setMediaUploadErrorMsg("");
      const convertData = await handleUploadMedia();
      if (!convertData) {
        setMediaUploadErrorMsg(t("messages.errConmpressImg"));
        return;
      }
      !!isEditDraft
        ? putCustomInspectionForm(
            id,
            machineId,
            currentInspectionInfo?.inspectionFormId,
            {
              currentStatus: CurrentStatusPostEnum.DRAFT,
              ...convertData,
            } as InspectionsType
          ).then(() => handleAfterPublishOrSaveDraftInspections())
        : postInspectionFormTemplates(id, machineId, {
            currentStatus: CurrentStatusPostEnum.DRAFT,
            ...convertData,
          } as InspectionsType).then(() =>
            handleAfterPublishOrSaveDraftInspections()
          );
    }
  };

  const handlePreView = () => {
    setDbPreview(handleConvertData());
    setOpenDialogPreview(true);
  };

  const handlePublishInspections = async () => {
    if (!!id && !!machineId) {
      setMediaUploadErrorMsg("");
      const convertData = await handleUploadMedia();
      if (!convertData) {
        setMediaUploadErrorMsg(t("messages.errConmpressImg"));
        return;
      }
      !!isEditDraft
        ? putCustomInspectionForm(
            id,
            machineId,
            currentInspectionInfo?.inspectionFormId,
            {
              currentStatus: CurrentStatusPostEnum.PUBLISHED,
              ...convertData,
            } as any
          ).then((res) => {
            handleAfterPublishOrSaveDraftInspections(
              res?.data?.meta?.successMessage
            );
          })
        : postInspectionFormTemplates(id, machineId, {
            currentStatus: CurrentStatusPostEnum.PUBLISHED,
            ...convertData,
          } as any).then((res) => {
            handleAfterPublishOrSaveDraftInspections(
              res?.data?.meta?.successMessage
            );
          });
    }
  };

  const handleAfterPublishOrSaveDraftInspections = (msg = "") => {
    !!msg && dispatch(setShowAlert(msg));
    onRequestData();
    handleCloseDialog();
  };

  const handleOpenPublishForm = () => {
    setOpenDialogConfirm(true);
    setConfirmDialog({
      classBtnSubmit: "btn-primary",
      typeDialog: DialogTypes.CONFIRM,
      title: "titleConfirmPublish",
      content: "desConfirmPublish",
      btnSubmitText: "release",
      onCancel() {
        setOpenDialogConfirm(false);
      },
      onSubmit() {
        setOpenDialogConfirm(false);
        handlePublishInspections();
      },
    });
  };

  const handleClickConfirm = () => {
    setOpenDialogConfirm(true);
    setConfirmDialog({
      classBtnSubmit: "btn-primary",
      typeDialog: DialogTypes.CONFIRM,
      title: "titleConfirm",
      content: "",
      btnSubmitText: "ok",
      onCancel() {
        setOpenDialogConfirm(false);
      },
      onSubmit() {
        handleCloseDialog();
      },
    });
  };

  const handleOpenSaveDraft = () => {
    setOpenDialogConfirm(true);
    setConfirmDialog({
      classBtnSubmit: "btn-primary",
      typeDialog: DialogTypes.SUCCESS,
      title: "titleSaveDraft",
      content: "desSaveDraft",
      btnSubmitText: "save",
      onCancel() {
        setOpenDialogConfirm(false);
      },
      onSubmit() {
        setOpenDialogConfirm(false);
        handleSaveDraft();
      },
    });
  };

  const handleCloseDialog = () => {
    setOpenDialogConfirm(false);
    setOpen(false);
    dispatch(setInspectionsTemplate(null));
    dispatch(setMachineId(null));
    dispatch(setIsEditDraft(false));
    setIsRequiredOdometer(false);
    setIsRequiredSMR(false);
    setMeterItemList([]);
    reset();
  };

  const onSubmit = () => {
    handleOpenPublishForm();
  };

  const handleConvertSetData = (db: InspectionsType) => {
    const currentInspectionItems = db?.inspectionItems
      .filter(
        (item: InspectionItem) => item?.resultType === ResultType?.OK_OR_ANOMARY
      )
      .map((item: InspectionItem) => {
        return {
          ...item,
          customInspectionItemMedias: item.inspectionItemMedias,
          isNew: false,
          isOpen: false,
        };
      });
    setValue("name", db?.name);
    setValue("inspectionItems", currentInspectionItems as any);
    setDbInspections(db);
    setMeterItemList(
      db?.inspectionItems?.filter(
        (item: InspectionItem) =>
          item?.resultType === ResultType?.NUMERIC && !!item?.itemCode
      )
    );
  };

  const handleSetMeter = (index: number, card?: any) => {
    meterItemList.splice(index, 1, {
      ...meterItemList[index],
      isRequired: !card?.isRequired,
    });
    card?.itemCode === ItemCodeEnum.ODOMETER
      ? setIsRequiredOdometer(!card?.isRequired)
      : setIsRequiredSMR(!card?.isRequired);
    setMeterItemList([...meterItemList]);
  };

  useEffect(() => {
    if (!!dbInspectionsFromStore?.inspectionItems) {
      handleConvertSetData(dbInspectionsFromStore);
    }
  }, [dbInspectionsFromStore]);

  return (
    <>
      <DataDialog
        title={t("creatingAChecklist")}
        open={open}
        setOpen={setOpen}
        className="dialog-inspection-form"
        action={
          <div className="flex header-actions">
            <Button
              className="btn-no-border"
              disabled={
                !isValid ||
                inspectionItemList?.length + meterItemList?.length < 2
              }
              onClick={() => handleOpenSaveDraft()}
            >
              <DriveFileRenameOutlineOutlined className="icon-border-color" />
              <span className="sp-d-none ml-8">{t("saveDraft")}</span>
            </Button>
            <Button
              className="btn-no-border"
              disabled={
                !isValid ||
                inspectionItemList?.length + meterItemList?.length < 2
              }
              onClick={() => handlePreView()}
            >
              <VisibilityOutlined className="icon-visibility" />
              <span className="sp-d-none ml-8">{t("preview")}</span>
            </Button>
          </div>
        }
        dialogActions={
          <div className="flex align-items-center justify-content-end">
            <Button
              className="btn-no-border"
              onClick={() => handleClickConfirm()}
            >
              <span>{t("cancel")}</span>
            </Button>
            <Button
              className="btn-primary ml-15"
              onClick={handleSubmit(onSubmit)}
              disabled={
                !isValid ||
                inspectionItemList?.length + meterItemList?.length < 2
              }
            >
              {t("release")}
            </Button>
          </div>
        }
      >
        <FormProvider {...methods}>
          <Box component="form" autoComplete="off">
            <div className="wrapper-text-field">
              <TextField
                {...register("name", {
                  validate: {
                    hasEmoji: (value) => REGEX.emojiRegex.test(value),
                    hasInvalidChar: (value) =>
                      REGEX.commonNameFieldRegex.test(value),
                    required: (value) => !!value.trim(),
                  },
                  onBlur: (e) => setValue("name", e.target.value?.trim()),
                })}
                error={!!errors.name?.type}
                id={errors.name?.type ? "outlined-error" : "outlined-required"}
                label={`${t("inspectionName")}*`}
                className="input-field"
                value={name}
                inputProps={{ maxLength: 50 }}
              />
              {errors.name?.type && <IconInputError />}
            </div>
            {errors.name ? (
              <span className="txt-red fs-12 ml-15">
                {errors.name?.type === "required" && t("messages.require")}
                {errors.name?.type === "hasInvalidChar" &&
                  t("messages.validCharacters")}
                {errors.name?.type === "hasEmoji" &&
                  t("messages.validCharacters")}
              </span>
            ) : (
              <span className="fs-12 ml-15 txt-gray-1">
                &#42;{t("required")}
              </span>
            )}
            <ul className="cards mt-24" ref={drop}>
              {fields?.map(renderCard)}
            </ul>
            <div className="flex-centered mt-24">
              <Button
                className="btn-secondary"
                onClick={handleAddInspectionItem}
              >
                <Add className="icon-add" />
                {t("addInspectionItem")}
              </Button>
            </div>
            <ul className="mt-24 cards bg-gray">
              {meterItemList?.map((card: InspectionItem, index: number) => (
                <li className="card-item" key={index}>
                  <div
                    className={`card-item-title flex-centered  justify-content-between ${
                      card?.itemCode === ItemCodeEnum.ODOMETER && "have-switch"
                    }`}
                  >
                    <span
                      className={`txt-card ${
                        !!card?.isForcedRequired ? "txt-gray-2" : ""
                      }`}
                    >
                      {card?.name}
                      {card?.itemCode === ItemCodeEnum.ODOMETER
                        ? (!!isRequiredOdometer || !!card?.isRequired) && "*"
                        : card?.itemCode === ItemCodeEnum.SERVICE_METER
                        ? (!!isRequiredSMR || !!card?.isRequired) && "*"
                        : !!card?.isRequired && "*"}
                    </span>
                    {!card?.isForcedRequired && (
                      <div className="d-flex align-items-center">
                        <span className="fs-14 txt-gray-3 mr-15">
                          {t("required")}
                        </span>
                        {card?.itemCode === ItemCodeEnum.ODOMETER ? (
                          <Switch
                            className={`btn-switch ${
                              (!!isRequiredOdometer || !!card?.isRequired) &&
                              "checked"
                            }
                          `}
                            checked={card?.isRequired || isRequiredOdometer}
                            onChange={() => {
                              handleSetMeter(index, card);
                            }}
                          />
                        ) : (
                          <Switch
                            className={`btn-switch ${
                              (!!isRequiredSMR || !!card?.isRequired) &&
                              "checked"
                            }
                          `}
                            checked={card?.isRequired || isRequiredSMR}
                            onChange={() => {
                              handleSetMeter(index, card);
                            }}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </Box>
        </FormProvider>
      </DataDialog>
      <ConfirmDialog
        open={openDialogConfirm}
        setOpen={setOpenDialogConfirm}
        {...confirmDialog}
      />
      <PreviewInspectionDialog
        open={openDialogPreview}
        setOpen={setOpenDialogPreview}
        data={dbPreview}
      />
    </>
  );
}
