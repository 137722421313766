export function IconTick({ fillColor = "#47464F" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="5 5 15 15"
      fill="none"
    >
      <path
        d="M10.7271 16.4L6.72705 12.4L8.12705 11L10.7271 13.6L17.3271 7L18.7271 8.4L10.7271 16.4Z"
        fill={fillColor}
      />
    </svg>
  );
}
